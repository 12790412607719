module Pages.Login

open Feliz
open Feliz.UseElmish
open Feliz.UseListener
open Elmish
open Indygemma.Auth.Client.Auth

let logo () = StaticFile.import "../imgs/bookstreamlogo.svg"

module Login =

    [<CLIMutable>]
    type LoginViewModel =
        {
            Email : string
            Password : string
        }

    [<CLIMutable>]
    type TokenResult =
        {
            isValid : bool
            errorMessage : string
            token : string
        }

    type Msg =
        | Login of string * string
        | SetErrors of string list
        | ClearErrors
        | DoNothing

    type Props =
        {
            AppDispatch : Client.Types.AppMsg -> Unit
        }

    type State =
        {
            AppDispatch : Client.Types.AppMsg -> Unit
            Errors: string list
        }

    let defaultState appDispatch =
        {
            AppDispatch = appDispatch
            Errors = []
        }

    let private init appDispatch () =
        defaultState appDispatch, Cmd.ofMsg DoNothing

    let private update msg state =
        match msg with
        | DoNothing -> state, Cmd.none
        | SetErrors errs ->
            { state with Errors = errs }, Cmd.none
        | ClearErrors ->
            { state with Errors = [] }, Cmd.none
        | Login (username, password) ->
            state, Cmd.fromAsync (fun _dispatch ->
                                      (login username password
                                          (fun token ->
                                            state.AppDispatch (Client.Types.SetJwt token)
                                            ())
                                          (fun error -> SetErrors [error])
                                          (fun _ -> ClearErrors)))

    let page = React.functionComponent(fun(props:Props) ->
        let state, dispatch = React.useElmish(init props.AppDispatch, update)
        let username, setUsername = React.useState("")
        let password, setPassword = React.useState("")
        Html.div [
            prop.className "flex flex-row justify-center"
            prop.children [

                Html.div [
                    prop.className "flex flex-col space-y-4 w-1/2 text-left mt-10"
                    prop.children [
                        Html.img [
                            prop.src (logo ())
                            prop.width 250
                        ]

                        if state.Errors.Length > 0 then
                            Html.ul [
                                for error in state.Errors do
                                    Html.li [
                                        Html.p error
                                    ]
                            ]
                        Html.div [
                            Html.input [
                                prop.className "input is-rounded"
                                prop.type' "text"
                                prop.placeholder "Username / Email"
                                prop.valueOrDefault username
                                prop.onChange (fun text ->
                                    setUsername text)
                                prop.onKeyPress (key.enter, fun _ ->
                                    dispatch (Login (username, password)))
                            ]
                        ]
                        Html.div [
                            Html.input [
                                prop.className "input is-rounded"
                                prop.type' "password"
                                prop.placeholder "Password"
                                prop.valueOrDefault password
                                prop.onChange (fun text ->
                                    setPassword text)
                                prop.onKeyPress (key.enter, fun _ ->
                                    dispatch (Login (username, password)))
                            ]
                        ]
                        Html.button [
                            prop.className "border border-gray-300 p-2 rounded font-semibold hover:bg-gray-50"
                            prop.text "Login"
                            prop.onClick (fun _ ->
                                dispatch (Login (username, password)))
                        ]
                    ]
                ]
            ]
        ])
