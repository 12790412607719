module SharedGQL

open MetaGQL.Types

module Entities =

    let EANStock =
        AEntity
            ("ean_stock",
             [ ("ean", AString)
               ("used", ABool) ])

    let BaseUserInfo =
        AEntity
            ("user_info",
             [ ("user_id", AUuid)
               ("name", AString) ])

    let BaseUser =
        AEntity
            ("user",
             [ ("id", AUuid)
               ("email", AString)
               ("password_digest", AString)
               ("email_verified", AString)
               ("created_at", AOption ADateTime)
               ("updated_at", AOption ADateTime)
               ("is_active", ABool) ])

    let UserToUserInfo =
        EntityHelper.add BaseUser
            [("info", AForeign(BaseUserInfo, None, IsOne))]

    let ISRCStock =
        AEntity
            ("isrc_stock_generation",
             [ ("isrc_prefix", AString)
               ("year", AInt)
               ("counter", AInt)
               ("comment", AString) ])

    let ArtistProfile =
        AEntity
            ("artist_profile",
             [ ("id", AUuid)
               ("name", AString)
             ])

    let ArtistProfileRole =
        AEntity
            ("artist_profile_role",
             [ ("id", AUuid)
               ("bookstream_description", AString)
               ("zebralution_description", AString)
               ("zebralution_role_number", AInt) ])

    let Author =
        AEntity
            ( "author",
              [ ("id", AUuid)
                ("name", AString)
              ])

    let AuthorManuscript =
        AEntity
            ( "author_manuscript",
              [ ("author_id", AUuid)
                ("manuscript_id", AUuid)
                ("sort_order", AInt)
              ])

    let AuthorManuscriptToAuthor =
        EntityHelper.add AuthorManuscript
            [("author", AForeign(Author, None, IsOne))]

    let ProductStatus =
        AEntity
            ( "product_status",
              [ ("value", AString)
                ("comment", AString)
              ])

    let SegmentationType =
        AEntity
            ( "segmentation_type",
              [ ("value", AString)
                ("comment", AString)
              ])

    let Language =
        AEntity
            ( "language",
              [ ("value", AString)
              ])

    let AccountingGroupShare =
        AEntity
            ("accounting_group_share",
                [ ("accounting_group_id", AUuid)
                  ("percentage", ADouble)
                  ("user_id", AUuid)
                  ("id", AUuid)
                  ("user", AForeign(UserToUserInfo, None, IsOne))
                ])

    let AccountingGroup =
        AEntity
            ( "accounting_group",
              [ ("id", AUuid)
                ("name", AString)
                ("accounting_group_shares", AForeign(AccountingGroupShare, None, IsMany))
              ])

    let GenreBisac =
        AEntity
            ( "genre_bisac",
              [ ("id", AUuid)
                ("name", AString)
                ("parent_genre", AString)
              ])

    let GenreWGS =
        AEntity
            ( "genre_wgs",
              [ ("id", AUuid)
                ("name", AString)
                ("parent_genre", AOption AString)
              ])

    let GenreZebralutionPhononet =
        AEntity
            ( "genre_zebralution_phononet",
              [ ("id", AUuid)
                ("name_de", AString)
                ("name_en", AString)
              ])

    let GenrePresetGenreBisac =
        AEntity
            ( "genre_preset_genre_bisac",
              [ ("genre_preset_id", AUuid)
                ("genre_bisac_id", AUuid)
                ("sort_order", AInt)
                ("genre_bisac", AForeign(GenreBisac, None, IsOne))
              ])

    let GenrePresetGenreWGS =
        AEntity
            ( "genre_preset_genre_wgs",
              [ ("genre_preset_id", AUuid)
                ("genre_wgs_id", AUuid)
                ("sort_order", AInt)
                ("genre_wgs", AForeign(GenreWGS, None, IsOne))
              ])

    let GenrePresetGenreZebralutionPhononet =
        AEntity
            ( "genre_preset_genre_zebralution_phononet",
              [ ("genre_preset_id", AUuid)
                ("genre_zebralution_phononet_id", AUuid)
                ("sort_order", AInt)
                ("genre_zebralution_phononet", AForeign(GenreZebralutionPhononet, None, IsOne))
              ])

    let GenrePreset =
        AEntity
            ( "genre_preset",
              [ ("id", AUuid)
                ("name", AString)
                ("bisac_genres", AForeign(GenrePresetGenreBisac, None, IsMany))
                ("wgs_genres", AForeign(GenrePresetGenreWGS, None, IsMany))
                ("zebralution_phononet_genres", AForeign(GenrePresetGenreZebralutionPhononet, None, IsMany))
              ])

    let MusicRightsInfo =
        AEntity
            ( "music_rights_info",
              [ ("value", AString)
                ("comment", AString) ])

    let ProjectKind =
        AEntity
            ( "project_kind",
              [ ("value", AString)
                ("comment", AString) ])

    let BaseProject =
        AEntity
            ( "project",
              [("id", AUuid)
               ("created_at", ADateTime)
               ("updated_at", ADateTime)
               ("title", AString)
               ("computed_title", AOption AString)
               ("computed_authors", AOption AString)
               ("computed_narrators", AOption AString)
               ("description", AOption AString)
               ("project_kind", ACustom "project_kind_enum")
               //("project_kind", AForeign (ProjectKind, None, IsEnum))
              ])

    let ProjectManuscriptInfo =
        AEntity
            ( "project_manuscript_info",
              [("manuscript_id", AUuid)
               ("project_id", AUuid)])

    let ProjectManuscriptInfoToProject =
        EntityHelper.add ProjectManuscriptInfo
            [("project", AForeign (BaseProject, None, IsOne))]


    let Manuscript =
        AEntity
            ( "manuscript",
              [("id", AUuid)
               ("title", AString)
               ("word_count", AInt)
               ("comment", AString)
               ("default_blurb", AString)
               ("is_explicit_content", ABool)
               ("default_accounting_group_id", AUuid)
               ("default_accounting_group", AForeign(AccountingGroup, None, IsOne))
               ("language_id", ACustom "language_enum")
               ("language", AForeign(Language, None, IsOne)) // TODO: This is defined as such in the Hasura backend (make it enum?)
               ("subtitle", AString)
               ("is_abridged", ABool)
               ("min_age", AOption AInt)
               ("max_age", AOption AInt)
               ("genre_preset_id", AUuid)
               ("genre_preset", AForeign(GenrePreset, None, IsOne)) // TODO: should this be enum in the hasura as well?
               ("authors", AForeign(AuthorManuscriptToAuthor, None, IsMany))
               ("projects", AForeign(ProjectManuscriptInfoToProject, None, IsMany))
               ])

    let AuthorManuscriptToManuscript =
        EntityHelper.add AuthorManuscript
            [("manuscript", AForeign(Manuscript, None, IsOne))]

    let BaseUserReaderInfo =
        AEntity
            ("user_reader_info",
             [ ("user_id", AUuid)
               ("pseudonym", AOption AString)
               ("gender", AString) // TODO: constrain enum
               ("voice_pitch", AString) // TODO: constrain enum
               ("voice_age", AString) // TODO: constrain enum
               ("voice_type", AString) // TODO: constrain enum
               ("is_active", ABool) // TODO: constrain enum
               ("notes", AString) // TODO: constrain enum
               ("dialect", AString) // TODO: constrain enum
             ])

    let UserToReader =
        EntityHelper.add BaseUser
            [("reader", AForeign(BaseUserReaderInfo, None, IsOne))]

    let UserInfoToReader =
        EntityHelper.add BaseUserInfo
            [("user", AForeign(UserToReader, None, IsOne))]

    let UserReaderInfoToUser =
        EntityHelper.add BaseUserReaderInfo
             [ ("user", AForeign(UserToUserInfo, None, IsOne)) ]

    let UserUserRole =
        AEntity
            ("user_user_role",
             [ ("user_id", AUuid)
               ("role", AString)
             ])

    let ProjectProductionInfoReader =
        AEntity
            ( "project_production_info_reader",
              [("project_id", AUuid)
               ("user_id", AUuid)
               ("total_duration", AInterval)
               ("reader_name", AString)
               ("sort_order", AInt)
               ("user_info", AForeign(BaseUserInfo, None, IsOne))
               ("user_reader_info", AForeign(BaseUserReaderInfo, None, IsOne))
               ])

    let ProjectStatus =
        AEntity
            ( "project_status",
              [("value", AString)
               ("comment", AString)
              ])

    let ProjectProductionInfo =
        AEntity
            ( "project_production_info",
              [("accepted_at", AOption ADateTime)
               ("next_deadline", AOption ADateTime)
               ("polished_at", AOption ADateTime)
               ("recorded_at", AOption ADateTime)
               ("project_id", AUuid)
               ("status", ACustom "project_status_enum")
               ("project_production_info_readers", AForeign(ProjectProductionInfoReader, None, IsMany))
               ("project_status", AForeign(ProjectStatus, None, IsOne)) // TODO: should this be IsEnum?
              ])

    let ProjectCopyrightInfo =
        AEntity
            ( "project_copyright_info",
              [("project_id", AUuid)
               ("c_line", AString)
               ("p_line", AString)
               ])

    let ProjectSeries =
        AEntity
            ( "project_series",
                [ ("id", AUuid)
                  ("title", AString)
                  ("comment", AString)
                  ("episode_designator", AString)
                ])

    let ProjectProjectSeries =
        AEntity
            ( "project_project_series",
              [("part_number", AInt)
               ("project_id", AUuid)
               ("project_series_id", AUuid)
               ])

    let ProjectProjectSeriesToSeries =
        EntityHelper.add ProjectProjectSeries
            [("project_series", AForeign(ProjectSeries, None, IsOne))]

    let ProjectAccountingInfo =
        AEntity
            ( "project_accounting_info",
              [("project_id", AUuid)
               ("accounting_group_id", AUuid)])

    let ProjectAccountingInfoToAccountingGroup =
        EntityHelper.add ProjectAccountingInfo
          [("accounting_group", AForeign(AccountingGroup, None, IsOne))]

    let ProjectManuscriptInfoToManuscript =
        EntityHelper.add ProjectManuscriptInfo
            [("manuscript", AForeign (Manuscript, None, IsOne))]

    let Ware =
        AEntity
            ( "ware",
              [ ("project_id", AUuid)
                ("id", AUuid)
                ("name", AString)
                ("default_blurb", AOption AString)
                ("is_anthology", ABool)
              ])

    let MinimalProduct =
        AEntity
            ( "product",
              [ ("id", AUuid)
                ("ean", AString)
                ("title", AString)
                ("sales_start", ADate)
                ("released_at", AOption ADate)
                ("product_status", AForeign (ProductStatus, None, IsEnum))
                ("bookstream_article_number", AString) ])

    let WareWithMinimalProduct =
        EntityHelper.add Ware
            [("products", AForeign(MinimalProduct, None, IsMany))]

    let BaseProjectLabelInfo =
        AEntity
            ( "project_label_info",
              [ ("project_id", AUuid)
                ("label_id", AUuid) ])

    let BaseLabelZebralutionInfo =
        AEntity
            ("label_zebralution_info",
             [("label_id", AUuid)
              ("zebralution_id", AUuid)])

    //let LabelZebralutionInfoToLabel =
    //    EntityHelper.add BaseLabelZebralutionInfo
    //        [("label", AForeign(LabelWithProjects, None, IsOne))]

    let Label =
        AEntity
            ( "label",
              [ ("id", AUuid)
                ("name", AString)
                ("default_c_line", AString)
                ("article_number_prefix", AString)
                ("next_number", ABigInt)
                ("zebralution_info", AForeign(BaseLabelZebralutionInfo, None, IsOne)) ])

    let ProjectLabelInfoToLabel =
        EntityHelper.add BaseProjectLabelInfo
            [("label", AForeign(Label, None, IsOne))]

    let Project =
        EntityHelper.add BaseProject
              [("manuscript_info", AForeign (ProjectManuscriptInfoToManuscript, None, IsOne))
               ("production_info", AForeign (ProjectProductionInfo, None, IsOne))
               ("copyright_info", AForeign (ProjectCopyrightInfo, None, IsOne))
               ("project_series_info", AForeign (ProjectProjectSeriesToSeries, None, IsOne))
               ("accounting_info", AForeign (ProjectAccountingInfoToAccountingGroup, None, IsOne))
               ("label_info", AForeign (ProjectLabelInfoToLabel, None, IsOne))
               ("wares", AForeign (WareWithMinimalProduct, None, IsMany))
              ]

    let ProjectLabelInfoToProject =
        EntityHelper.add BaseProjectLabelInfo
            [("project", AForeign(Project, None, IsOne))]

    let LabelWithProjects =
        EntityHelper.add Label
            [("projects", AForeign (ProjectLabelInfoToProject, None, IsMany))]

    let LabelZebralutionInfo =
        AEntity
            ("label_zebralution_info",
             [("label_id", AUuid)
              ("zebralution_id", AUuid)
              ("label", AForeign (LabelWithProjects, None, IsOne))])

    let ProjectLabelInfoWithProject =
        EntityHelper.add BaseProjectLabelInfo
            [("project", AForeign(Project, None, IsOne))]

    let WareWithProject =
        EntityHelper.add Ware
            [("project", AForeign(Project, None, IsOne))]

    let ProjectProductionInfoWithProject =
        EntityHelper.add ProjectProductionInfo
            [("project", AForeign(Project, None, IsOne))]

    let ProjectCopyrightInfoWithProject =
        EntityHelper.add ProjectCopyrightInfo
            [("project", AForeign(Project, None, IsOne))]

    let ProjectAccountingInfoToProject =
        EntityHelper.add ProjectAccountingInfo
          [("project", AForeign(Project, None, IsOne))]

    let ProjectProjectSeriesToProject =
        EntityHelper.add ProjectProjectSeries
            [("project", AForeign(Project, None, IsOne))]

    let ManuscriptWithProjects =
        EntityHelper.add Manuscript
            [("projects", AForeign (ProjectManuscriptInfo, None, IsMany))]

    let ProductArtistProfileInfo =
        AEntity
            ("product_artist_profile_info",
             [("product_id", AUuid)
              ("artist_profile_id", AUuid)
              ("sort_order", AInt)
              ("role_id", AUuid)
             ])

    let ProductArtistProfileInfoToArtist =
        EntityHelper.add ProductArtistProfileInfo
            [("artist_profile", AForeign(ArtistProfile, None, IsOne))
             ("artist_profile_role", AForeign(ArtistProfileRole, None, IsOne))]

    let ProductTrackInfo =
        AEntity
            ("product_track_info",
             [("id", AUuid)
              ("isrc", AString)
              ("product_id", AUuid)
              ("duration", AInterval)
              ("track_number", AInt)
              ("title", AString)
              ("title_version", AString)])

    let Product =
        AEntity
            ( "product",
              [("id", AUuid)
               ("created_at", ADateTime)
               ("updated_at", ADateTime)
               ("sales_start", ADate)
               ("sales_end", AOption ADate)
               ("delivered_at", AOption ADate)
               ("ean", AString)
               ("title", AString)
               ("bookstream_article_number", AString)
               ("total_duration", AInterval)
               ("ware_id", AUuid)
               ("default_price", AOption AInt)
               ("subtitle", AString)
               ("blurb", AOption AString)
               ("musical_percentage", AInt)
               ("released_at", AOption ADate)
               ("is_spotify", ABool)
               ("musical_work_rights", AForeign (MusicRightsInfo, None, IsEnum))
               ("product_status", AForeign (ProductStatus, None, IsEnum))
               ("ware", AForeign (WareWithProject, None, IsOne))
               ("product_artist_profile_infos", AForeign (ProductArtistProfileInfoToArtist, None, IsMany))
               ("segmentation_type", AForeign (SegmentationType, None, IsEnum))
              ])

    let WareWithProducts =
        EntityHelper.add Ware
            [("products", AForeign(Product, None, IsMany))]

    let ProductArtistProfileInfoToProduct =
        EntityHelper.add ProductArtistProfileInfo
            [("product", AForeign(Product, None, IsOne))]


module DWHEntities =

    // Entity to hold the information: "Which distributor does this belong to?"
    let DWHDistributor =
        AEntity
            ("dwh_distributor",
             [("id", AUuid)
              ("name", AString)])

    //
    // Data types specifically for the data warehouse (DIM, OT, FACT etc.)
    //

    // At filter time, we need to filter by royalty sheet name and the distributor the sheet belongs to
    let DIMDistributor =
        AEntity
            ("dwh_dim_distributor",
             [("id", AUuid)
              ("name", AString)
              ("royalty_sheet_name", AString)]) // TODO: is this the correct place? Maybe Import/Report is a separate dimension?

    let DIMShop =
        AEntity
            ("dwh_dim_shop",
             [("id", AUuid)
              ("name", AString)])

    let DIMSalesType =
        AEntity
            ("dwh_dim_sales_type",
             [("id", AUuid)
              ("name", AString)])

    let DIMLocation =
        AEntity
            ("dwh_dim_location",
             [("id", AUuid)
              ("name", AString)])

    // Mapping tables are used to dynamically define the mapping of original value to normalized values (data-driven, instead of code-driven)
    let DIMShopMappingZebralution =
        AEntity
            ("dwh_dim_shop_mapping_zebralution",
             [("id", AUuid)
              ("distributor_id", AUuid)
              ("original_value", AString)
              ("normalized_value", AUuid)
              // TODO: implement 'dwh_distributor' relationship
              //("dwh_distributor", AForeign(DWHDistributor, None, IsOne))
              ("dwh_dim_shop", AForeign(DIMShop, None, IsOne))
              ])

    let DIMShopMappingKontor =
        AEntity
            ("dwh_dim_shop_mapping_kontor",
             [("id", AUuid)
              ("distributor_id", AUuid)
              ("original_lizenznehmer", AString)
              ("original_sublizenznehmer", AString)
              ("normalized_value", AUuid)
              // TODO: implement 'dwh_distributor' relationship
              // TODO: implement 'dwh_dim_shop' relationship
              //("dwh_distributor", AForeign(DWHDistributor, None, IsOne))
              ("dwh_dim_shop", AForeign(DIMShop, None, IsOne))
              ])


    let DIMSalesTypeMapping =
        AEntity
            ("dwh_dim_sales_type_mapping",
             [("id", AUuid)
              ("distributor_id", AUuid)
              ("original_value", AString)
              ("normalized_value", AUuid)
              // TODO: implement 'dwh_distributor' relationship
              //("dwh_distributor", AForeign(DWHDistributor, None, IsOne))
              ("dwh_dim_sales_type", AForeign(DIMSalesType, None, IsOne))
              ])

    let DIMLocationMapping =
        AEntity
            ("dwh_dim_location_mapping",
             [("id", AUuid)
              ("distributor_id", AUuid)
              ("original_value", AString)
              ("normalized_value", AUuid)
              // TODO: implement 'dwh_distributor' relationship
              //("dwh_distributor", AForeign(DWHDistributor, None, IsOne))
              ("dwh_dim_location", AForeign(DIMLocation, None, IsOne))
              ])

    // not used in facts
    let DIMAuthor =
        AEntity
            ("dwh_dim_author",
             [("id", AUuid)
              ("author_id", AUuid) // refers to original author id in bookstream's normal DB (business key)
              ("name", AString)])

    let BridgeProductAuthor =
        AEntity
            ("dwh_bridge_product_author",
             [("product_id", AUuid) // refers to DIMProduct's surrogate key
              ("author_id", AUuid)]) // refers to DIMAuthor's surrogate key

    let DIMProductTrack =
        AEntity
            ("dwh_dim_product_track",
             [("id", AUuid)
              ("ean", AString)
              ("isrc", AString)
              ("label", AString)
              ("bookstream_article_number", AString)
              ("track_title", AString)
              ("manuscript_id", AUuid)
              ("manuscript_title", AString)
              //("manuscript_authors", AForeign(BridgeManuscriptAuthor, None, IsMany))
              ])

    let DIMProduct =
        AEntity
            ("dwh_dim_product",
             [("id", AUuid)
              ("title", AString)
              ("ean", AString)
              ("label", AString)
              ("bookstream_article_number", AString)
              ("manuscript_id", AUuid) // manuscript business key (not required for anything for now
              ("manuscript_title", AString)
              //("manuscript_authors", AForeign(BridgeManuscriptAuthor, None, IsMany))
              ])

    let DIMTrack =
        AEntity
            ("dwh_dim_track",
             [("id", AUuid)
              ("isrc", AOption AString)
              ("title", AString)])

    let DIMUser =
        AEntity
            ("dwh_dim_user",
             [("id", AUuid)
              ("user_id", AUuid) // business key
              ("email", AString)
              ("name", AOption AString)])

    let BridgeAccountingGroupShare =
        AEntity
            ("dwh_bridge_accounting_group_share",
             [("accounting_group_id", AUuid) // refers to DIMAccountingGroup's surragate key
              ("user_id", AUuid) // refers to DIMUser's surrogate key
              ("shares", ADouble)])

    let DIMAccountingGroup =
        AEntity
            ("dwh_dim_accounting_group",
             [("id", AUuid)
              ("accounting_group_id", AUuid) // Source accounting group id (business key)
              ("name", AString)
              ("shares", AForeign(BridgeAccountingGroupShare, None, IsMany))])

    let DIMSalesTime =
        AEntity
            ("dwh_dim_sales_time",
             [("id", AUuid)
              ("year", AInt)
              ("month", AInt)
              ("quarter", AInt)])

    let DIMInvoiceTime =
        AEntity
            ("dwh_dim_invoice_time",
             [("id", AUuid)
              ("year", AInt)
              ("month", AOption AInt)
              ("quarter", AInt)])

    let DIMImportEvent =
        AEntity
            ("dwh_dim_import_event",
             [("id", AUuid)
              ("filename", AString) // business key
              ("datetime", ADateTime) // business key
              ("distributor", AString) // business key
              ("year", AInt)
              ("month", AInt)
              ("day", AInt)
              ("hour", AInt)
              ("minute", AInt)
              ("quarter", AInt)
              ("log", AString)])

    let FACTInvoiceLine =
        AEntity
            ("dwh_fact_invoice_line",
             [("product_id", AUuid) // Product
              //("product_track", AForeign(DIMProductTrack, None, IsOne))
              ("track_id", AUuid) // Track
              ("shop_id", AUuid) // Shop
              //("shop", AForeign(DIMShop, None, IsOne))
              ("sales_type_id", AUuid) // Sales Type
              //("sales_type", AForeign(DIMSalesType, None, IsOne))
              ("location_id", AUuid) // Location
              //("location", AForeign(DIMLocation, None, IsOne))
              ("distributor_id", AUuid) // Distributor
              //("distributor", AForeign(DIMDistributor, None, IsOne))
              ("accounting_group_id", AUuid) // Accounting Group
              //("accounting_group", AForeign(DIMAccountingGroup, None, IsOne))
              ("sales_time_id", AUuid) // Sales Time
              //("sales_time", AForeign(DIMSalesTime, None, IsOne))
              ("invoice_time_id", AUuid) // Invoice Time
              //("invoice_time", AForeign(DIMInvoiceTime, None, IsOne))
              ("import_event_id", AUuid) // Import Event
              //("import_event", AForeign(DIMImportedTime, None, IsOne))
              ("sales_amount", AInt)
              ("bookstream_share", ADouble)
              ("unit_income_before_distributor", ADouble)
              ("revenue_before_distributor", ADouble)
              ("revenue_before_copyright", ADouble)
              ("revenue_after_distributor", ADouble)
              ("source_row_number", AInt)])

type ProductStatus =
    { value: string
      comment: string }

and Ware =
    { project_id: string
      id: string
      name: string
      default_blurb: string option
      is_anthology: bool
      project: Project option
    }

and WareWithMinimalProduct =
    { project_id: string
      id: string
      name: string
      default_blurb: string option
      is_anthology: bool
      products: MinimalProduct list
    }

and MusicRightsInfo =
    { value: string
      comment: string }

and ProjectKind =
    { value: string
      comment: string }

and ProductArtistProfileInfo =
    { product_id: string
      artist_profile_id: string
      sort_order: int
      product: Product option
      artist_profile: ArtistProfile option
      role_id: string
      artist_profile_role: ArtistProfileRole option
    }

and MinimalProduct =
    { id: string
      ean: string
      title: string
      sales_start: System.DateTimeOffset
      released_at: System.DateTimeOffset option
      bookstream_article_number: string
      product_status: string
    }

and Product =
    { id: string
      created_at: System.DateTimeOffset
      updated_at: System.DateTimeOffset
      sales_start: System.DateTimeOffset
      sales_end: System.DateTimeOffset option
      delivered_at: System.DateTimeOffset option
      ean: string
      title: string
      bookstream_article_number: string
      total_duration: string // TODO: improve this datatype AInterval
      ware_id: string
      default_price: int option
      subtitle: string
      blurb: string option
      musical_percentage: int
      released_at: System.DateTimeOffset option
      is_spotify: bool
      musical_work_rights: string // TODO: IsEnum becomes the type of the reference object value? Before: MusicRightsInfo option
      product_status:  string // TODO: isEnum becomes string here? Before: ProductStatus option
      ware: Ware option
      product_artist_profile_infos: ProductArtistProfileInfo list
      segmentation_type: string // TODO: isEnum for segmentation_type as string too?
    }

and BaseProject =
    { id: string
      created_at: System.DateTimeOffset
      updated_at: System.DateTimeOffset
      title: string
      computed_title: string option
      computed_authors: string option
      computed_narrators: string option
      description: string option
      project_kind: string // ProjectKind as string
    }

and ProjectProjectSeries =
    { part_number: int
      project_id: string
      project_series_id: string
      project: Project option
      project_series: ProjectSeries option
    }

and Label =
    { id: string
      name: string
      default_c_line: string
      article_number_prefix: string
      next_number: int
      zebralution_info: LabelZebralutionInfo option
      //projects: ProjectLabelInfo list
    }

and Project =
    { id: string
      created_at: System.DateTimeOffset
      updated_at: System.DateTimeOffset
      title: string
      computed_title: string option
      computed_authors: string option
      computed_narrators: string option
      description: string option
      project_kind: string // ProjectKind as string
      manuscript_info: ProjectManuscriptInfoToManuscript option
      production_info: ProjectProductionInfo option
      copyright_info: ProjectCopyrightInfo option
      project_series_info: ProjectProjectSeries option
      accounting_info: ProjectAccountingInfoToAccountingGroup option
      label_info: ProjectLabelInfo option
      wares: WareWithMinimalProduct list
    }

and ProjectAccountingInfo =
    { project_id: string
      accounting_group_id: string
      project: Project option
      accounting_group: AccountingGroup option
    }

and ProjectLabelInfo =
    { project_id: string
      label_id: string
      project: Project option
      label: Label option
    }

and ProjectAccountingInfoToProject =
    { project_id: string
      accounting_group_id: string
      project: Project option }

and ProjectAccountingInfoToAccountingGroup =
    { project_id: string
      accounting_group_id: string
      accounting_group: AccountingGroup option }

and ProjectProductionInfo =
    { accepted_at: System.DateTimeOffset option
      next_deadline: System.DateTimeOffset option
      published_at: System.DateTimeOffset option
      recorded_at: System.DateTimeOffset option
      project_id: string
      project: Project option
      status: string
      project_production_info_readers: ProjectProductionInfoReader list
      project_status: ProjectStatus option
    }

and ProjectCopyrightInfo =
    { project_id: string
      c_line: string
      p_line: string
      project: Project option
    }

and ProjectStatus =
    { value: string
      comment: string }

and ProjectProductionInfoReader =
    { project_id: string
      user_id: string
      total_duration: string // TODO: improve AInterval from string
      reader_name: string
      sort_order: int
      user_info: UserInfo option
      user_reader_info: UserReaderInfo option
      project: Project option
    }

and ProjectManuscriptInfo =
    { manuscript_id: string
      project_id: string
      manuscript: Manuscript option
      project: Project option
    }
and ProjectManuscriptInfoToProject =
    { manuscript_id: string
      project_id: string
      project: BaseProject option
    }
and ProjectManuscriptInfoToManuscript =
    { manuscript_id: string
      project_id: string
      manuscript: Manuscript option
    }

and AuthorManuscript =
    { author_id: string
      manuscript_id: string
      sort_order: int
      author: Author option
      manuscript: Manuscript option
    }

and LabelZebralutionInfo =
    { label_id: string
      zebralution_id: string
      label: Label option }

and Manuscript =
    { id: string
      title: string
      word_count: int
      comment: string
      default_blurb: string
      is_explicit_content: bool
      default_accounting_group: AccountingGroup option
      language_id: string
      language: Language option
      subtitle: string
      is_abridged: bool
      min_age: int option
      max_age: int option
      genre_preset_id: string
      genre_preset: GenrePreset option
      authors: AuthorManuscript list
      projects: ProjectManuscriptInfoToProject list
    }

and ProjectSeries =
    { id: string
      title: string
      episode_designator: string
    }

and Language =
    { value: string
    }

and GenreBisac =
    { id: string
      name: string
      parent_genre: string
    }

and GenreWGS =
    { id: string
      name: string
      parent_genre: string option
    }

and GenreZebralutionPhononet =
    { id: string
      name_de: string
      name_en: string }

and GenrePresetGenreBisac =
    { genre_preset_id: string
      genre_bisac_id: string
      sort_order: int
      genre_bisac: GenreBisac option
    }

and GenrePresetGenreWGS =
    { genre_preset_id: string
      genre_wgs_id: string
      sort_order: int
      genre_wgs: GenreWGS option
    }

and GenrePresetGenreZebralutionPhononet =
    { genre_preset_id: string
      genre_zebralution_phononet_id: string
      sort_order: int
      genre_zebralution_phononet: GenreZebralutionPhononet option
    }

and GenrePreset =
    { id: string
      name: string
      bisac_genres: GenrePresetGenreBisac list
      wgs_genres: GenrePresetGenreWGS list
      zebralution_phononet_genres: GenrePresetGenreZebralutionPhononet list
    }

and AccountingGroupShare =
    { accounting_group_id: string
      percentage: double
      user_id: string
      id: string
      user: User option
    }

and AccountingGroup =
    { id: string
      name: string
      accounting_group_shares: AccountingGroupShare list
    }

and Author =
    { id: string
      name: string
    }

and UserInfo =
    { user_id: string
      name: string
    }

and UserReaderInfo =
    { user_id: string
      pseudonym: string option
      gender: string
      voice_pitch: string
      voice_age: string
      voice_type: string
      is_active: bool
      notes: string
      dialect: string
    }

and UserUserRole =
    { user_id: string
      role: string
    }

and ArtistProfile =
    { id: string
      name: string
    }

and ArtistProfileRole =
    { id: string
      bookstream_description: string
      zebralution_description: string
      zebralution_role_number: int
      rendered_value: string option
    }

and User =
    { id: string
      email: string
      //password_digest: string
      //email_verified: string
      created_at: System.DateTimeOffset option
      updated_at: System.DateTimeOffset option
      //is_active: bool
      info: UserInfo option
    }

and EANStock =
    { ean: string
      used: bool }

and ISRCStock =
    { isrc_prefix: string
      year: int
      counter: int
      comment: string
    }

and ProductTrackInfo =
    { id: string
      isrc: string
      product_id: string
      duration: string // TODO: improve to handle AInterval
      track_number: int
      title: string
      title_version: string
    }

//
// Data Warehouse entity definitions
//
type DWHDistributor =
    { id: string
      name: string
    }
and DIMDistributor =
    { id: string
      name: string
      royalty_sheet_name: string
    }
and DIMShop =
    { id: string
      name: string
    }
and DIMSalesType =
    { id: string
      name: string
    }
and DIMLocation =
    { id: string
      name: string
    }
and DIMShopMappingZebralution =
    { id: string
      distributor_id: string
      original_value: string
      normalized_value: string
      // TODO: dwh_distributor: DWHDistributor
      dwh_dim_shop: DIMShop option
    }
and DIMShopMappingKontor =
    { id: string
      distributor_id: string
      original_lizenznehmer: string
      original_sublizenznehmer: string
      normalized_value: string
      // TODO: dwh_distributor: DWHDistributor
      dwh_dim_shop: DIMShop option
    }
and DIMSalesTypeMapping =
    { id: string
      distributor_id: string
      original_value: string
      normalized_value: string
      // TODO: dwh_distributor: DWHDistributor
      dwh_dim_sales_type: DIMSalesType option
    }
and DIMLocationMapping =
    { id: string
      distributor_id: string
      original_value: string
      normalized_value: string
      // TODO: dwh_distributor: DWHDistributor
      dwh_dim_location: DIMLocation option
    }
and DIMAuthor =
    { id: string
      name: string
    }
and BridgeManuscriptAuthor =
    { manuscript_id: string
      author_id: string
    }
and DIMProductTrack =
    { id: string
      ean: string
      isrc: string
      label: string
      bookstream_article_number: string
      track_title: string
      manuscript_id: string
      manuscript_title: string
      manuscript_authors: DIMAuthor list
    }
and DIMUser =
    { id: string
      email: string
      name: string
    }
and BridgeAccountingGroupShare =
    { id: string
      uid: string
      shares: double
    }
and DIMAccountingGroup =
    { id: string
      accounting_group_id: string
      name: string
      shares: BridgeAccountingGroupShare list
    }
and DIMSalesTime =
    { id: string
      year: int
      month: int
      quarter: int
    }
and DIMInvoiceTime =
    { id: string
      year: int
      month: int option
      quarter: int
    }
and DIMImportedTime =
    { id: string
      datetime: System.DateTimeOffset
      year: int
      month: int
      day: int
      hour: int
      minute: int
      quarter: int
    }
and FACTInvoiceLine =
    { product_id: string
      product_track: DIMProductTrack option
      shop_id: string
      shop: DIMShop option
      sales_type_id: string
      sales_type: DIMSalesType option
      location_id: string
      location: DIMLocation option
      distributor_id: string
      distributor: DIMDistributor option
      accounting_group_id: string
      accounting_group: DIMAccountingGroup option
      sales_time_id: string
      sales_time: DIMSalesTime option
      invoice_time_id: string
      invoice_time: DIMInvoiceTime option
      imported_time_id: string
      imported_time: DIMImportedTime option
      sales_amount: int
      bookstream_share: double
      unit_income_before_distributor: double
      revenue_before_distributor: double
      revenue_before_copyright: double
      revenue_after_distributor: double
      source_row_number: int
    }

type UpdateReturning<'T> =
    { returning: 'T list }

let WareWithMinimalProductToWare (x:WareWithMinimalProduct) =
    { project_id = x.project_id
      id = x.id
      name = x.name
      default_blurb = x.default_blurb
      is_anthology = x.is_anthology
      project = None
    }

type count_aggregate_field = { count: int }
type count_aggregate = { aggregate: count_aggregate_field }
