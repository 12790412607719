module Components.MultiAutoComplete

open Feliz
open Feliz.UseElmish
open Feliz.UseListener
open Feliz.Popover
open MetaGQL.Types
open Components.DragAndDrop
open Components.MultiAutoComplete.Types
open Components.Text
open Queries.Autocomplete.AutoComplete
open Browser.Types
open Browser.Dom
open Elmish
open Client.BaseTypes

type Selection<'T> = string * string * 'T

type MultiAutoCompleteProps<'T,'A> =
    { FieldLabel: string
      Id: string
      SubjectEntity: AEntity
      ObjectEntity: AEntity
      IdName: string
      AttrName: string
      TheType: AType
      CurrentSelection: Selection<'T> list
      AddLabel: string
      Label: string
      Constructor: string -> ExtraRelatedEntityRequest<'T>
      ElIdHighlight: string option
      RelatedRenderer: 'T -> ReactElement
      EditRelatedBody: 'T -> ('T -> unit) -> (unit -> unit) -> ReactElement
      Context: Fable.React.IContext<AutoCompleteContext'<'T,'A>>
      ApiEndpoint: ExtraRelatedEntityRequest<'T> -> Shared.Response<ExtraAutoCompleteResult<'T>>
      OnOrderChanged: (int * 'T * 'T) list -> unit
      OnAdd: string -> unit
      AddRelatedBody: 'A -> ('A -> unit) -> (unit -> unit) -> ReactElement
      AddNew: 'A
      AddApiEndpoint: 'A -> Shared.Response<Result<Queries.Update.Update.UpdateResult list, 'A>>
      AddNewLabel: string
      AddValidator: 'A -> bool * 'A
      OnUpdate: Queries.Update.Update.UpdateResult list -> unit
    }

module RelatedEntity =

    let private renderEntityChoice<'T,'A> = React.functionComponent(fun(props:MultiAutoCompleteProps<'T,'A>, item:ExtraAutoCompleteItem<'T>, is_assigned:bool) ->
        let acCtx = React.useContext props.Context
        Html.li [
            prop.children [
                if not is_assigned then
                    Html.a [
                        prop.className "tag is-light is-info"
                        prop.text item.value
                        //prop.onClick (fun _ -> AddTagToTask (taskId, baseTag.name, baseTag.id) |> dispatch)
                        prop.onClick (fun _ ->
                            // TODO: refactor this Msg so we can use another one for assignment
                            let el = match props.ElIdHighlight with
                                     | Some elId -> document.querySelector elId :?> HTMLElement |> Some
                                     | None -> None
                            printfn "%A" props.ObjectEntity
                            printfn "%A" props.IdName
                            printfn "%A" props.AttrName
                            printfn "%A" props.Id
                            printfn "%A" item.id
                            printfn "%A" el
                            acCtx.ACDispatch <| AddMultiRelatedEntity (props.ObjectEntity, props.IdName, props.AttrName, props.Id, item.id, el)
                            acCtx.ACDispatch <| ExitAutoComplete (props.Id, props.SubjectEntity, props.ObjectEntity)
                            props.OnAdd item.id
                            )
                    ]
                else
                    Html.span [
                        prop.className "tag is-light is-danger"
                        prop.text item.value
                    ]
            ]
        ])

    let private renderSuggestionList<'T,'A> = React.functionComponent(fun(props:MultiAutoCompleteProps<'T,'A>) ->
        let acCtx = React.useContext props.Context
        let idSet = List.map (fun (i,_,_) -> i) props.CurrentSelection |> Set.ofList
        match acCtx.ACState.AutoCompleteResult with
        | HasNotStartedYet -> Html.none
        | InProgress -> Html.none
        | Resolved (Ok res) ->
            if res.extra_items.Length > 0 then
                FrontendUtils.AutoComplete.renderSuggestionList
                    (List.length res.extra_items)
                    (React.fragment [
                        for x in res.extra_items ->
                            renderEntityChoice<'T,'A> (props, x, (idSet.Contains x.id))
                    ])

            else
                FrontendUtils.AutoComplete.renderNoMatchSuggestionList
                    (Html.p (sprintf "'%s' not found" acCtx.ACState.AutoCompleteText))
        | Resolved (Error (errorMsg:string)) ->
            Html.h1 [
                prop.style [ style.color.crimson ]
                prop.text errorMsg
            ])

    let render<'T,'A> = React.functionComponent(fun(props:MultiAutoCompleteProps<'T,'A>) ->
        let acCtx = React.useContext props.Context
        let elRef = React.useElementRef ()
        React.useListener.onClickAway(elRef, fun _ -> ExitAutoComplete (props.Id, props.SubjectEntity, props.ObjectEntity) |> acCtx.ACDispatch)
        Html.div [
            prop.ref elRef
            prop.children [
                 FrontendUtils.AutoComplete.render
                     { FrontendUtils.AutoComplete.placeHolder = props.Label
                       FrontendUtils.AutoComplete.valueOrDefault = acCtx.ACState.AutoCompleteText
                       FrontendUtils.AutoComplete.onChange = (fun txt -> AutoCompleteRequest (props.Id, txt, props.Constructor txt, props.SubjectEntity, props.ObjectEntity)
                                                                         |> acCtx.ACDispatch)
                       FrontendUtils.AutoComplete.onKeyPress = (fun ke ->
                                                                    match ke.key with
                                                                    | "Enter" ->
                                                                        if ke.ctrlKey then
                                                                            // create tag if possible
                                                                            match acCtx.ACState.AutoCompleteResult with
                                                                            | HasNotStartedYet -> acCtx.ACDispatch DoNothing
                                                                            | InProgress -> acCtx.ACDispatch DoNothing
                                                                            | Resolved (Ok (res:ExtraAutoCompleteResult<'T>)) ->
                                                                                if res.extra_items.Length = 0 then
                                                                                    CreateRelatedEntity (props.Id, props.ObjectEntity, acCtx.ACState.AutoCompleteText) |> acCtx.ACDispatch
                                                                                else
                                                                                    acCtx.ACDispatch DoNothing
                                                                            | Resolved (Error _) -> acCtx.ACDispatch DoNothing
                                                                        else
                                                                            // assign the tag from the top of suggestion list
                                                                            AssignTopSuggestedRelatedEntity (props.Id, props.SubjectEntity, props.ObjectEntity) |> acCtx.ACDispatch
                                                                    | _ -> FrontendUtils.debugKey "onKeyPress" ke)
                       FrontendUtils.AutoComplete.onKeyDown = (fun ke ->
                                                                  match ke.key with
                                                                  | "Escape" -> ExitAutoComplete (props.Id, props.SubjectEntity, props.ObjectEntity) |> acCtx.ACDispatch
                                                                  | _ -> FrontendUtils.debugKey "onKeyDown" ke)
                       FrontendUtils.AutoComplete.suggestionList = renderSuggestionList<'T,'A> props
                     }
            ]
        ])


module MultiAutoComplete =

    let init (props:MultiAutoCompleteProps<'T,'A>) =
        { AutoCompleteActive = None;
          AutoCompleteText = "";
          AutoCompleteResult = HasNotStartedYet
          ApiEndpoint = props.ApiEndpoint
          AddApiEndpoint = props.AddApiEndpoint
          OnUpdate = props.OnUpdate
        }, Cmd.none

    let private call dispatch callF responseF = Utilities.Remoting.withException dispatch callF responseF DoNothing
    let private err okF result = Utilities.Remoting.err DoNothing okF result

    let update (appCtx : Client.Types.AppContext) msg state =
        let token = { Indygemma.Auth.Types.Token = appCtx.Jwt }
        match msg with
        | DoNothing ->
            state, Cmd.none
        | AutoCompleteRequest (id, query, req, subjEntity, objEntity) ->
            printfn "---- SENDING AUTOCOMPLETE REQUEST: %s %A" query state.AutoCompleteActive
            let op _dispatch =
                call appCtx.AppDispatch (state.ApiEndpoint req)
                    (err (fun res ->
                           printfn "-- AUTOCOMPLETE RESULT %A" res
                           LoadAutoCompleteResult (Finished (Ok res))))

            { state with
                AutoCompleteText = query;
                AutoCompleteActive = Some (id, subjEntity, objEntity)
            }, Cmd.fromAsync op
        | LoadAutoCompleteResult Started ->
            printfn "---- ACTIVE AUTOCOMPLETE REQUEST: %A" state.AutoCompleteActive
            { state with AutoCompleteResult = HasNotStartedYet }, Cmd.none
        | LoadAutoCompleteResult (Finished res) ->
            printfn "---- ACTIVE AUTOCOMPLETE REQUEST: %A" state.AutoCompleteActive
            { state with AutoCompleteResult = Resolved res }, Cmd.none
        | CreateRelatedEntity (id, AEntity (name, _), autoCompleteText) ->
            printfn "CREATING RELATED ENTITY for %s with id %s. AUTOCOMPLETE TEXT: %s" name id autoCompleteText
            state, Cmd.none
        | AddNewRelatedEntity newInput ->
            let op _dispatch =
                call appCtx.AppDispatch (state.AddApiEndpoint newInput)
                    (err (fun res ->
                           match res with
                           | Ok updated -> state.OnUpdate updated // before: dispatch <| Client.Types.LoadUpdatedEntity (Finished (Ok updated))
                           | Error _newInput ->
                             // TODO: need to populate this in the existing popover after failed server validation
                             ()
                           DoNothing))

            state, Cmd.fromAsync op
        | AddMultiRelatedEntity (entity, idName, attrName, pk, attrValue, mEl) ->
            printf "(AddMultiRelatedEntity) entity:%s attrName:%s pk:%s value:%A"
                   (AEntity.tableName entity) attrName pk attrValue
            let op _dispatch =
                call appCtx.AppDispatch (Server.api.AddMultiRelatedEntity token entity idName attrName pk attrValue)
                    (err (fun res ->
                           printfn "-- UPDATE RESULT %A" res
                           match mEl with
                           | Some el ->
                             FrontendUtils.yellowFadeElement el |> ignore
                           | None -> ()
                           // state.AppDispatch <| Client.Types.LoadUpdatedEntity (Finished (Ok res))
                           state.OnUpdate res
                           DoNothing))

            let nextCmds = [Cmd.fromAsync op]
            state, Cmd.batch nextCmds
        | DeleteRelatedEntity (entity, pk, attrValue, sortOrder) ->
            let op _dispatch =
                call appCtx.AppDispatch (Server.api.DeleteMultiRelatedEntity token entity pk attrValue sortOrder)
                    (err (fun res ->
                           printfn "res: %A" res
                           // state.AppDispatch <| Client.Types.LoadUpdatedEntity (Finished (Ok res))
                           state.OnUpdate res
                           DoNothing))

            state, Cmd.fromAsync op
        | AssignTopSuggestedRelatedEntity (id, AEntity (subjName, _), AEntity (objName, _)) ->
            printfn "PICK TOP SUGGESTED RELATED ENTITY FOR %A with id %s." (subjName, objName) id
            state, Cmd.none
        | ExitAutoComplete (id, AEntity (subjName, _), AEntity (objName, _)) ->
            printfn "EXIT AUTOCOMPLETE: %A with id %s" (subjName, objName) id
            { state with AutoCompleteText = ""
                         AutoCompleteActive = None
                         AutoCompleteResult = HasNotStartedYet } , Cmd.none

    type Item<'T> =
        { SubjectId: string
          RelatedId: string
          RelatedLabel: string
          RelatedEntity: 'T
        }

    type ConfirmDeleteProps =
        { OnConfirm: unit -> bool }

    let popoverConfirmDelete = React.functionComponent(fun (props: ConfirmDeleteProps) ->
        let popoverOpen, toggleOpen = React.useState false
        let enableCover () =
            let el = document.getElementById("global-cover")
            el.classList.add "cover-screen"
        let disableCover () =
            let el = document.getElementById("global-cover")
            el.classList.remove "cover-screen"
        Popover.popover [
            popover.body [
                Html.div [
                    prop.className "bg-white w-auto h-auto p-10 rounded-lg flex flex-col space-y-4"
                    prop.children [
                        Html.p "Confirm removal of this item."
                        Html.div [
                            prop.className "flex flex-row justify-between"
                            prop.children [
                                Button.button (Some "Cancel") None "is-light"
                                    [ prop.onClick (fun _ ->
                                                     toggleOpen false
                                                     disableCover ())
                                    ]
                                Button.button (Some "Confirm") None "is-danger"
                                    [ prop.onClick (fun _ ->
                                                     if props.OnConfirm () then
                                                         disableCover ()
                                                         toggleOpen false
                                                     else ())
                                    ]
                            ]
                        ]
                    ]
                ]
            ]
            popover.isOpen popoverOpen
            popover.place.below
            popover.onOuterAction (fun _ ->
                disableCover ()
                toggleOpen false
                )
            popover.children [
                Html.a [
                    prop.className "tag is-delete"
                    prop.onClick (fun _ ->
                        toggleOpen true
                        enableCover ())
                ]
            ]
        ])

    type PopoverRelatedInput =
        { Body: (unit -> unit) -> ReactElement
          Button: (MouseEvent -> unit) -> ReactElement
          OnConfirm: MouseEvent -> bool
          ConfirmLabel: string }

    let popoverRelated = React.functionComponent(fun(props:PopoverRelatedInput) ->
        let popoverOpen, toggleOpen = React.useState false
        let enableCover () =
            let el = document.getElementById("global-cover")
            el.classList.add "cover-screen"
        let disableCover () =
            let el = document.getElementById("global-cover")
            el.classList.remove "cover-screen"
        let doClose () =
            toggleOpen false
            disableCover ()
        Popover.popover [
            popover.body [
                Html.div [
                    prop.className "bg-white w-auto h-auto p-10 rounded-lg flex flex-col space-y-4"
                    prop.children [
                        props.Body doClose
                        Html.div [
                            prop.className "flex flex-row justify-between"
                            prop.children [
                                Button.button (Some "Cancel") None "is-light"
                                    [ prop.onClick (fun _ ->
                                                     doClose ())
                                    ]
                                Button.button (Some props.ConfirmLabel) (Some "fas fa-check-circle") "is-success is-light"
                                    [ prop.onClick (fun e ->
                                                     if props.OnConfirm e then
                                                         doClose ()
                                                         e.preventDefault ()
                                                     else ())
                                    ]
                            ]
                        ]
                    ]
                ]
            ]
            popover.isOpen popoverOpen
            popover.place.below
            popover.onOuterAction (fun _ ->
                disableCover ()
                toggleOpen false
                )
            popover.children [
                props.Button (fun _ -> toggleOpen true
                                       enableCover ())
            ]
        ])

    type EditRelatedProps =
        { Body: (unit -> unit) -> ReactElement }

    let popoverEditRelated = React.functionComponent(fun(props:EditRelatedProps) ->
        popoverRelated { Body = props.Body
                         Button = (fun onClick -> Html.a [
                                        prop.className "tag hover:bg-gray-200"
                                        prop.onClick onClick
                                        prop.children [
                                            FrontendUtils.iconSpan "far fa-edit"
                                        ]
                                    ])
                         OnConfirm = fun _ -> true
                         ConfirmLabel = "Ok"
                        })

    type AddRelatedProps =
        { Body: (unit -> unit) -> ReactElement
          AddLabel: string
          OnConfirm: MouseEvent -> bool }

    let popoverAddRelated = React.functionComponent(fun(props:AddRelatedProps) ->
        popoverRelated { Body = props.Body
                         Button = (fun onClick -> Button.button (Some props.AddLabel) (Some "fas fa-plus-circle") "is-success is-light"
                                                                [ prop.onClick onClick])
                         OnConfirm = props.OnConfirm
                         ConfirmLabel = "Create"
                        })

    type ItemsProps<'T,'A> =
        { Id: string
          SubjectEntity: AEntity
          ObjectEntity: AEntity
          Selection: Selection<'T> list
          Dispatch: Msg<'T,'A> -> unit
          LocalItems: DragAndDrop.IItem<Item<'T>> list
          SetLocalItems: DragAndDrop.IItem<Item<'T>> list -> unit
          RelatedRenderer: 'T -> ReactElement
          EditRelatedBody: 'T -> ('T -> unit) -> (unit -> unit) -> ReactElement
          OnOrderChanged: (int * 'T * 'T) list -> unit
          Context: Fable.React.IContext<AutoCompleteContext'<'T,'A>>
        }

    let renderTag<'T,'A> = React.functionComponent(fun(idx:int, x:Item<'T>, props:ItemsProps<'T,'A>) ->
        let currentRelatedEntity, setCurrentRelatedEntity = React.useState x.RelatedEntity
        let acCtx = React.useContext props.Context
        Html.div [
            prop.className "control flex flex-row hover:bg-indigo-50 px-3 py-4 items-center rounded-lg justify-between text-right"
            prop.children [
                Text.wrapText ("Position", Html.div [
                    prop.className "flex items-center space-x-2 cursor-pointer"
                    prop.children [
                        FrontendUtils.iconSpan "fas fa-grip-horizontal"
                        Html.p [
                            prop.className ""
                            prop.text (sprintf "%d." (idx+1))
                        ]
                    ]
                ])
                React.fragment [
                    props.RelatedRenderer currentRelatedEntity
                    Text.wrapText ("Action",
                        Html.div [
                            prop.className "flex flex-row items-center"
                            prop.children [
                                popoverEditRelated { Body = props.EditRelatedBody currentRelatedEntity setCurrentRelatedEntity}
                                popoverConfirmDelete { OnConfirm = fun _ ->
                                    acCtx.ACDispatch <| DeleteRelatedEntity (props.ObjectEntity, x.SubjectId, x.RelatedId, (idx+1))
                                    true }
                            ]
                        ])
                        //           Html.a [
                        //prop.className "tag is-delete"
                        //prop.onClick (fun _ -> DeleteTagFromTask (taskId, x.id) |> dispatch)
                        //prop.children [
                        //    FrontendUtils.iconSpan "far fa-edit"
                        //]
                    //]
                ]
            ]
        ])

    let renderTags<'T,'A> = React.functionComponent(fun(props:ItemsProps<'T,'A>) ->
            Html.div [
                prop.id (sprintf "multi-tags-%s" props.Id)
                prop.className "field is-grouped"
                prop.children [
                    DragAndDrop.render<Item<'T>> {
                        DragAndDrop.Id = sprintf "dnd-%s" props.Id
                        DragAndDrop.CssClasses = "multi-entities"
                        DragAndDrop.Items = props.LocalItems
                        DragAndDrop.ItemRenderer = (fun item idx ->
                            let x = item.item
                            renderTag<'T,'A> (idx,x,props))
                        DragAndDrop.OnChange = (fun newItems ->
                            let newItems' =
                                newItems
                                |> List.mapi (fun idx x -> (idx, x.item.RelatedId, x.item.RelatedEntity))
                            let oldItems =
                                props.LocalItems
                                |> List.mapi (fun idx x -> (idx, x.item.RelatedId, x.item.RelatedEntity))
                            let changed = List.filter (fun ((idx1, relId1, _x), (idx2, relId2, _y)) -> idx1 = idx2 && relId1 <> relId2)
                                                      (List.zip oldItems newItems')
                            props.OnOrderChanged (List.map (fun ((idx1, _relId1, old), (_idx2, _relId2, new')) -> (idx1, old, new')) changed)
                            //printfn "CHANGED: %A" changed
                            props.SetLocalItems newItems)
                    }
                ]
            ])

    let autoCompleteInput<'T,'A> = React.functionComponent(fun(props:MultiAutoCompleteProps<'T,'A>) ->
        let newRelated, setNewRelated = React.useState props.AddNew
        let appCtx = React.useContext(Context.AppContext)
        let state, dispatch = React.useElmish(init props, update appCtx)
        let acCtx:AutoCompleteContext'<'T,'A> =
            { ACState = state
              ACDispatch = dispatch
            }
        let initSelection =
            (List.mapi (fun idx ((relId,relLabel,relEntity):Selection<'T>) ->
                            let innerItem = { SubjectId = props.Id
                                              RelatedId = relId
                                              RelatedLabel = relLabel
                                              RelatedEntity = relEntity }
                            let item : DragAndDrop.IItem<Item<'T>> =
                                DragAndDrop.createItem<Item<'T>> idx innerItem
                            item) props.CurrentSelection)
        let localItems, setLocalItems = React.useState initSelection
        let renderSelected =
            Html.div [
                prop.className ""
                prop.children [
                    renderTags<'T,'A> { Id = props.Id
                                        SubjectEntity = props.SubjectEntity
                                        ObjectEntity = props.ObjectEntity
                                        Selection = props.CurrentSelection
                                        Dispatch = acCtx.ACDispatch
                                        LocalItems = localItems
                                        SetLocalItems = setLocalItems
                                        RelatedRenderer = props.RelatedRenderer
                                        EditRelatedBody = props.EditRelatedBody
                                        OnOrderChanged = props.OnOrderChanged
                                        Context = props.Context
                                      }
                ]
            ]
        let addButton =
            Html.div [
                prop.className "flex flex-row justify-between"
                prop.children [
                    Button.button (Some props.AddLabel) (Some "fas fa-plus-circle") ""
                        [ prop.onClick (fun _ ->
                             AutoCompleteRequest (props.Id, acCtx.ACState.AutoCompleteText,
                                                  props.Constructor acCtx.ACState.AutoCompleteText, props.SubjectEntity,
                                                  props.ObjectEntity) |> acCtx.ACDispatch) ]
                    popoverAddRelated {
                        AddLabel = props.AddNewLabel
                        Body = props.AddRelatedBody newRelated setNewRelated
                        OnConfirm = fun _ ->
                            let ok, newX = props.AddValidator newRelated
                            //printfn "newX: %A" newX
                            setNewRelated newX
                            //printfn "OK: %A" ok
                            if ok then
                                acCtx.ACDispatch <| AddNewRelatedEntity newX // TODO: need to control when popover is removed after server validation
                                setNewRelated props.AddNew
                            else ()
                            ok
                    }
                ]
            ]
        let renderInner inner =
            Html.div [
                prop.className "field"
                prop.children [
                    Html.label [
                        prop.className "label"
                        prop.text props.FieldLabel
                    ]
                    renderSelected
                    inner
                ]
            ]
        React.contextProvider(props.Context, acCtx,
            match acCtx.ACState.AutoCompleteActive with
            | None -> renderInner addButton
            | Some (activeId, activeSubjEntity, activeObjEntity) ->
                match activeId = props.Id && activeSubjEntity = props.SubjectEntity && activeObjEntity = props.ObjectEntity with
                | true ->
                    let autocompleteComponent = RelatedEntity.render<'T,'A> props
                    renderInner autocompleteComponent
                | false ->
                    renderInner addButton))
