module Client.Url

open Feliz.Router
open Client.Types

let parseUrl = function
    | [ ] -> Url.Index
    | [ "products"; "edit"; productId ] -> Url.EditProduct productId
    | [ "projects"; "edit"; projectId ] -> Url.EditProject projectId
    | [ "projects"; "edit"; projectId; Route.Query [ "focus", Route.Bool doFocus ] ] -> Url.EditProjectFocusTitle (projectId, doFocus)
    | _ -> Url.NotFound

let toUrl = function
    | Url.Index -> "/"
    | Url.NotFound -> "/NotFound"
    | Url.EditProduct productId -> sprintf "/products/edit/%s" productId
    | Url.EditProject projectId -> sprintf "/projects/edit/%s" projectId
    | Url.EditProjectFocusTitle (projectId, doFocus) -> sprintf "/projects/edit/%s?focus=%A" projectId doFocus

let navigateToUrl = function
    | Url.Index -> Cmd.navigate("")
    | Url.NotFound -> Cmd.navigate("NotFound")
    | Url.EditProduct productId -> Cmd.navigate("products", "edit", productId)
    | Url.EditProject projectId -> Cmd.navigate("projects", "edit", projectId)
    | Url.EditProjectFocusTitle (projectId, doFocus) -> Cmd.navigate("projects", "edit", projectId, [ "focus", sprintf "%A" doFocus ])
