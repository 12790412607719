module Components.SingleAutoComplete

open Client.BaseTypes
open Client.Types
open MetaGQL.Types
open Queries.Autocomplete.AutoComplete
open Feliz
open Feliz.UseElmish
open Feliz.UseListener
open Elmish

type Selection = string * string

type Msg =
    | LoadAutoCompleteResult of AsyncOperationStatus<Result<SimpleAutoCompleteResult, string>>
    | AutoCompleteRequest of pk:(string * string) list * query:string * SimpleRelatedEntityRequest * AEntity * AEntity
    | DoNothing
    | CreateRelatedEntity of pk:(string * string) list * AEntity * autoCompleteText:string
    | AssignTopSuggestedRelatedEntity of pk:(string * string) list * AEntity * AEntity
    | ExitAutoComplete

type State =
    { AutoCompleteActive: ((string * string) list * AEntity * AEntity) option // an active auto complete is identified by an id and the request type
      AutoCompleteText: string
      AutoCompleteResult: Deferred<Result<SimpleAutoCompleteResult, string>> }

type AutoCompleteContext' =
    { ACState: State
      ACDispatch: Msg -> unit
    }
let AutoCompleteContext:Fable.React.IContext<AutoCompleteContext'> = React.createContext(name="SingleAutoCompleteContext")

type SingleAutoCompleteProps =
    { FieldLabel: string
      SubjectEntity: AEntity
      ObjectEntity: AEntity
      Pk: (string * string) list
      AttrName: string
      TheType: AType
      CurrentSelection: Selection list
      AddLabel: string
      Label: string
      Constructor: string -> SimpleRelatedEntityRequest
      OnSelect: SingleAutoCompleteProps -> SimpleAutoCompleteItem -> bool -> unit
    }

module RelatedEntity =

    let private renderEntityChoice = React.functionComponent(fun(props:SingleAutoCompleteProps, item:SimpleAutoCompleteItem, is_assigned:bool) ->
        let acCtx = React.useContext AutoCompleteContext
        let _appCtx = React.useContext Context.AppContext
        Html.li [
            prop.children [
                if not is_assigned then
                    Html.a [
                        prop.className "tag is-light is-info"
                        prop.text item.value
                        //prop.onClick (fun _ -> AddTagToTask (taskId, baseTag.name, baseTag.id) |> dispatch)
                        prop.onClick (fun _ ->
                            printf "ON CLICK. item:%A, is_assigned:%A" item is_assigned
                            props.OnSelect props item is_assigned
                            acCtx.ACDispatch <| ExitAutoComplete
                            )
                    ]
                else
                    Html.span [
                        prop.className "tag is-light is-danger"
                        prop.text item.value
                    ]
            ]
        ])

    let private renderSuggestionList = React.functionComponent(fun(props:SingleAutoCompleteProps) ->
        let acCtx = React.useContext AutoCompleteContext
        let idSet = List.map fst props.CurrentSelection |> Set.ofList
        match acCtx.ACState.AutoCompleteResult with
        | HasNotStartedYet -> Html.none
        | InProgress -> Html.none
        | Resolved (Ok res) ->
            if res.simple_items.Length > 0 then
                FrontendUtils.AutoComplete.renderSuggestionList
                    (List.length res.simple_items)
                    (React.fragment [
                        for x in res.simple_items ->
                            renderEntityChoice (props, x, (idSet.Contains x.id))
                    ])
            else
                FrontendUtils.AutoComplete.renderNoMatchSuggestionList
                    (Html.p (sprintf "'%s' not found" acCtx.ACState.AutoCompleteText))
        | Resolved (Error (errorMsg:string)) ->
            Html.h1 [
                prop.style [ style.color.crimson ]
                prop.text errorMsg
            ])

    let render = React.functionComponent(fun(props:SingleAutoCompleteProps) ->
        let acCtx = React.useContext AutoCompleteContext
        let elRef = React.useElementRef ()
        React.useListener.onClickAway(elRef, fun _ -> ExitAutoComplete |> acCtx.ACDispatch)
        Html.div [
            prop.ref elRef
            prop.children [
                 FrontendUtils.AutoComplete.render
                     {   FrontendUtils.AutoComplete.placeHolder = props.Label
                         FrontendUtils.AutoComplete.valueOrDefault = acCtx.ACState.AutoCompleteText
                         FrontendUtils.AutoComplete.onChange = (fun txt -> AutoCompleteRequest (props.Pk, txt, props.Constructor txt, props.SubjectEntity, props.ObjectEntity)
                                                                           |> acCtx.ACDispatch)
                         FrontendUtils.AutoComplete.onKeyPress = (fun ke ->
                                                                      match ke.key with
                                                                      | "Enter" ->
                                                                          if ke.ctrlKey then
                                                                              // create tag if possible
                                                                              match acCtx.ACState.AutoCompleteResult with
                                                                              | HasNotStartedYet -> acCtx.ACDispatch DoNothing
                                                                              | InProgress -> acCtx.ACDispatch DoNothing
                                                                              | Resolved (Ok (res:SimpleAutoCompleteResult)) ->
                                                                                  if res.simple_items.Length = 0 then
                                                                                      CreateRelatedEntity (props.Pk, props.ObjectEntity, acCtx.ACState.AutoCompleteText) |> acCtx.ACDispatch
                                                                                  else
                                                                                      acCtx.ACDispatch DoNothing
                                                                              | Resolved (Error _) -> acCtx.ACDispatch DoNothing
                                                                          else
                                                                              // assign the tag from the top of suggestion list
                                                                              AssignTopSuggestedRelatedEntity (props.Pk, props.SubjectEntity, props.ObjectEntity) |> acCtx.ACDispatch
                                                                      | _ -> FrontendUtils.debugKey "onKeyPress" ke)
                         FrontendUtils.AutoComplete.onKeyDown = (fun ke -> match ke.key with
                                                                           | "Escape" ->
                                                                                 ExitAutoComplete |> acCtx.ACDispatch
                                                                           | _ -> FrontendUtils.debugKey "onKeyDown" ke)
                         FrontendUtils.AutoComplete.suggestionList = (renderSuggestionList props)
                     }
            ]
        ]
        )

module SingleAutoComplete =

    let init () =
        { AutoCompleteActive = None;
          AutoCompleteText = "";
          AutoCompleteResult = HasNotStartedYet }, Cmd.none

    let private call dispatch callF responseF = Utilities.Remoting.withException dispatch callF responseF DoNothing
    let private err okF result = Utilities.Remoting.err DoNothing okF result

    let update (appCtx : AppContext) msg state =
        let token = { Indygemma.Auth.Types.Token = appCtx.Jwt }
        match msg with
        | DoNothing ->
            state, Cmd.none
        | AutoCompleteRequest (id, query, req, subjEntity, objEntity) ->
            printfn "---- SENDING AUTOCOMPLETE REQUEST: %s %A" query state.AutoCompleteActive
            let op _dispatch =
                call appCtx.AppDispatch (Server.api.SimpleSearchRelatedEntities token req)
                    (err (fun res ->
                           printfn "-- AUTOCOMPLETE RESULT %A" res
                           LoadAutoCompleteResult (Finished (Ok res))))
            { state with
                AutoCompleteText = query;
                AutoCompleteActive = Some (id, subjEntity, objEntity)
            }, Cmd.fromAsync op
        | LoadAutoCompleteResult Started ->
            printfn "---- ACTIVE AUTOCOMPLETE REQUEST: %A" state.AutoCompleteActive
            { state with AutoCompleteResult = HasNotStartedYet }, Cmd.none
        | LoadAutoCompleteResult (Finished res) ->
            printfn "---- ACTIVE AUTOCOMPLETE REQUEST: %A" state.AutoCompleteActive
            { state with AutoCompleteResult = Resolved res }, Cmd.none
        | CreateRelatedEntity (pk, AEntity (name, _), autoCompleteText) ->
            printfn "CREATING RELATED ENTITY for %s with pk: %A. AUTOCOMPLETE TEXT: %s" name pk autoCompleteText
            state, Cmd.none
        | AssignTopSuggestedRelatedEntity (pk, AEntity (subjName, _), AEntity (objName, _)) ->
            printfn "PICK TOP SUGGESTED RELATED ENTITY FOR %A with id %A." (subjName, objName) pk
            state, Cmd.none
        | ExitAutoComplete ->
            { state with AutoCompleteText = ""
                         AutoCompleteActive = None
                         AutoCompleteResult = HasNotStartedYet } , Cmd.none

    let renderTag (_subjectEntity:AEntity) (_objEntity:AEntity) ((_relatedId,relatedLabel):Selection) _dispatch onChangeHandler =
        Html.div [
            prop.className "control"
            prop.children [
                Html.div [
                    prop.className ""
                    prop.children [
                        Html.a [
                            prop.className "tag"
                            prop.onClick onChangeHandler
                            prop.children [
                                FrontendUtils.iconSpan "far fa-edit"
                            ]
                        ]
                        Html.a [
                            prop.className "tag is-light is-info"
                            prop.onClick onChangeHandler
                            prop.text relatedLabel
                        ]
                    ]
                ]
            ]
        ]

    let autoCompleteInput = React.functionComponent(fun(props:SingleAutoCompleteProps) ->
        let appCtx = React.useContext Context.AppContext
        let state, dispatch = React.useElmish(init, update appCtx)
        let appCtx = React.useContext Context.AppContext
        let acCtx:AutoCompleteContext' =
            { ACState = state
              ACDispatch = dispatch
            }
        let onChangeHandler = fun _ -> AutoCompleteRequest (props.Pk, state.AutoCompleteText,
                                                            props.Constructor state.AutoCompleteText,
                                                            props.SubjectEntity, props.ObjectEntity) |> dispatch
        let addButton =
            Html.div [
                prop.className "max-w-sm"
                prop.children [
                    match List.tryHead props.CurrentSelection with
                    | None ->
                        Button.button (Some props.AddLabel) (Some "fas fa-plus-circle") ""
                            [ prop.onClick onChangeHandler ]
                    | Some x ->
                        renderTag props.SubjectEntity props.ObjectEntity x appCtx.AppDispatch onChangeHandler
                ]
            ]
        let renderInner inner =
            Html.div [
                prop.className "field"
                prop.children [
                    Html.label [
                        prop.className "label"
                        prop.text props.FieldLabel
                    ]
                    inner
                ]
            ]
        React.contextProvider(AutoCompleteContext, acCtx,
            match acCtx.ACState.AutoCompleteActive with
            | None -> renderInner addButton
            | Some (activeId, activeSubjEntity, activeObjEntity) ->
                printf "activeId: %A, activeSubjEntity: %A, activeObjEntity: %A" activeId activeSubjEntity activeObjEntity
                match activeId = props.Pk && activeSubjEntity = props.SubjectEntity && activeObjEntity = props.ObjectEntity with
                | true ->
                    let autocompleteComponent = RelatedEntity.render props
                    renderInner autocompleteComponent
                | false ->
                    renderInner addButton))

