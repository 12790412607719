﻿namespace Indygemma.Auth.Client

open Fable.SimpleHttp
open Fable.SimpleJson

module Auth =

    [<CLIMutable>]
    type LoginViewModel =
        {
            Email : string
            Password : string
        }

    [<CLIMutable>]
    type TokenResult =
        {
            isValid : bool
            errorMessage : string
            token : string
        }

    [<CLIMutable>]
    type RefreshTokenResult =
        {
            isRefreshSuccess : bool
            accessToken : string
        }

    let refreshAccessToken successF errorMsg =
        async {
            let! response =
                Http.request "/refresh"
                |> Http.method POST
                |> Http.send

            if response.statusCode = 200 then
                let jsonResult = Json.parseAs<RefreshTokenResult>(response.responseText)
                if jsonResult.isRefreshSuccess then
                    return successF jsonResult.accessToken
                else
                    return errorMsg ()
            else
                return errorMsg ()
        }


    let logout msg =
        async {
            let! _response =
                Http.request "/logout"
                |> Http.method POST
                |> Http.send

            return msg ()
        }

    let login username password onLoginF onErrorF onClearErrorF =
        async {
            let contentString = SimpleJson.stringify { Email = username; Password = password }
            let! response =
                Http.request "/login"
                |> Http.method POST
                |> Http.content (BodyContent.Text contentString)
                |> Http.header (Headers.contentType "application/json")
                |> Http.send

            if response.statusCode = 200 then
                let jsonResult = Json.parseAs<TokenResult>(response.responseText)
                if jsonResult.isValid then
                    onLoginF jsonResult.token
                    return onClearErrorF ()
                else
                    return (onErrorF jsonResult.errorMessage)
            else
                return (onErrorF "Cannot access Auth Server")
        }
