module Components.IntInput

open Feliz
open Feliz.Bulma
open Feliz.UseListener
open Browser.Dom
open Browser.Types
open MetaGQL.Types

module IntInput =

    let intSliderInput =
        React.functionComponent(fun (input: {| min:int
                                               max:int
                                               fieldLabel:string
                                               mValue:int option
                                               disabled:bool
                                               onChange:int -> HTMLElement -> unit
                                               attrName:string
                                               pk:string  |}) ->
            let isFocused, setIsFocused = React.useState false
            let localValue, setLocalValue = React.useState(Option.defaultValue 0 input.mValue)
            let localTimer, setLocalTimer = React.useState(None)
            let elId = sprintf "int-slider-%s-%s" input.pk input.attrName
            let getEl () = document.querySelector ("#" + elId) :?> HTMLElement
            let updateLocalValue x =
                match localTimer with
                | Some timer ->
                    window.clearTimeout timer
                | None ->
                    ()
                setLocalValue(x)
                let timerId = window.setTimeout((fun _ -> input.onChange x (getEl())), 150)
                setLocalTimer(Some timerId)

            let elRef = React.useElementRef ()

            Html.div [
                prop.ref elRef
                prop.className "field"
                prop.children [
                    Html.label [
                        prop.className "label"
                        prop.text input.fieldLabel
                    ]
                    Bulma.level [
                        Bulma.levelLeft [
                            Bulma.levelItem [
                                Button.button None (Some "fas fa-minus") "" [
                                    prop.onClick (fun _ ->
                                        updateLocalValue(localValue - 1)
                                    )
                                ]
                            ]
                            Bulma.levelItem [
                                Html.input [
                                    prop.id elId
                                    prop.className "w-16 p-2"
                                    prop.valueOrDefault localValue
                                    prop.onChange (fun (_x:string) -> ())
                                    prop.onFocus (fun _ -> setIsFocused true)
                                    prop.onBlur (fun e ->
                                        if isFocused then
                                            let newText = FrontendUtils.Extensions.eventValue e
                                            updateLocalValue(newText |> int)
                                            setIsFocused false
                                        else ())
                                    prop.onKeyPress (key.enter, fun (e:KeyboardEvent) ->
                                        //let newInt = FrontendUtils.Extensions.textContent e |> int
                                        let el = e.target :?> HTMLInputElement
                                        //printfn "UPDATED: %d" (el.value |> int)
                                        updateLocalValue(el.value |> int)
                                        getEl().blur()
                                        e.preventDefault())
                                ]
                            ]
                            Bulma.levelItem [
                                Button.button None (Some "fas fa-plus") "" [
                                    prop.onClick (fun _ ->
                                        updateLocalValue(localValue + 1)
                                    )
                                ]
                            ]
                            Bulma.levelItem [
                                Html.input [
                                    prop.type' "range"
                                    prop.min input.min
                                    prop.max input.max
                                    prop.value localValue
                                    prop.onChange (fun (x:string) -> (updateLocalValue(x |> int)))
                                ]
                            ]
                        ]
                    ]
                ]
            ])

    type IntInputProps =
        { fieldLabel:string
          mValue:int option
          disabled:bool
          onChange:(string * AValue) list -> int -> HTMLElement -> unit
          attrName:string
          pk:(string * string) list }

    let intInput =
        React.functionComponent(fun (input: IntInputProps) ->
        let isFocused, setIsFocused = React.useState false
        let pkVals = input.pk |> List.map snd |> String.concat "-"
        let elId = sprintf "int-%s-%s" pkVals input.attrName
        let getEl () = document.querySelector ("#" + elId) :?> HTMLElement
        let pk' = input.pk |> List.map (fun (name, id) -> (name, AVUuid id))
        let localValue, setLocalValue = React.useState(Option.defaultValue 0 input.mValue)
        let localTimer, setLocalTimer = React.useState(None)
        let elRef = React.useElementRef ()

        let updateLocalValue x =
            match localTimer with
            | Some timer ->
                window.clearTimeout timer
            | None ->
                ()
            setLocalValue(x)
            let timerId = window.setTimeout((fun _ -> input.onChange pk' x (getEl())), 150)
            setLocalTimer(Some timerId)

//        React.useListener.onClickAway(elRef, fun _ ->
//            match input.mValue with
//            | None -> ()
//            | Some value ->
//                (getEl() :?> HTMLInputElement).value <- value.ToString ())

        Html.div [
            prop.ref elRef
            prop.className "field"
            prop.children [
                Html.label [
                    prop.className "label"
                    prop.text input.fieldLabel
                ]
                Bulma.level [
                    Bulma.levelLeft [
                        Bulma.levelItem [
                            Button.button None (Some "fas fa-minus") "" [
                                prop.onClick (fun _ ->
                                    updateLocalValue(localValue - 1)
                                )
                            ]
                        ]
                        Bulma.levelItem [
                            (*
                            Html.div [
                                prop.id elId
                                prop.className (if input.disabled then "non-editable" else "editable")
                                prop.contentEditable (not input.disabled)
                                prop.custom ("suppressContentEditableWarning", true)
                                prop.onKeyPress (key.enter, fun (e:KeyboardEvent) ->
                                    let newInt = FrontendUtils.Extensions.textContent e |> int
                                    let el = e.currentTarget :?> HTMLElement
                                    input.dispatch <| UpdateEntityAttributeValue (input.entity, pk', input.attrName, input.theType, Some (AVInt newInt), Some el, true)
                                    el.blur()
                                    e.preventDefault())
                                prop.children [
                                    match input.mValue with
                                    | Some value -> Html.text value
                                    | None -> Html.text "-"
                                ]
                            ]
                            *)
                            Html.input [
                                prop.id elId
                                prop.className "w-16 p-2"
                                prop.valueOrDefault localValue
                                prop.onChange (fun (_x:string) -> ())
                                prop.onFocus (fun _ -> setIsFocused true)
                                prop.onBlur (fun e ->
                                    if isFocused then
                                        let newText = FrontendUtils.Extensions.eventValue e
                                        updateLocalValue (newText |> int)
                                        setIsFocused false
                                    else ())
                                prop.onKeyPress (key.enter, fun (e:KeyboardEvent) ->
                                    //let newInt = FrontendUtils.Extensions.textContent e |> int
                                    let el = e.target :?> HTMLInputElement
                                    //printfn "UPDATED: %d" (el.value |> int)
                                    updateLocalValue(el.value |> int)
                                    getEl().blur()
                                    e.preventDefault())
                            ]
                        ]
                        Bulma.levelItem [
                            Button.button None (Some "fas fa-plus") "" [
                                prop.onClick (fun _ ->
                                    updateLocalValue(localValue + 1)
                                )
                            ]
                        ]
                    ]
                ]
            ]
        ])
