﻿module FrontendUtils

open Browser.Dom
open Browser.Types
open Fable.Core
open Feliz

module Extensions =

    [<Emit("setTimeout($0, $1)")>]
    let setTimeout (_f: unit -> unit) (_n:int) : int = jsNative

    [<Emit("clearTimeout($0)")>]
    let clearTimeout (_n:int) : unit = jsNative

    [<Emit("setInterval($0, $1)")>]
    let setInterval (_f: unit -> unit) (_n:int) : int = jsNative

    [<Emit("clearInterval($0)")>]
    let clearInterval (_n:int) : unit = jsNative

    [<Emit("$0.target.firstChild.nodeValue")>]
    let elementText (_el:obj) : string = jsNative

    [<Emit("$0.target.firstChild.textContent")>]
    let textContent (_el:obj) : string = jsNative

    [<Emit("$0.target.value")>]
    let eventValue (_el:obj) : string = jsNative

    [<Emit("new DOMParser().parseFromString($0, 'text/html').body.textContent || ''")>]
    let stripHtml (_html:string) : string = jsNative

let yellowFadeElementId elId =
    let el = document.getElementById elId
    el.classList.add "updated"
    Extensions.setTimeout (fun () -> el.classList.remove "updated") 2000

let yellowFadeElement (el:HTMLElement) =
    el.classList.add "updated"
    Extensions.setTimeout (fun () -> el.classList.remove "updated") 2000

let iconSpan (iconName:string) =
    Html.span [
        prop.className ["icon"; "is-small"; "is-left"]
        prop.children [
            Html.i [
                prop.className iconName
            ]
        ]
    ]

let iconSvgBase (height:int) (viewBox:int * int * int * int) (dValue:string) =
    Svg.svg [
        svg.viewBox viewBox
        svg.height height
        svg.children [
            Svg.path [
                svg.fill "currentColor"
                svg.d dValue
            ]
        ]
    ]

let iconSvgCode (height:int) =
    iconSvgBase height (0, 0, 576, 512 ) "M228.5 511.8l-25-7.1c-3.2-.9-5-4.2-4.1-7.4L340.1 4.4c.9-3.2 4.2-5 7.4-4.1l25 7.1c3.2.9 5 4.2 4.1 7.4L235.9 507.6c-.9 3.2-4.3 5.1-7.4 4.2zm-75.6-125.3l18.5-20.9c1.9-2.1 1.6-5.3-.5-7.1L49.9 256l121-102.5c2.1-1.8 2.4-5 .5-7.1l-18.5-20.9c-1.8-2.1-5-2.3-7.1-.4L1.7 252.3c-2.3 2-2.3 5.5 0 7.5L145.8 387c2.1 1.8 5.3 1.6 7.1-.5zm277.3.4l144.1-127.2c2.3-2 2.3-5.5 0-7.5L430.2 125.1c-2.1-1.8-5.2-1.6-7.1.4l-18.5 20.9c-1.9 2.1-1.6 5.3.5 7.1l121 102.5-121 102.5c-2.1 1.8-2.4 5-.5 7.1l18.5 20.9c1.8 2.1 5 2.3 7.1.4z"

let iconSvgUnlock (height:int) =
    iconSvgBase height (0, 0, 448, 512 ) "M400 256H152V152.9c0-39.6 31.7-72.5 71.3-72.9 40-.4 72.7 32.1 72.7 72v16c0 13.3 10.7 24 24 24h32c13.3 0 24-10.7 24-24v-16C376 68 307.5-.3 223.5 0 139.5.3 72 69.5 72 153.5V256H48c-26.5 0-48 21.5-48 48v160c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V304c0-26.5-21.5-48-48-48z"

let iconSvgLock (height:int) =
    iconSvgBase height (0, 0, 448, 512) "M400 224h-24v-72C376 68.2 307.8 0 224 0S72 68.2 72 152v72H48c-26.5 0-48 21.5-48 48v192c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V272c0-26.5-21.5-48-48-48zm-104 0H152v-72c0-39.7 32.3-72 72-72s72 32.3 72 72v72z"

let debugKey name (ke:KeyboardEvent) =
    printfn "%s: key=%s code=%s charCode=%f which=%f" name ke.key ke.code ke.charCode ke.which


module AutoComplete =

    let classNameSuggestionList = "tags-suggestion-list"

    let renderSuggestionList (count: int) (content:ReactElement) =
        Html.div [
             prop.className classNameSuggestionList
             prop.children [
                 Html.ul [
                     prop.className "tags"
                     prop.children [
                         Html.li [
                             prop.className "w-full mb-3 font-semibold text-right"
                             prop.children [
                                 Html.h3 (sprintf "%d Matches" count)
                             ]
                         ]
                         content
                     ]
                 ]
             ]
         ]

    let renderNoMatchSuggestionList (content:ReactElement) =
        Html.div [
            prop.className classNameSuggestionList
            prop.children [
                Html.ul [
                    prop.className "field is-grouped is-grouped-multiline"
                    prop.children [
                        Html.li [
                            content
                        ]
                    ]
                ]
            ]
        ]

    type Props =
        { //ref: IRefValue<HTMLElement option>
          placeHolder: string
          valueOrDefault: string
          onChange: string -> unit
          onKeyPress: KeyboardEvent -> unit
          onKeyDown: KeyboardEvent -> unit
          suggestionList: ReactElement
        }
    let render = React.functionComponent(fun(props:Props) ->
        Html.div [
            prop.children [
                Html.div [
                    prop.className "control has-icons-left"
                    prop.children [
                        Html.input [
                            prop.className "input is-rounded is-small"
                            prop.type' "text"
                            prop.placeholder props.placeHolder
                            prop.valueOrDefault props.valueOrDefault
                            prop.onChange props.onChange
                            prop.onKeyPress props.onKeyPress
                            prop.onKeyDown props.onKeyDown
                            prop.autoFocus true
                            //prop.onBlur (fun _ -> ChangeTaskState (taskId, Display) |> dispatch)
                        ]
                        iconSpan "fas fa-search"
                    ]
                ]
                Html.div [
                    props.suggestionList
                ]
            ]
        ])
