module Queries.Update

open MetaGQL.Types
open SharedGQL

let updateSingleByPk (idNames:string list) (entity:AEntity) (attrName, attrVarName) dataType isNull =
    let update_name = sprintf "update_%s_by_pk" (AEntity.tableName entity)
    let idNameInputs = idNames |> List.map (fun idName -> DefVarBang(idName, AUuid))
    { MutationInput = if not isNull then
                        List.append idNameInputs [ DefVarBang(attrVarName, dataType) ]
                      else
                        idNameInputs
      MutationMapping =
          [ UpdateByPKSet
                { Name = update_name
                  PK = idNames |> List.map (fun idName -> (SimpleAttr idName, LookupVar idName))
                  Entity = entity
                  Projection = ProjectionHelper.ofEntity entity
                  Assignment = if not isNull then
                                 [ (SimpleAttr attrName, LookupVar attrVarName) ]
                               else
                                 [ (SimpleAttr attrName, Constant "null") ]} ]
    }
    
module Update =
    
    type UpdateResult =
        | UpdatedProduct of Product
        | UpdatedManuscript of Manuscript
        | UpdatedProject of Project
        | UpdatedProjectSeries of ProjectSeries
        | UpdatedProjectProjectSeries of ProjectProjectSeries

module Product =
    
    type UpdateByPkResult = { update_product_by_pk: Product }
     
    let mutationBase attrName dataType = updateSingleByPk ["id"] Entities.Product attrName dataType
    let mutationSalesStart = mutationBase ("sales_start", "newSalesStart") ADate
    let mutationSalesEnd = mutationBase ("sales_end", "newSalesEnd") (AOption ADate)
    let mutationDeliveredAt = mutationBase ("delivered_at", "newDeliveredAt") (AOption ADate)
    let mutationUpdateTitle = mutationBase ("title", "newTitle") AString
    let mutationUpdateDefaultPrice = mutationBase ("default_price", "newDefaultPrice") AInt
    let mutationUpdateSubtitle = mutationBase ("subtitle", "newSubtitle") AString
    let mutationUpdateMusicalPercentage = mutationBase ("musical_percentage", "newMusicalPercentage") AInt
    let mutationReleasedAt = mutationBase ("released_at", "newReleasedAt") (AOption ADate)
    let mutationMusicalWorkRights = mutationBase ("musical_work_rights","newMusicalWorkRights") (ACustom "music_rights_info_enum")

module Manuscript =
    
    type UpdateByPkResult = { update_manuscript_by_pk: Manuscript }
    
    let mutationBase attrName dataType = updateSingleByPk ["id"] Entities.Manuscript attrName dataType
    let mutationUpdateTitle = mutationBase ("title", "newTitle") AString
    let mutationUpdateWordCount = mutationBase ("word_count", "newWordCount") AInt
    let mutationUpdateDefaultBlurb = mutationBase ("default_blurb", "newDefaultBlurb") AString
    let mutationUpdateIsExplicitContent = mutationBase ("is_explicit_content", "newIsExplicitContent") ABool
    let mutationUpdateDefaultAccountingGroupId = mutationBase ("default_accounting_group_id", "newDefaultAccountingGroupId") AUuid
    let mutationUpdateLanguageId = mutationBase ("language_id", "newLanguageId") (ACustom "language_enum")
    let mutationUpdateSubtitle = mutationBase ("subtitle", "newSubtitle") AString
    let mutationUpdateIsAbridged = mutationBase ("is_abridged","newIsAbridged") ABool
    let mutationUpdateGenrePresetId = mutationBase ("genre_preset_id", "newGenrePresetId") AUuid

module Project =
    
    type UpdateByPkResult = { update_project_by_pk: Project }
    
    let mutationBase attrName dataType = updateSingleByPk ["id"] Entities.Project attrName dataType
    let mutationUpdateTitle = mutationBase ("title", "newTitle") AString
    let mutationUpdateDescription = mutationBase ("description", "newDescription") (AOption AString)
    let mutationUpdateProjectKind = mutationBase ("project_kind", "newProjectKind") (ACustom "project_kind_enum")
    
module ProjectProductionInfo =
    
    type UpdateByPkResult = { update_project_production_info_by_pk: ProjectProductionInfo }
    
    let mutationBase attrName dataType = updateSingleByPk ["project_id"] Entities.ProjectProductionInfoWithProject attrName dataType
    let mutationUpdateStatus = mutationBase ("status", "newStatus") (ACustom "project_status_enum")
    
module ProjectCopyrightInfo =
    type UpdateByPkResult = { update_project_copyright_info_by_pk: ProjectCopyrightInfo }
    
    let mutationBase attrName dataType = updateSingleByPk ["project_id"] Entities.ProjectCopyrightInfoWithProject attrName dataType
    let mutationCLine = mutationBase ("c_line", "newCLine") AString
    let mutationPLine = mutationBase ("p_line", "newPLine") AString
    
module ProjectLabelInfo =
    type UpdateByPkResult = { update_project_label_info_by_pk: ProjectLabelInfo }
    
    let mutationBase attrName dataType = updateSingleByPk ["project_id"] Entities.ProjectLabelInfoWithProject attrName dataType
    let mutationUpdateLabel = mutationBase ("label_id", "newLabelId") AUuid
    
module ProjectAccountingInfo =
    
    type UpdateByPkResult = { update_project_accounting_info_by_pk: ProjectAccountingInfo }
    
    let mutationBase attrName dataType = updateSingleByPk ["project_id"] Entities.ProjectAccountingInfoToProject attrName dataType
    let mutationUpdateAccountingGroup = mutationBase ("accounting_group_id","newAccountingGroupId") AUuid
    
module ProjectManuscriptInfo =
    
    type UpdateByPkResult = { update_project_manuscript_info_by_pk: ProjectManuscriptInfo }
    
    let AllProjectManuscriptInfo =
        EntityHelper.add Entities.ProjectManuscriptInfo
            [("manuscript", AForeign (Entities.Manuscript, None, IsOne))
             ("project", AForeign (Entities.Project, None, IsOne))]
    
    let mutationBase attrName dataType = updateSingleByPk ["project_id"] AllProjectManuscriptInfo attrName dataType
    let mutationUpdateManuscript = mutationBase ("manuscript_id", "newManuscriptId") AUuid
    
module ProjectProductionInfoReader =
    
    type UpdateByPkResult = { update_project_production_info_reader_by_pk: ProjectProductionInfoReader }
    
    let AllProjectProductionInfoReader =
        EntityHelper.add Entities.ProjectProductionInfoReader
            [("project", AForeign (Entities.Project, None, IsOne))]
    
    let mutationBase attrName dataType = updateSingleByPk ["project_id"; "user_id"] AllProjectProductionInfoReader attrName dataType
    let mutationUpdateReaderName = mutationBase ("reader_name", "newReaderName") AString
    let mutationUpdateTotalDuration = mutationBase ("total_duration", "newTotalDuration") AString

module BaseUserInfo =
    
    type UpdateByPkResult = { update_user_info_by_pk: UserInfo }
    
    let mutationBase attrName dataType = updateSingleByPk ["user_id"] Entities.BaseUserInfo attrName dataType
    let mutationUpdateName = mutationBase ("name","newName") AString
    
module BaseUserReaderInfo =
    
    type UpdateByPkResult = { update_user_reader_info_by_pk: UserReaderInfo }
    
    let mutationBase attrName dataType = updateSingleByPk ["user_id"] Entities.BaseUserReaderInfo attrName dataType
    let mutationUpdatePseudonym = mutationBase ("pseudonym","newPseudonym") (AOption AString)
    
module Author =
    
    type UpdateByPkResult = { update_author_by_pk: Author }
    
    let mutationBase attrName dataType = updateSingleByPk ["id"] Entities.Author attrName dataType
    let mutationUpdateName = mutationBase ("name","newName") AString
    
module ArtistProfile =
    
    type UpdateByPkResult = { update_artist_profile_by_pk: ArtistProfile }
    
    let mutationBase attrName dataType = updateSingleByPk ["id"] Entities.ArtistProfile attrName dataType
    let mutationUpdateName = mutationBase ("name","newName") AString
    
module ProductArtistProfileInfo =
    
    type UpdateByPkResult = { update_product_artist_profile_info_by_pk: ProductArtistProfileInfo }
    
    let mutationBase attrName dataType = updateSingleByPk ["product_id";"artist_profile_id"] Entities.ProductArtistProfileInfoToProduct attrName dataType
    
module ProjectProjectSeries =
    
    type UpdateByPkResult = { update_project_project_series_by_pk: ProjectProjectSeries }
    
    let AllProjectProjectSeries =
        EntityHelper.add Entities.ProjectProjectSeries
            [("project_series", AForeign (Entities.ProjectSeries, None, IsOne))]
    
    let mutationBase attrName dataType = updateSingleByPk ["project_id";"project_series_id"] AllProjectProjectSeries attrName dataType
    let mutationUpdateProjectSeriesId = mutationBase ("project_series_id","newProjectSeriesId") AUuid

module ProjectSeries =
    
    type UpdateByPkResult = { update_project_series_by_pk: ProjectSeries }
    
    let mutationBase attrName dataType = updateSingleByPk ["id"] Entities.ProjectSeries attrName dataType
