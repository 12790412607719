module Components.Button

open Feliz

let button (mLabel:string option) (mIconStr:string option) extraClass props =
    let withIcon xs =
        match mIconStr with
        | Some icon -> FrontendUtils.iconSpan icon :: xs
        | None -> xs
    let withLabel xs =
        match mLabel with
        | Some label -> Html.span label :: xs
        | None -> xs
    Html.button
        (List.append [ prop.className (sprintf "button is-rounded is-small %s" extraClass)
                       prop.children (withIcon <| withLabel [])
                     ] props)
