module Common

open Fable.Core

module LocalStorage =

    [<Emit("window.localStorage.setItem($0, $1)")>]
    let set (x:string) (y:string) = jsNative

    [<Emit("window.localStorage.getItem($0)")>]
    let get (x:string) : string = jsNative

    [<Emit("window.localStorage.getItem($0) == null")>]
    let isEmpty (x:string) : bool = jsNative

    [<Emit("window.localStorage.removeItem($0)")>]
    let remove (x:string) = jsNative

    [<Emit("window.localStorage.clear()")>]
    let clear () = jsNative

module LocalSession =

    //
    // creates and assigns a unique local session key that
    // can be used to identify this user's session. This
    // is useful allowing the user to on-board to the web application
    // without signing up. This encourages first-try of the web app
    // before coming to the point where creating an account makes
    // sense in order not to lose the data that the user entered.
    //
    // Having a local session key means we can temporarily allow
    // the user to be identified through that key. On sign-up
    // the user data can be associated to the new user.
    //
    // A regular clean up interval of temporary data may be required.
    //

    let private sessionKey = "local-session"

    let init () =
        if (LocalStorage.isEmpty sessionKey) then
            let newId = (System.Guid.NewGuid ()).ToString ()
            LocalStorage.set sessionKey newId
            newId
        else
            LocalStorage.get sessionKey
