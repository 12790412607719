module Components.MultiAutoComplete.Context

open Components.MultiAutoComplete.Types
open Feliz
open Shared

let createContext<'T,'A> =
    let ctx:Fable.React.IContext<AutoCompleteContext'<'T,'A>> = React.createContext(name="Multi AutoComplete Context")
    ctx
    
let NarratorContext = createContext<RelatedNarratorInfo,Queries.Add.ProjectProductionInfoReader.NewProjectProductionInfoReader>
