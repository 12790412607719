module Components.DateInput

open System

module DateInput =

    open Feliz
    open Feliz.Bulma
    open MetaGQL.Types
    open Browser.Types
    open Browser.Dom
    open Fable.DateFunctions

    let dateInput (fieldLabel:string) (entity:AEntity)
                  (id:string) (attrName:string)
                  (value:DateTimeOffset option)
                  (onUpdate:AType -> DateTimeOffset -> HTMLElement -> unit)
                  (onReset:HTMLElement -> unit)
                  isOptional =
        let _withDefaultValue xs =
            match value with
            | Some x ->
                printfn "/// %s HAS VALUE" fieldLabel
                prop.value (x.Format "YYYY-MM-DD") :: xs
            | None ->
                printfn "/// %s HAS NO VALUE" fieldLabel
                xs
        let elId = sprintf "data-%s-%s-%s" (AEntity.tableName entity) attrName id
        let getEl () = document.querySelector (sprintf "#%s" elId) :?> HTMLElement
        Html.div [
                prop.className "field"
                prop.children [
                    Html.label [
                        prop.className "label"
                        prop.text fieldLabel
                    ]
                    Html.div [
                        prop.className "control"
                        prop.children [
                            Bulma.level [
                                Bulma.levelLeft [
                                    Bulma.levelItem [
                                        (*
                                        Flatpickr.flatpickr (withDefaultValue [
                                            Flatpickr.Id elId
                                            Flatpickr.OnChange (fun value ->
                                                printfn "CHOSE: %s" (value.ToString ())
                                                let el = getEl ()
                                                let asDTOffset : System.DateTimeOffset = new System.DateTimeOffset(value)
                                                let actualType = if isOptional then AOption ADate else ADate
                                                dispatch <| UpdateEntityAttributeValue (entity, attrName, AVUuid id, actualType, Some (AVDate asDTOffset), el)
                                                )
                                            Flatpickr.ClassName "input"
                                        ])
                                        *)
                                        ReactDateTime.dateTime [
                                            ReactDateTime.Utc true
                                            ReactDateTime.TimeFormat false
                                            ReactDateTime.CloseOnSelect true
                                            ReactDateTime.OnChange (fun moment ->
                                                printfn "%A" (moment.toDate ())
                                                let dtOffset = moment.toDate ()
                                                let actualType = if isOptional then AOption ADate else ADate
                                                let el = getEl ()
                                                //dispatch <| UpdateEntityAttributeValue (entity, [(idName, AVUuid id)], attrName, actualType, Some (AVDate dtOffset), Some el, true)
                                                onUpdate actualType dtOffset el
                                            )
                                            ReactDateTime.RenderInput (fun props openCalendar _closeCalendar ->
                                                printfn "%A" props
                                                Html.div [
                                                    prop.id elId
                                                    prop.style [
                                                        style.minWidth 300
                                                    ]
                                                    prop.children [
                                                        (*
                                                        Html.input (withDefaultValue [
                                                            prop.type' "date"
                                                            prop.onChange (fun (e:Event) ->
                                                                let el = e.target
                                                                ())
                                                        ])
                                                        *)
                                                        Bulma.level [
                                                            Bulma.levelLeft [
                                                                Bulma.levelItem [
                                                                    Button.button (Some "Change") (Some "far fa-calendar-alt") "" [ prop.onClick openCalendar ]
                                                                ]
                                                                Bulma.levelItem [
                                                                    match value with
                                                                    | Some x ->
                                                                        Html.span (x.Format "dd MMMM yyyy")
                                                                    | None ->
                                                                        Html.none
                                                                ]
                                                                Bulma.levelItem [
                                                                    if isOptional && Option.isSome value then
                                                                        Button.button (Some "Reset") (Some "far fa-calendar-times") ""
                                                                          [ prop.onClick (fun _ ->
                                                                             //FrontendUtils.Extensions.setTimeout (fun () -> clearFlatPickr el) 300 |> ignore
                                                                             let el = getEl ()
                                                                             //dispatch <| UpdateEntityAttributeValue (entity, [(idName, AVUuid id)], attrName, AOption ADate, None, Some el, true)) ]
                                                                             onReset el )]
                                                                    else
                                                                        Html.none
                                                                ]
                                                            ]
                                                        ]
                                                    ]
                                                ]
                                            )
                                        ] []
                                    ]
                                ]
                            ]
                        ]
                    ]
                ]
            ]
