module Components.Choice

module Choice =

    open Feliz
    open Browser.Types
    open Browser.Dom

    let choices (fieldLabel:string) (attrName:string) (pk:string)
                (choices: string list) (value:string)
                (onUpdate: string -> HTMLElement -> unit)
                disabled =
        let elId = sprintf "choices_%s_%s" pk attrName
        Html.div [
            prop.className "field"
            prop.children [
                Html.label [
                    prop.className "label"
                    prop.text fieldLabel
                ]
                Html.div [
                    prop.id elId
                    prop.className "control"
                    prop.children [

                        React.fragment (List.mapi (fun _idx (choice:string) ->
                                let isChecked = choice = value
                                Html.label [
                                    prop.className "radio"
                                    prop.text choice
                                    prop.children [
                                        Html.input [
                                            prop.disabled disabled
                                            prop.type' "radio"
                                            prop.name attrName
                                            prop.defaultChecked isChecked
                                            prop.onChange (fun (_e:Event) ->
                                                let el = document.querySelector ("#"+elId) :?> HTMLElement
                                                //dispatch <| UpdateEntityAttributeValue (entity, [(idName, AVUuid pk)], attrName, AString, Some (AVString choice), Some el, true)
                                                onUpdate choice el
                                            )
                                        ]
                                        Html.span [
                                            prop.style [
                                                style.marginLeft 5
                                            ]
                                            prop.text choice
                                        ]
                                    ]
                                ])
                            choices)
                    ]
                ]
            ]
        ]
