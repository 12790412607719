module Pages.Edit

open System
open Browser
open Components
open Queries.Add
open Queries.Add.ProjectProductionInfoReader
open Shared
open Fable.Core
open Client.BaseTypes
open Elmish
open Feliz
open Feliz.UseElmish

[<Emit("$0 === undefined")>]
let isUndefined (_x:'a): bool = jsNative

module Edit =

    open Feliz.UseListener
    open Client.Types
    open MetaGQL.Types
    open SharedGQL
    open Queries.Autocomplete.AutoComplete
    open Components.Text
    open Components.EditableInput
    open Components.EditableTextArea
    open Components.IntInput
    open Components.DateInput
    open Components.DefaultInput
    open Components.YesNo
    open Components.SingleAutoComplete
    open Components.MultiAutoComplete
    open Browser.Types

    type Msg =
        | DoNothing
        | InitProduct of string
        | InitProject of string
        | LoadProductTrackInfo of AsyncOperationStatus<Result<Queries.ProductTrackInfo.ProductTrackInfo.GetResult, string>>

        | LoadSingleProduct of AsyncOperationStatus<Result<Product, string>>
        | LoadSingleProject of AsyncOperationStatus<Result<Project, string>>

        // Create Product
        | CreateNewProduct of string * string
        | DuplicateProduct of Product
        | DuplicateSpotifyProduct of Product

        // Export single products
        | ExportONIX of Product
        | ExportXLSX of Product

        // Create Project Series
        | CreateProjectSeries of Product
        | AssignProjectSeries of (string * string * string)
        | UnAssignProjectSeries of (string * string * string)

        // Update
        | UpdateEntityAttributeValue of (AEntity * (string * AValue) list * string * AType * AValue option * HTMLElement option * bool)
        | UpdateEntitiesInEdit of EntitiesInEdit
        | LoadUpdatedEntity of AsyncOperationStatus<Result<Queries.Update.Update.UpdateResult list, string>>

        // refresh cline and pline
        | RefreshProjectCLine of string
        | RefreshProjectPLine of string
        | RefreshProjectCAndPLine of string

        // Overwrite EAN/Article number sets
        | ToggleOverwriteEAN of string
        | ToggleOverwriteArticleNumber of string

    type EditPageProps =
        { State: AppState
          Dispatch: AppMsg -> unit
          ProductId: string option
          HRefUrl: Url -> string
          FocusOnTitle: bool
          InitMsg : Msg
        }

    type State =
        {
            ProductId : string option
            ProductTrackInfo : Deferred<Result<Queries.ProductTrackInfo.ProductTrackInfo.GetResult, string>>
            EditProduct: Deferred<Result<Product, string>>
            EntitiesInEdit : EntitiesInEdit
            ProductInEdit : Product option
            // EAN/Bookstream Article overwrite attributes
            OverwriteEANSet : string Set
            OverwriteArticleNumberSet : string Set
        }


    let private emptyEntitiesInEdit =
        {
            Manuscript = None
            Product = None
            Project = None
            ProjectSeries = None
            ProjectProjectSeries = None
            Ware = None
        }


    let private init (props:EditPageProps) =
        { ProductTrackInfo = HasNotStartedYet
          ProductId = props.ProductId
          EditProduct = HasNotStartedYet
          EntitiesInEdit = emptyEntitiesInEdit
          ProductInEdit = None
          OverwriteEANSet = Set.empty
          OverwriteArticleNumberSet = Set.empty
        }, Cmd.ofMsg props.InitMsg

    let private call dispatch callF responseF = Utilities.Remoting.withException dispatch callF responseF DoNothing
    let private err okF result = Utilities.Remoting.err DoNothing okF result

    let private processEditProduct appDispatch token state productId =
        let editProduct _dispatch =
            call appDispatch (Server.api.GetOneProductComposite token productId)
                (err (fun res ->
                    LoadSingleProduct (Finished (Ok res))))
        let loadTrackInfo _dispatch =
            match state.ProductId with
            | Some productId ->
                call appDispatch (Server.api.GetProductTrackInfo token productId)
                    (err (fun res ->
                           LoadProductTrackInfo (Finished (Ok res))))
            | None -> async { return DoNothing }
        let nextCmds = Cmd.batch [
            Cmd.fromAsync loadTrackInfo
            Cmd.fromAsync editProduct
        ]
        { state with EditProduct = InProgress }, nextCmds

    let private processEditProject appDispatch token state projectId =
        let editProduct _dispatch =
            call appDispatch (Server.api.GetProject token projectId)
                (err (fun res ->
                    LoadSingleProject (Finished (Ok res))))
        { state with EditProduct = InProgress }, Cmd.fromAsync editProduct

    let private processCreateNewProduct appDispatch token state wareId labelId =
        let op _dispatch =
            call appDispatch (Server.api.CreateNewProduct token wareId labelId)
                (err (fun res ->
                    appDispatch <| Navigate (Url.EditProduct res.id) // TODO: test that this is really working
                    DoNothing))
        { state with EditProduct = InProgress }, Cmd.fromAsync op

    let private processDuplicateProduct appDispatch token state product =
        let op _dispatch =
            call appDispatch (Server.api.DuplicateProduct token product)
                (err (fun res ->
                    appDispatch <| Navigate (Url.EditProduct res.id) // TODO: test that this is really working
                    DoNothing))
        { state with EditProduct = InProgress }, Cmd.fromAsync op

    let private processDuplicateSpotifyProduct appDispatch token state product =
        let op _dispatch =
            call appDispatch (Server.api.DuplicateSpotifyProduct token product)
                (err (fun res ->
                    appDispatch <| Navigate (Url.EditProduct res.id) // TODO: test that this is really working
                    DoNothing))
        { state with EditProduct = InProgress }, Cmd.fromAsync op

    let private processExportONIX appDispatch token state (product: Product) =
        let op _dispatch =
            call appDispatch (Server.api.ExportONIX token product)
                (err (fun res ->
                    printf "%A" res
                    DoNothing))
        state, Cmd.fromAsync op

    let private processExportXLSX appDispatch token state (product: Product) =
        let op _dispatch =
            call appDispatch (Server.api.ExportXLSX token product)
                (err (fun res ->
                    printf "[processExportXLSX res] %A" res
                    //return SetONIX (sprintf "%A" res)
                    let anchor = document.createElement "a"
                    anchor.setAttribute("href", sprintf "/%s" res.Filename)
                    anchor.setAttribute("download", res.Filename)
                    anchor.click()
                    LoadUpdatedEntity (Finished (Ok res.UpdatedEntities))))
        state, Cmd.fromAsync op

    let private processCreateProjectSeries appDispatch token state product =
        let op _dispatch =
            call appDispatch (Server.api.CreateProjectSeries token product)
                (err (fun res -> InitProduct res.id))
        { state with EditProduct = InProgress }, Cmd.fromAsync op

    let private processAssignProjectSeries appDispatch token state productId projectId projectSeriesId =
        let op _dispatch =
            call appDispatch (Server.api.AssignProjectSeries token productId projectId projectSeriesId)
                (err (fun res -> InitProduct res.id))
        { state with EditProduct = InProgress }, Cmd.fromAsync op

    let private processUnAssignProjectSeries appDispatch token state productId projectId projectSeriesId =
        let op _dispatch =
            call appDispatch (Server.api.UnAssignProjectSeries token productId projectId projectSeriesId)
                (err (fun res -> InitProduct res.id))
        { state with EditProduct = InProgress }, Cmd.fromAsync op

    let private processShowProduct appDispatch token state productId =
        let editProduct _dispatch =
            call appDispatch (Server.api.GetOneProductComposite token productId)
                (err (fun res ->
                    LoadSingleProduct (Finished (Ok res))))
        { state with EditProduct = InProgress }, Cmd.fromAsync editProduct

    let private processRefreshProjectCLine appDispatch token state (project_id:string) =
        let op _dispatch =
            call appDispatch (Server.api.RefreshProjectCLine token project_id)
                (err (fun res ->
                    LoadUpdatedEntity (Finished (Ok res))))
        state, Cmd.fromAsync op

    let processRefreshProjectPLine appDispatch token state (project_id:string) =
        let op _dispatch =
            call appDispatch (Server.api.RefreshProjectPLine token project_id)
                (err (fun res ->
                    LoadUpdatedEntity (Finished (Ok res))))
        state, Cmd.fromAsync op

    let processRefreshProjectCAndPLine appDispatch token state (project_id:string) =
        let op _dispatch =
            call appDispatch (Server.api.RefreshProjectCAndPLine token project_id)
                (err (fun res ->
                    LoadUpdatedEntity (Finished (Ok res))))
        state, Cmd.fromAsync op

    let private update (appCtx : AppContext) msg (state:State) =
        let token = { Indygemma.Auth.Types.Token = appCtx.Jwt }
        match msg with
        | DoNothing ->
            state, Cmd.none
        | LoadProductTrackInfo Started ->
            { state with ProductTrackInfo = HasNotStartedYet }, Cmd.none
        | LoadProductTrackInfo (Finished result) ->
            { state with ProductTrackInfo = Resolved result }, Cmd.none
        | InitProduct productId ->
            processEditProduct appCtx.AppDispatch token state productId
        | InitProject projectId ->
            processEditProject appCtx.AppDispatch token state projectId
        | CreateNewProduct (wareId, labelId) ->
            processCreateNewProduct appCtx.AppDispatch token state wareId labelId
        | DuplicateProduct product ->
            processDuplicateProduct appCtx.AppDispatch token state product
        | DuplicateSpotifyProduct product ->
            processDuplicateSpotifyProduct appCtx.AppDispatch token state product
        | ExportONIX product ->
            processExportONIX appCtx.AppDispatch token state product
        | ExportXLSX product ->
            processExportXLSX appCtx.AppDispatch token state product
        | CreateProjectSeries product ->
            processCreateProjectSeries appCtx.AppDispatch token state product
        | AssignProjectSeries (productId, projectId, projectSeriesId) ->
            processAssignProjectSeries appCtx.AppDispatch token state productId projectId projectSeriesId
        | UnAssignProjectSeries (productId, projectId, projectSeriesId) ->
            processUnAssignProjectSeries appCtx.AppDispatch token state productId projectId projectSeriesId
        | RefreshProjectCLine project_id ->
            processRefreshProjectCLine appCtx.AppDispatch token state project_id
        | RefreshProjectPLine project_id ->
            processRefreshProjectPLine appCtx.AppDispatch token state project_id
        | RefreshProjectCAndPLine project_id ->
            processRefreshProjectCAndPLine appCtx.AppDispatch token state project_id
        | LoadSingleProduct Started ->
            state, Cmd.none
        | LoadSingleProduct (Finished product) ->
            // split into the EntitiesInEdit components
            let entitiesInEdit =
                match product with
                | Ok x ->
                    let mWare = x.ware
                    let mProject = match mWare with
                                   | Some ware -> ware.project
                                   | None -> None
                    let mManuscript = match mProject with
                                      | Some project -> match project.manuscript_info with
                                                        | Some mi -> mi.manuscript
                                                        | None -> None
                                      | None -> None
                    let mProjectSeriesInfo = match mProject with
                                             | Some project -> project.project_series_info
                                             | None -> None
                    let mProjectSeries = match mProjectSeriesInfo with
                                         | Some psi -> psi.project_series
                                         | None -> None
                    { state.EntitiesInEdit with
                        Product = Some x
                        Manuscript = mManuscript
                        Project = mProject
                        ProjectSeries = mProjectSeries
                        ProjectProjectSeries = mProjectSeriesInfo
                    }
                | Error _err -> state.EntitiesInEdit
            let ProductInEdit =
                match product with
                | Ok x -> Some x
                | Error _ -> None
            { state with EditProduct = Resolved product
                         EntitiesInEdit = entitiesInEdit
                         ProductInEdit = ProductInEdit }, Cmd.none
        | LoadSingleProject Started ->
            state, Cmd.none
        | LoadSingleProject (Finished mProject) ->
            // split into the EntitiesInEdit components
            let entitiesInEdit =
                match mProject with
                | Ok project ->
                    let mProject = Some project
                    let mManuscript = match project.manuscript_info with
                                      | Some mi -> mi.manuscript
                                      | None -> None
                    let mProjectSeriesInfo = project.project_series_info
                    let mProjectSeries = match mProjectSeriesInfo with
                                         | Some psi -> psi.project_series
                                         | None -> None
                    { state.EntitiesInEdit with
                        Product = None
                        Manuscript = mManuscript
                        Project = mProject
                        ProjectSeries = mProjectSeries
                        ProjectProjectSeries = mProjectSeriesInfo
                    }
                | Error _err -> state.EntitiesInEdit
            let ProductInEdit = None
            { state with EditProduct = HasNotStartedYet
                         EntitiesInEdit = entitiesInEdit
                         ProductInEdit = ProductInEdit }, Cmd.none
        | UpdateEntitiesInEdit eid ->
            { state with EntitiesInEdit = eid }, Cmd.none
        | UpdateEntityAttributeValue (entity, idNamePkPairs, attrName, theType, attrValue, mEl, reload) ->
            printf "(UPDATE) entity:%s attrName:%s pk:%A type:%A value:%A"
                   (AEntity.tableName entity) attrName idNamePkPairs theType attrValue
            let idNamePkPairsString = List.map (fun (idName, pk) -> (idName, AValue.toString pk)) idNamePkPairs
            let op _dispatch =
                call appCtx.AppDispatch (Server.api.UpdateEntityAttribute token entity idNamePkPairsString attrName theType attrValue)
                    (err (fun res ->
                        printfn "-- UPDATE RESULT %A" res
                        match mEl with
                        | Some el ->
                            FrontendUtils.yellowFadeElement el |> ignore
                        | None -> ()
                        if reload then
                            LoadUpdatedEntity (Finished (Ok res))
                        else
                            DoNothing))
            let nextCmds = [Cmd.fromAsync op]
            state, Cmd.batch nextCmds
        | LoadUpdatedEntity Started ->
            { state with EntitiesInEdit = emptyEntitiesInEdit }, Cmd.none
        | LoadUpdatedEntity (Finished res) ->
            let nextState =
                match res with
                | Ok xs ->
                    let folder s x =
                      match x with
                      | Queries.Update.Update.UpdatedProduct x ->
                          //Cmd.ofMsg (UpdateEntitiesInEdit { state.EntitiesInEdit with Product = Some x })
                          { s with EntitiesInEdit = { s.EntitiesInEdit with Product = Some x }
                                   ProductInEdit = Some x }
                      | Queries.Update.Update.UpdatedManuscript x ->
                          //Cmd.ofMsg (UpdateEntitiesInEdit { state.EntitiesInEdit with Manuscript = Some x })
                          { s with EntitiesInEdit = { s.EntitiesInEdit with Manuscript = Some x } }
                      | Queries.Update.Update.UpdatedProject x ->
                          //Cmd.ofMsg (UpdateEntitiesInEdit { state.EntitiesInEdit with Project = Some x })
                          { s with EntitiesInEdit = { s.EntitiesInEdit with Project = Some x } }
                      | Queries.Update.Update.UpdatedProjectSeries x ->
                          //Cmd.ofMsg (UpdateEntitiesInEdit { state.EntitiesInEdit with ProjectSeries = Some x })
                          { s with EntitiesInEdit = { s.EntitiesInEdit with ProjectSeries = Some x } }
                      | Queries.Update.Update.UpdatedProjectProjectSeries x ->
                          //Cmd.ofMsg (UpdateEntitiesInEdit { state.EntitiesInEdit with ProjectSeries = Some x })
                          { s with EntitiesInEdit = { s.EntitiesInEdit with ProjectProjectSeries = Some x } }
                    List.fold folder state xs
                | Error _errs ->
                    // TODO: Show this error somehow
                    //Cmd.none
                    state
            nextState, Cmd.none
        | ToggleOverwriteEAN productId ->
            let changed =
                if Set.contains productId state.OverwriteEANSet then
                    Set.remove productId state.OverwriteEANSet
                else
                    Set.add productId state.OverwriteEANSet
            { state with OverwriteEANSet = changed }, Cmd.none
        | ToggleOverwriteArticleNumber productId ->
            let changed =
                if Set.contains productId state.OverwriteArticleNumberSet then
                    Set.remove productId state.OverwriteArticleNumberSet
                else
                    Set.add productId state.OverwriteArticleNumberSet
            { state with OverwriteArticleNumberSet = changed }, Cmd.none

    let productComponent (title:string) (buttonEls:ReactElement) (idName:string) children =
        Html.div [
            prop.id idName
            prop.className "block flex flex-col border rounded-lg p-5 divide-y divide-dotted"
            prop.children [
                Html.h3 [
                    prop.className "title is-4 has-text-centered is-uppercase has-text-weight-bold is-family-sans-serif bg-gray-100 p-2 rounded-lg"
                    prop.children [
                        Html.text title
                    ]
                ]
                Html.div [
                    prop.className "p-2 mb-2"
                    prop.children [
                        buttonEls
                    ]
                ]
                children
            ]
        ]

    let private defaultUpdateEntity dispatch elIdHighlight (props: SingleAutoCompleteProps) (item: SimpleAutoCompleteItem) _isAssigned =
        let el = match elIdHighlight with
                 | Some elId -> document.querySelector elId :?> HTMLElement |> Some
                 | None -> None
        dispatch <| UpdateEntityAttributeValue
                          (props.SubjectEntity,
                           List.map (fun (name,id) -> (name, AVUuid id)) props.Pk,
                           props.AttrName,
                           props.TheType,
                           Some (AVString item.id),
                           el,
                           true)

    let renderEditProduct state (dispatch: Msg -> unit) (productTrackInfo:Queries.ProductTrackInfo.ProductTrackInfo.GetResult option) =
        let appCtx = React.useContext(Context.AppContext)
        let token = { Indygemma.Auth.Types.Token = appCtx.Jwt }
        //let mWare = state.EntitiesInEdit.Ware
        let mProject = state.EntitiesInEdit.Project
        let mManuscript = state.EntitiesInEdit.Manuscript
        let mProjectSeries = state.EntitiesInEdit.ProjectSeries
        let mProjectSeriesInfo = state.EntitiesInEdit.ProjectProjectSeries
        let mCopyrightInfo = match mProject with
                              | Some project -> project.copyright_info
                              | None -> None
        let mLabelInfo = match mProject with
                         | Some project -> project.label_info
                         | None -> None
        let mAccountingInfo = match mProject with
                              | Some project -> project.accounting_info
                              | None -> None
        let mProductionInfo = match mProject with
                              | Some project -> project.production_info
                              | None -> None
        let mProjectStatus = match mProductionInfo with
                             | Some pi -> pi.project_status
                             | None -> None
        let languageId = match mManuscript with
                         | Some manuscript -> [(manuscript.language_id, manuscript.language_id)]
                         | None -> []
        let productCount = match mProject with
                           | None -> 0
                           | Some project -> match project.wares with
                                             | [] -> 0
                                             | wares -> seq {
                                                            for ware in wares do
                                                                for _product in ware.products do
                                                                    yield 1
                                                        } |> Seq.sum
        Html.div [
            prop.className "columns"
            prop.children [
                Html.div [
                    prop.className "column"
                    prop.children [
                        let _buttons =
                            Html.div [
                                prop.className "flex flex-row justify-between"
                                prop.children [
                                    Button.button (Some "Create New Manuscript") (Some "fas fa-plus-circle") "is-success is-light"
                                        [ prop.onClick (fun e ->
                                                         appCtx.AppDispatch CreateNewManuscript
                                                         e.preventDefault ())
                                        ]
                                ]
                            ]
                        productComponent "Manuscript" Html.none "manuscript-component" (
                                match mManuscript with
                                | Some manuscript ->
                                    React.fragment [
                                        SingleAutoComplete.autoCompleteInput
                                            { FieldLabel = "Language"
                                              Pk = [("id", manuscript.id)]
                                              SubjectEntity = Entities.Manuscript
                                              ObjectEntity = Entities.Language
                                              AttrName = "language_id"
                                              TheType = (ACustom "language_enum")
                                              CurrentSelection = languageId
                                              AddLabel = "Assign Language"
                                              Label = "Enter Language"
                                              Constructor = RELanguage
                                              OnSelect = defaultUpdateEntity dispatch None
                                            }
                                        EditableInput.editableInput { id = "manuscript_title"
                                                                      fieldLabel = "Title"
                                                                      extraElements = None
                                                                      value = manuscript.title
                                                                      disabled = false
                                                                      onChange = fun curText el ->
                                                                          dispatch <| UpdateEntityAttributeValue (Entities.Manuscript, [("id", AVUuid manuscript.id)], "title", AString, Some (AVString curText), Some el, true)
                                                                    }
                                        EditableInput.editableInput { id = "manuscript_subtitle"
                                                                      fieldLabel = "Subtitle"
                                                                      extraElements = None
                                                                      value = manuscript.subtitle
                                                                      disabled = false
                                                                      onChange = fun curText el ->
                                                                          printfn "-- CALLING manuscript_subtitle ONCHANGE"
                                                                          dispatch <| UpdateEntityAttributeValue (Entities.Manuscript, [("id", AVUuid manuscript.id)], "subtitle", AString, Some (AVString curText), Some el, true)
                                                                    }

                                        MultiAutoComplete.autoCompleteInput<AuthorManuscript, AuthorManuscript.NewAuthor>
                                            { FieldLabel = "Authors"
                                              Id = manuscript.id
                                              SubjectEntity = Entities.Manuscript
                                              ObjectEntity = Entities.AuthorManuscript
                                              IdName = "manuscript_id"
                                              AttrName = "author_id"
                                              TheType = AUuid
                                              CurrentSelection = [for author in manuscript.authors ->
                                                                     match author.author with
                                                                     | Some author' ->
                                                                         (author.author_id, author'.name, author)
                                                                     | None ->
                                                                         (author.author_id, "", author)]
                                                                  |> List.sortBy (fun (_,_,am) -> am.sort_order)
                                              AddLabel = "Assign existing Author"
                                              Label = "Enter Author"
                                              Constructor = fun authorId -> REAuthor (manuscript.id, authorId)
                                              ElIdHighlight = None
                                              RelatedRenderer = fun x ->
                                                  React.fragment [
                                                      Text.wrapText("Name",
                                                                    match x.author with
                                                                    | Some author -> Html.p author.name
                                                                    | None -> Html.p "")
                                                  ]
                                              EditRelatedBody = fun x update _doClose ->
                                                  let author_name =
                                                      match x.author with
                                                      | Some author -> author.name
                                                      | None -> ""
                                                  Html.div [
                                                      prop.className "flex flex-col w-96"
                                                      prop.children [
                                                          EditableInput.editableInput { id = "manuscript_author_name"
                                                                                        fieldLabel = "Author Name"
                                                                                        extraElements = None
                                                                                        value = author_name
                                                                                        disabled = false
                                                                                        onChange = fun curText el ->
                                                                                            dispatch <| UpdateEntityAttributeValue (Entities.Author,
                                                                                                                                    [("id", AVUuid x.author_id)],
                                                                                                                                    "name",
                                                                                                                                    AString, Some (AVString curText), Some el, false)
                                                                                            update { x with author = Some { id = x.author_id
                                                                                                                            name = curText } }
                                                                                      }
                                                      ]
                                                  ]
                                              Context = Context.createContext<AuthorManuscript, AuthorManuscript.NewAuthor>
                                              ApiEndpoint = Server.api.AuthorSearchRelatedEntities token
                                              OnOrderChanged = (fun xs ->
                                                  let changes =
                                                      seq {
                                                          for idx, _old, new' in xs do
                                                              // printfn "[%d]: %s(%d) vs %s(%d)" idx old.reader_name old.sort_order
                                                              //                                      new'.reader_name new'.sort_order
                                                              yield (new'.author_id, idx+1)
                                                      }
                                                      |> List.ofSeq
                                                  //printfn "%A" changes
                                                  appCtx.AppDispatch <| UpdateSortOrder (Entities.AuthorManuscript,
                                                                                         "manuscript_id", "author_id", manuscript.id, changes))
                                              OnAdd = fun relId -> printfn "ADDING HANDLER %s" relId
                                              OnUpdate = fun updated -> dispatch <| LoadUpdatedEntity (Finished (Ok updated))
                                              AddNewLabel = "Create new Author"
                                              AddNew = { manuscript_id = manuscript.id
                                                         name = ""
                                                         errors = None
                                                       }
                                              AddApiEndpoint = Server.api.AddAuthorRelatedEntity token
                                              AddValidator = fun x ->
                                                  let newX = AuthorManuscript.clientValidate x
                                                  match not (isUndefined newX.errors) || not (AuthorManuscript.emptyErrors newX.errors) with
                                                  | true -> false, newX
                                                  | false -> true, newX
                                              AddRelatedBody = fun x update _doClose ->
                                                  // TODO: create Add form specific components from these
                                                  let formInput (label:string) (attr:string) (onChange:string -> unit) errs (errMsg:AuthorManuscript.NewAuthorError -> string) =
                                                      Html.div [
                                                        prop.className "field"
                                                        (match errs with
                                                        | Some err ->
                                                            if (errMsg err).Length > 0 then
                                                                prop.children [
                                                                    Html.label [
                                                                        prop.className "text-sm leading-8 font-bold text-red-500"
                                                                        prop.text (label + "* (" + errMsg err + ")")
                                                                    ]
                                                                    Html.input [
                                                                        prop.className "border border-solid border-red-500 p-2"
                                                                        prop.valueOrDefault attr
                                                                        prop.onChange onChange
                                                                    ]
                                                                ]
                                                            else
                                                                prop.children [
                                                                    Html.label [
                                                                        prop.className "label"
                                                                        prop.text label
                                                                    ]
                                                                    Html.input [
                                                                        prop.className "border border-solid border-gray-500 p-2"
                                                                        prop.valueOrDefault attr
                                                                        prop.onChange onChange
                                                                    ]
                                                                ]
                                                        | None ->
                                                            prop.children [
                                                                Html.label [
                                                                    prop.className "label"
                                                                    prop.text label
                                                                ]
                                                                Html.input [
                                                                    prop.className "border border-solid border-gray-500 p-2"
                                                                    prop.valueOrDefault attr
                                                                    prop.onChange onChange
                                                                ]
                                                            ]
                                                        )]
                                                  Html.div [
                                                      prop.children [
                                                          formInput "Name" x.name (fun nameStr -> update { x with name = nameStr }) x.errors (fun err -> err.name)
                                                      ]
                                                  ]
                                            }

                                        YesNo.yesNo "Is Explicit Content?" "is_explicit_content" manuscript.id (Some manuscript.is_explicit_content)
                                                    (fun el -> dispatch <| UpdateEntityAttributeValue
                                                                               (Entities.Manuscript, [("id", AVUuid manuscript.id)], "is_explicit_content", ABool,
                                                                                Some (AVBool true), Some el, true))
                                                    (fun el -> dispatch <| UpdateEntityAttributeValue
                                                                               (Entities.Manuscript, [("id", AVUuid manuscript.id)], "is_explicit_content", ABool,
                                                                                Some (AVBool false), Some el, true))
                                                    false
                                        YesNo.yesNo "Is Abridged?" "is_abridged" manuscript.id (Some manuscript.is_abridged)
                                                    (fun el -> dispatch <| UpdateEntityAttributeValue
                                                                               (Entities.Manuscript, [("id", AVUuid manuscript.id)], "is_abridged", ABool,
                                                                                Some (AVBool true), Some el, true))
                                                    (fun el -> dispatch <| UpdateEntityAttributeValue
                                                                               (Entities.Manuscript, [("id", AVUuid manuscript.id)], "is_abridged", ABool,
                                                                                Some (AVBool false), Some el, true))
                                                    false
                                        SingleAutoComplete.autoCompleteInput
                                            { FieldLabel = "Genre Preset"
                                              Pk = [("id", manuscript.id)]
                                              SubjectEntity = Entities.Manuscript
                                              ObjectEntity = Entities.GenrePreset
                                              AttrName = "genre_preset_id"
                                              TheType = AUuid
                                              CurrentSelection = [(manuscript.genre_preset_id,
                                                                   Option.map (fun (gp:GenrePreset) -> gp.name) manuscript.genre_preset
                                                                 |> Option.defaultValue "")]
                                              AddLabel = "Assign Genre Preset"
                                              Label = "Enter Genre Preset"
                                              Constructor = REGenrePreset
                                              OnSelect = defaultUpdateEntity dispatch None
                                            }

                                        match manuscript.genre_preset with
                                        | None -> Html.none
                                        | Some genre_preset ->
                                            Html.div [
                                                prop.children [
                                                    React.fragment [for x in genre_preset.bisac_genres ->
                                                                        DefaultInput.defaultInput (sprintf "Genre Preset :: BISAC [%d]" x.sort_order) "TODO"
                                                                            (match x.genre_bisac with
                                                                             | Some genre_bisac -> sprintf "%s - %s" genre_bisac.parent_genre genre_bisac.name
                                                                             | None -> "") true]
                                                    React.fragment [for x in genre_preset.wgs_genres ->
                                                                        DefaultInput.defaultInput (sprintf "Genre Preset :: WGS [%d]" x.sort_order) "TODO"
                                                                            (match x.genre_wgs with
                                                                             | Some genre_wgs -> match genre_wgs.parent_genre with
                                                                                                 | Some parent_genre -> sprintf "%s - %s" parent_genre genre_wgs.name
                                                                                                 | None -> genre_wgs.name
                                                                             | None -> "") true]
                                                    React.fragment [for x in genre_preset.zebralution_phononet_genres ->
                                                                        DefaultInput.defaultInput (sprintf "Genre Preset :: Zebralution Phononet [%d]" x.sort_order) "TODO"
                                                                            (match x.genre_zebralution_phononet with
                                                                             | Some genre_zebralution_phononet -> genre_zebralution_phononet.name_de
                                                                             | None -> "") true]
                                                ]
                                            ]

                                        EditableTextArea.editableTextArea
                                            {| fieldLabel = "Default Blurb"
                                               value = manuscript.default_blurb
                                               disabled = false
                                               onChange = fun strVal el ->
                                                    dispatch <| UpdateEntityAttributeValue
                                                                    (Entities.Manuscript, [("id", AVUuid manuscript.id)],
                                                                     "default_blurb", AString, Some (AVString strVal),
                                                                     Some el, true)
                                               pk = manuscript.id |}

                                        Html.div [
                                            prop.className "field"
                                            prop.children [
                                                Html.label [
                                                    prop.className "label"
                                                    prop.text "Associated Projects"
                                                ]
                                                Html.ul [
                                                    prop.className "control"
                                                    prop.children [
                                                        React.fragment [for project_info in manuscript.projects ->
                                                                            let title =
                                                                                match project_info.project with
                                                                                | None -> "Unnamed Project"
                                                                                | Some prj ->
                                                                                    match prj.computed_title with
                                                                                    | None -> ""
                                                                                    | Some ct -> ct
                                                                            Html.li [
                                                                                Html.a [
                                                                                    prop.href (appCtx.AppHrefUrl (Url.EditProject project_info.project_id))
                                                                                    prop.text title
                                                                                ]
                                                                            ]
                                                        ]
                                                    ]
                                                ]
                                            ]
                                        ]
                                    ]
                                | None -> Html.p "No Manuscript. Create a new one or Open an existing one"
                            )
                    ]
                ]
                Html.div [
                    prop.className "column"
                    prop.children [
                        productComponent "Project" Html.none "project-component" (
                            match mProject with
                            | Some project ->
                                (React.fragment [

                                    SingleAutoComplete.autoCompleteInput
                                        { FieldLabel = "Manuscript"
                                          Pk = [("project_id", project.id)]
                                          SubjectEntity = Entities.ProjectManuscriptInfo
                                          ObjectEntity = Entities.Manuscript
                                          AttrName = "manuscript_id"
                                          TheType = AUuid
                                          CurrentSelection =
                                              (match project.manuscript_info with
                                               | Some mi -> [(mi.manuscript_id, match mi.manuscript with
                                                                                | Some m ->
                                                                                      // TODO: define common function to extract user-readable entity string
                                                                                      // In this case title, subtitle and all authors (the same logic is
                                                                                      // being done in the server-side on autocomplete)
                                                                                      let author_names = seq {
                                                                                                          for author in m.authors do
                                                                                                              match author.author with
                                                                                                              | Some a -> yield a.name
                                                                                                              | None -> ()
                                                                                                          } |> String.concat ", "
                                                                                      let subtitle =
                                                                                          match m.subtitle with
                                                                                          | "" -> ""
                                                                                          | x -> sprintf " (%s)" x
                                                                                      sprintf "%s%s - %s" m.title subtitle author_names
                                                                                | None -> mi.manuscript_id)]
                                               | None -> [])
                                          AddLabel = "Assign Project Manuscript"
                                          Label = "Enter Project Manuscript"
                                          Constructor = REManuscriptAuthor
                                          OnSelect = defaultUpdateEntity dispatch (Some "#manuscript-component")
                                        }

                                    SingleAutoComplete.autoCompleteInput
                                        { FieldLabel = "Project Kind"
                                          Pk = [("id", project.id)]
                                          SubjectEntity = Entities.Project
                                          ObjectEntity = Entities.ProjectKind
                                          AttrName = "project_kind"
                                          TheType = (ACustom "project_kind_enum")
                                          CurrentSelection = [(project.project_kind, project.project_kind)]
                                          AddLabel = "Assign Project Kind"
                                          Label = "Enter Project Kind"
                                          Constructor = REProjectKind
                                          OnSelect = defaultUpdateEntity dispatch None
                                        }

                                    MultiAutoComplete.autoCompleteInput<ProjectProductionInfoReader, NewProjectProductionInfoReader>
                                        { FieldLabel = "Narrators"
                                          Id = project.id
                                          SubjectEntity = Entities.Project
                                          ObjectEntity = Entities.ProjectProductionInfoReader
                                          IdName = "project_id"
                                          AttrName = "user_id"
                                          TheType = AUuid
                                          CurrentSelection = match mProductionInfo with
                                                             | Some pi -> [for reader in pi.project_production_info_readers ->
                                                                              (reader.user_id, reader.reader_name, reader)]
                                                                          |> List.sortBy (fun (_,_,reader) -> reader.sort_order)
                                                             | None -> []
                                          AddLabel = "Assign existing Narrator"
                                          Label = "Enter Narrator"
                                          Constructor = (fun userId -> REReader (project.id, userId)) // make project.id static and userId variable
                                          ElIdHighlight = None
                                          RelatedRenderer = fun x ->
                                              React.fragment [
                                                  Text.wrapText("Name",
                                                      Html.p x.reader_name)
                                                  Text.wrapText("Total Duration",
                                                      Html.p x.total_duration)
                                              ]
                                          EditRelatedBody = fun x update _doClose ->
                                              Html.div [
                                                  prop.className "flex flex-col w-96"
                                                  prop.children [
                                                      EditableInput.editableInput { id = "project_narrator_name"
                                                                                    fieldLabel = "Narrator Name"
                                                                                    extraElements = None
                                                                                    value = x.reader_name
                                                                                    disabled = false
                                                                                    onChange = fun curText el ->
                                                                                        dispatch <| UpdateEntityAttributeValue (Entities.ProjectProductionInfoReader,
                                                                                                                                [("project_id", AVUuid x.project_id)
                                                                                                                                 ("user_id", AVUuid x.user_id)],
                                                                                                                                "reader_name",
                                                                                                                                AString, Some (AVString curText), Some el, false)
                                                                                        update { x with reader_name = curText }
                                                                                  }
                                                      Html.div [
                                                          prop.className "flex flex-row justify-between items-center"
                                                          prop.children [
                                                              Html.div [
                                                                  prop.className "w-full"
                                                                  prop.children [
                                                                      EditableInput.editableInput { id = "project_narrator_user_name"
                                                                                                    fieldLabel = "User Name"
                                                                                                    extraElements = None
                                                                                                    value = (match x.user_info with
                                                                                                             | Some ui -> ui.name
                                                                                                             | None -> "")
                                                                                                    disabled = false
                                                                                                    onChange = fun curText el ->
                                                                                                        dispatch <| UpdateEntityAttributeValue (Entities.BaseUserInfo,
                                                                                                                                                [("user_id", AVUuid x.user_id)],
                                                                                                                                                "name", AString, Some (AVString curText),
                                                                                                                                                Some el, false)
                                                                                                        let newUserInfo = match x.user_info with
                                                                                                                          | Some ui -> Some { ui with name = curText }
                                                                                                                          | None -> Some { user_id = x.user_id
                                                                                                                                           name = curText }
                                                                                                        update { x with user_info = newUserInfo }
                                                                                                  }
                                                                  ]
                                                              ]
                                                              Html.button [
                                                                  prop.className "w-56 h-10 my-auto p-2 text-xs rounded border border-solid border-gray-500"
                                                                  prop.onClick (fun _ ->
                                                                      let value = (match x.user_info with
                                                                                   | Some ui -> ui.name
                                                                                   | None -> "")
                                                                      dispatch <| UpdateEntityAttributeValue (Entities.ProjectProductionInfoReader,
                                                                                                              [("project_id", AVUuid x.project_id)
                                                                                                               ("user_id", AVUuid x.user_id)],
                                                                                                              "reader_name",
                                                                                                              AString, Some (AVString value), None, false)
                                                                      update { x with reader_name = value })
                                                                  prop.text "Use as Narrator Name"
                                                              ]
                                                          ]
                                                      ]
                                                      Html.div [
                                                        prop.className "flex flex-row justify-between items-center"
                                                        prop.children [
                                                            Html.div [
                                                                prop.className "w-full"
                                                                prop.children [
                                                                    EditableInput.editableInput { id = "project_narrator_pseudonym"
                                                                                                  fieldLabel = "Pseudonym"
                                                                                                  extraElements = None
                                                                                                  value = (match x.user_reader_info with
                                                                                                           | Some uri -> Option.defaultValue "" uri.pseudonym
                                                                                                           | None -> "")
                                                                                                  disabled = false
                                                                                                  onChange = fun curText el ->
                                                                                                    dispatch <| UpdateEntityAttributeValue (Entities.BaseUserReaderInfo,
                                                                                                                                            [("user_id", AVUuid x.user_id)],
                                                                                                                                            "pseudonym", AOption AString,
                                                                                                                                            Some (AVString curText), Some el, false)
                                                                                                    let newUserReaderInfo = match x.user_reader_info with
                                                                                                                            | Some uri -> Some { uri with pseudonym = Some curText }
                                                                                                                            | None -> Some { user_id = x.user_id
                                                                                                                                             gender = "UNKNOWN"
                                                                                                                                             voice_pitch = "UNKNOWN"
                                                                                                                                             voice_age = "UNKNOWN"
                                                                                                                                             voice_type = "UNKNOWN"
                                                                                                                                             is_active = true
                                                                                                                                             notes = ""
                                                                                                                                             dialect = ""
                                                                                                                                             pseudonym = Some curText }
                                                                                                    update { x with user_reader_info = newUserReaderInfo }
                                                                                                 }
                                                                ]
                                                            ]
                                                            Html.button [
                                                                prop.className "w-56 h-10 my-auto p-2 text-xs rounded border border-solid border-gray-500"
                                                                prop.onClick (fun _ ->
                                                                    let value = (match x.user_reader_info with
                                                                                 | Some uri -> Option.defaultValue "" uri.pseudonym
                                                                                 | None -> "")
                                                                    dispatch <| UpdateEntityAttributeValue (Entities.ProjectProductionInfoReader,
                                                                                                            [("project_id", AVUuid x.project_id)
                                                                                                             ("user_id", AVUuid x.user_id)],
                                                                                                            "reader_name",
                                                                                                            AString, Some (AVString value), None, false)
                                                                    update { x with reader_name = value })
                                                                prop.text "Use as Narrator Name"
                                                            ]
                                                        ]
                                                      ]
                                                      EditableInput.editableInput { id = "project_narrator_total_duration"
                                                                                    fieldLabel = "Total Duration"
                                                                                    extraElements = None
                                                                                    value = x.total_duration
                                                                                    disabled = false
                                                                                    onChange = fun curText el ->
                                                                                        dispatch <| UpdateEntityAttributeValue (Entities.ProjectProductionInfoReader,
                                                                                                                                [("project_id", AVUuid x.project_id)
                                                                                                                                 ("user_id", AVUuid x.user_id)],
                                                                                                                                "total_duration", AString,
                                                                                                                                Some (AVString curText), Some el, false)
                                                                                        update { x with total_duration = curText }
                                                                                  }
                                                  ]
                                              ]
                                          Context = Context.createContext<ProjectProductionInfoReader, NewProjectProductionInfoReader>
                                          ApiEndpoint = Server.api.NarratorSearchRelatedEntities token
                                          OnOrderChanged = (fun xs ->
                                              printfn "----- CHANGED ORDER START"
                                              let changes =
                                                  seq {
                                                      for idx, old, new' in xs do
                                                          printfn "[%d]: %s(%d) vs %s(%d)" idx old.reader_name old.sort_order
                                                                                               new'.reader_name new'.sort_order
                                                          yield (new'.user_id, idx+1)
                                                  }
                                                  |> List.ofSeq
                                              //printfn "%A" changes
                                              appCtx.AppDispatch <| UpdateSortOrder (Entities.ProjectProductionInfoReader,
                                                                                     "project_id", "user_id", project.id, changes))
                                          OnAdd = fun relId -> printfn "ADDING HANDLER %s" relId
                                          OnUpdate = fun updated -> dispatch <| LoadUpdatedEntity (Finished (Ok updated))
                                          AddNewLabel = "Create new Narrator"
                                          AddNew = { project_id = project.id
                                                     email = ""
                                                     name = ""
                                                     pseudonym = None
                                                     gender = "UNKNOWN"
                                                     errors = None
                                                   }
                                          AddApiEndpoint = Server.api.AddNarratorRelatedEntity token
                                          AddValidator = fun x ->
                                              let newX = clientValidate x
                                              // NOTE: match x.errors with does not correctly pattern match. Use isUndefined for now
                                              //printfn "%A" newX.errors
                                              //printfn "not isUndefined %A" (isUndefined newX.errors |> not)
                                              //printfn "not emptyErrors %A" (emptyErrors newX.errors |> not)
                                              match not (isUndefined newX.errors) || not (emptyErrors newX.errors) with
                                              | true -> false, newX
                                              | false -> true, newX
                                          AddRelatedBody = fun x update _doClose ->
                                              // TODO: create Add form specific components from these
                                              let formInput (label:string) (attr:string) (onChange:string -> unit) errs (errMsg:NewProjectProductionInfoReaderErrors -> string) =
                                                  Html.div [
                                                    prop.className "field"
                                                    (match errs with
                                                    | Some err ->
                                                        if (errMsg err).Length > 0 then
                                                            prop.children [
                                                                Html.label [
                                                                    prop.className "text-sm leading-8 font-bold text-red-500"
                                                                    prop.text (label + "* (" + errMsg err + ")")
                                                                ]
                                                                Html.input [
                                                                    prop.className "border border-solid border-red-500 p-2"
                                                                    prop.valueOrDefault attr
                                                                    prop.onChange onChange
                                                                ]
                                                            ]
                                                        else
                                                            prop.children [
                                                                Html.label [
                                                                    prop.className "label"
                                                                    prop.text label
                                                                ]
                                                                Html.input [
                                                                    prop.className "border border-solid border-gray-500 p-2"
                                                                    prop.valueOrDefault attr
                                                                    prop.onChange onChange
                                                                ]
                                                            ]
                                                    | None ->
                                                        prop.children [
                                                            Html.label [
                                                                prop.className "label"
                                                                prop.text label
                                                            ]
                                                            Html.input [
                                                                prop.className "border border-solid border-gray-500 p-2"
                                                                prop.valueOrDefault attr
                                                                prop.onChange onChange
                                                            ]
                                                        ]
                                                    )]
                                              Html.div [
                                                  prop.children [
                                                      formInput "Email" x.email (fun emailStr -> update { x with email = emailStr }) x.errors (fun err -> err.email)
                                                      formInput "Name" x.name (fun nameStr -> update { x with name = nameStr }) x.errors (fun err -> err.name)
                                                      Html.div [
                                                        prop.className "field"
                                                        prop.children [
                                                            Html.label [
                                                                prop.className "label"
                                                                prop.text "Pseudonym"
                                                            ]
                                                            Html.input [
                                                                prop.className "border border-solid border-gray-500 p-2"
                                                                prop.valueOrDefault (Option.defaultValue "" x.pseudonym)
                                                                prop.onChange (fun pseudonymStr -> update { x with pseudonym = if pseudonymStr = "" then None else Some pseudonymStr })
                                                            ]
                                                        ]
                                                      ]
                                                      Html.div [
                                                        prop.className "field"
                                                        prop.children [
                                                            Html.label [
                                                                prop.className "label"
                                                                prop.text "Gender"
                                                            ]
                                                            Html.div [
                                                                prop.className "control"
                                                                prop.children [
                                                                    Html.label [
                                                                        prop.className "radio"
                                                                        prop.children [
                                                                            Html.input [
                                                                                prop.type' "radio"
                                                                                prop.isChecked (x.gender = "MALE")
                                                                                //prop.defaultChecked (x.gender = "MALE")
                                                                                prop.onChange (fun (_e:Event) ->
                                                                                    update { x with gender = "MALE" })
                                                                            ]
                                                                            Html.span [
                                                                                prop.className "ml-1"
                                                                                prop.text "Male"
                                                                            ]
                                                                        ]
                                                                    ]
                                                                    Html.label [
                                                                        prop.className "radio"
                                                                        prop.children [
                                                                            Html.input [
                                                                                prop.type' "radio"
                                                                                prop.isChecked (x.gender = "FEMALE")
                                                                                //prop.defaultChecked (x.gender = "FEMALE")
                                                                                prop.onChange (fun (_e:Event) ->
                                                                                    update { x with gender = "FEMALE" })
                                                                            ]
                                                                            Html.span [
                                                                                prop.className "ml-1"
                                                                                prop.text "Female"
                                                                            ]
                                                                        ]
                                                                    ]
                                                                    Html.label [
                                                                        prop.className "radio"
                                                                        prop.children [
                                                                            Html.input [
                                                                                prop.type' "radio"
                                                                                prop.isChecked (x.gender = "UNKNOWN")
                                                                                //prop.defaultChecked (x.gender = "UNKNOWN")
                                                                                prop.onChange (fun (_e:Event) ->
                                                                                    update { x with gender = "UNKNOWN" })
                                                                            ]
                                                                            Html.span [
                                                                                prop.className "ml-1"
                                                                                prop.text "Unknown"
                                                                            ]
                                                                        ]
                                                                    ]
                                                                ]
                                                            ]
                                                        ]
                                                      ]
                                                  ]
                                              ]
                                        }

                                    SingleAutoComplete.autoCompleteInput
                                        { FieldLabel = "Accounting Group"
                                          Pk = [("project_id", project.id)]
                                          SubjectEntity = Entities.ProjectAccountingInfo
                                          ObjectEntity = Entities.AccountingGroup
                                          AttrName = "accounting_group_id"
                                          TheType = AUuid
                                          CurrentSelection =
                                              (match mAccountingInfo with
                                              | Some ai -> [(ai.accounting_group_id, Option.map (fun (x:AccountingGroup) -> x.name)
                                                                                                ai.accounting_group
                                                                                     |> Option.defaultValue "")]
                                              | None -> [])
                                          AddLabel = "Assign Accounting Group"
                                          Label = "Enter Accounting Group"
                                          Constructor = REAccountingGroup
                                          OnSelect = defaultUpdateEntity dispatch None
                                        }

                                    (* copyright info version *)
                                    (*
                                    EditableInput.editableInput
                                        { fieldLabel = "Imprint"
                                          value = (match mCopyrightInfo with
                                                   | Some ci -> ci.p_line
                                                   | None -> "")
                                          disabled = false
                                          onChange = fun curText el ->
                                              dispatch <| UpdateEntityAttributeValue
                                                              (Entities.ProjectCopyrightInfo,
                                                               [("project_id", AVUuid project.id)],
                                                               "p_line",
                                                               AString,
                                                               Some (AVString curText), Some el, true)
                                        }

                                    EditableInput.editableInput
                                        { fieldLabel = "Copyright Line"
                                          value = (match mCopyrightInfo with
                                                   | Some ci -> ci.c_line
                                                   | None -> "")
                                          disabled = false
                                          onChange = fun curText el ->
                                              dispatch <| UpdateEntityAttributeValue
                                                              (Entities.ProjectCopyrightInfo,
                                                               [("project_id", AVUuid project.id)],
                                                               "c_line",
                                                               AString,
                                                               Some (AVString curText), Some el, true)
                                        }

                                    *)

                                    if productCount = 0 then
                                        SingleAutoComplete.autoCompleteInput
                                            { FieldLabel = "Label"
                                              Pk = [("project_id", project.id)]
                                              SubjectEntity = Entities.ProjectLabelInfoToLabel
                                              ObjectEntity = Entities.Label
                                              AttrName = "label_id"
                                              TheType = AUuid
                                              CurrentSelection =
                                                  (match mLabelInfo with
                                                  | Some label -> [(label.label_id, Option.map (fun (x:Label) -> x.name)
                                                                                                    label.label
                                                                                    |> Option.defaultValue "")]
                                                  | None -> [])
                                              AddLabel = "Assign Label"
                                              Label = "Enter Label"
                                              Constructor = RELabel
                                              OnSelect = fun props item _isAssigned ->
                                                  let msg1 = UpdateEntityAttributeValue
                                                              (props.SubjectEntity,
                                                               List.map (fun (name,id) -> (name, AVUuid id)) props.Pk,
                                                               props.AttrName,
                                                               props.TheType,
                                                               Some (AVString item.id),
                                                               None,
                                                               true)
                                                  //let msg2 = RefreshProjectCAndPLine project.id
                                                  //appCtx.AppDispatch <| Seq (msg1, msg2)
                                                  dispatch msg1
                                            }
                                    else
                                        EditableInput.editableInput
                                            { id = "project_label"
                                              fieldLabel = "Label"
                                              extraElements = None
                                              value = (match mLabelInfo with
                                                       | Some li -> match li.label with
                                                                    | Some label -> label.name
                                                                    | None -> ""
                                                       | None -> "")
                                              disabled = true
                                              onChange = fun _curText _el -> ()
                                              // not implemented because this input is disabled
                                            }

                                        EditableInput.editableInput
                                            { id = "project_copyright_line"
                                              fieldLabel = "Default Copyright Line"
                                              extraElements = None
                                              value = (match mLabelInfo with
                                                       | Some li -> match li.label with
                                                                    | Some label -> label.default_c_line
                                                                    | None -> ""
                                                       | None -> "")
                                              disabled = true
                                              onChange = fun _curText _el -> ()
                                              // not implemented because this input is disabled
                                            }

                                        EditableInput.editableInput
                                            { id = "project_copyright_c_line"
                                              fieldLabel = "C Line"
                                              extraElements =
                                                Some ( Html.a
                                                            [ prop.onClick (fun e ->
                                                                             match mCopyrightInfo with
                                                                             | Some pci -> dispatch <| RefreshProjectCLine pci.project_id
                                                                             | None -> ()
                                                                             e.preventDefault ())
                                                              prop.children [
                                                                  FrontendUtils.iconSpan "fas fa-sync"
                                                              ]
                                                            ] )
                                              value = (match mCopyrightInfo with
                                                       | Some pci -> pci.c_line
                                                       | None -> "")
                                              disabled = false
                                              onChange = fun curText el ->
                                                  dispatch <| UpdateEntityAttributeValue
                                                                  (Entities.ProjectCopyrightInfo,
                                                                   [("project_id", AVUuid project.id)],
                                                                   "c_line",
                                                                   AString,
                                                                   Some (AVString curText), Some el, true)
                                              // not implemented because this input is disabled
                                            }

                                        EditableInput.editableInput
                                            { id = "project_copyright_p_line"
                                              fieldLabel = "P Line"
                                              extraElements =
                                                Some ( Html.a
                                                            [ prop.onClick (fun e ->
                                                                             match mCopyrightInfo with
                                                                             | Some pci -> dispatch <| RefreshProjectPLine pci.project_id
                                                                             | None -> ()
                                                                             e.preventDefault ())
                                                              prop.children [
                                                                  FrontendUtils.iconSpan "fas fa-sync"
                                                              ]
                                                            ] )
                                              value = (match mCopyrightInfo with
                                                       | Some pci -> pci.p_line
                                                       | None -> "")
                                              disabled = false
                                              onChange = fun curText el ->
                                                  dispatch <| UpdateEntityAttributeValue
                                                                  (Entities.ProjectCopyrightInfo,
                                                                   [("project_id", AVUuid project.id)],
                                                                   "p_line",
                                                                   AString,
                                                                   Some (AVString curText), Some el, true)
                                            }

                                    SingleAutoComplete.autoCompleteInput
                                        { FieldLabel = "Project Status"
                                          Pk = [("project_id", project.id)]
                                          SubjectEntity = Entities.ProjectProductionInfo
                                          ObjectEntity = Entities.ProjectStatus
                                          AttrName = "status"
                                          TheType = (ACustom "project_status_enum")
                                          CurrentSelection =
                                              (match mProjectStatus with
                                               | Some status -> [(status.value, status.value)]
                                               | None -> [])
                                          AddLabel = "Assign Project Status"
                                          Label = "Enter Project Status"
                                          Constructor = REProjectStatus
                                          OnSelect = defaultUpdateEntity dispatch None
                                        }

                                    // TODO: extract as own component
                                    Html.div [
                                        prop.className "field"
                                        prop.children [
                                            Html.label [
                                                prop.className "label"
                                                prop.text "Product EAN"
                                            ]
                                            Html.div [
                                                prop.className "control p-2 flex flex-col space-y-2"
                                                prop.children [
                                                    React.fragment [
                                                        for ware in project.wares ->
                                                            React.fragment [for product in ware.products ->
                                                                                Html.div [
                                                                                    prop.className "rounded hover:bg-gray-50 px-4 py-2"
                                                                                    prop.children [
                                                                                        Html.a [
                                                                                            prop.className ""
                                                                                            prop.href (appCtx.AppHrefUrl (Url.EditProduct product.id))
                                                                                            prop.children [
                                                                                                Html.p (sprintf "%s - %s" product.ean product.title)
                                                                                                Html.span [
                                                                                                    prop.className "rounded bg-blue-50 text-blue-400 px-3 py-1 text-sm"
                                                                                                    prop.text (sprintf "%s" product.product_status)
                                                                                                ]
                                                                                            ]
                                                                                        ]
                                                                                        ]]]]
                                                ]
                                            ]
                                        ]
                                    ]
                                ])
                            | None -> Html.p "No Project. Create a new one or Open an existing one")

                        if not <| Option.isNone state.EntitiesInEdit.Product then
                            productComponent "Project Series" Html.none "project-series-component"
                                (match mProject, mProjectSeriesInfo, mProjectSeries, state.EntitiesInEdit.Product with
                                 | Some project, Some psi, Some projectSeries, Some product ->
                                    (React.fragment [
                                        Html.div [
                                            prop.className "flex flex-row justify-between"
                                            prop.children [
                                                Button.button (Some "Create New Project Series") (Some "fas fa-plus-circle") "is-success is-light"
                                                    [ prop.onClick (fun e ->
                                                                     dispatch <| CreateProjectSeries product
                                                                     e.preventDefault ())
                                                    ]
                                            ]
                                        ]
                                        SingleAutoComplete.autoCompleteInput
                                            { FieldLabel = "Series"
                                              SubjectEntity = Entities.ProjectProjectSeries
                                              ObjectEntity = Entities.ProjectSeries
                                              Pk = [("project_id", project.id)
                                                    ("project_series_id", projectSeries.id)]
                                              AttrName = "project_series_id"
                                              TheType = AUuid
                                              CurrentSelection = [(projectSeries.id, projectSeries.title)]
                                              AddLabel = "Assign Project Series"
                                              Label = "Enter Project Series"
                                              Constructor = REProjectSeries
                                              OnSelect = defaultUpdateEntity dispatch None
                                            }
                                        EditableInput.editableInput
                                            { id = "series_title"
                                              fieldLabel = "Series Title"
                                              extraElements = None
                                              value = projectSeries.title
                                              disabled = false
                                              onChange = fun curText el ->
                                                  dispatch <| UpdateEntityAttributeValue
                                                                  (Entities.ProjectSeries,
                                                                   [("id", AVUuid projectSeries.id)],
                                                                   "title", AString,
                                                                   Some (AVString curText), Some el, true)
                                            }
                                        EditableInput.editableInput
                                            { id = "series_designator"
                                              fieldLabel = "Series Designator"
                                              extraElements = None
                                              value = projectSeries.episode_designator
                                              disabled = false
                                              onChange = fun curText el ->
                                                  dispatch <| UpdateEntityAttributeValue
                                                                  (Entities.ProjectSeries,
                                                                   [("id", AVUuid projectSeries.id)],
                                                                   "episode_designator", AString,
                                                                   Some (AVString curText), Some el, true)
                                            }

                                        React.keyedFragment (sprintf "project_series_%s_part_number_%s" project.id projectSeries.id,
                                            [
                                                IntInput.intInput { fieldLabel = "Series Part Number"
                                                                    mValue = (Some psi.part_number)
                                                                    disabled = false
                                                                    onChange = fun pk intVal el ->
                                                                        dispatch <| UpdateEntityAttributeValue
                                                                                        (Entities.ProjectProjectSeries, pk,
                                                                                         "part_number", AInt, Some (AVInt intVal),
                                                                                         Some el, true)
                                                                    pk = [("project_id", project.id)
                                                                          ("project_series_id", projectSeries.id)]
                                                                    attrName = "part_number" }
                                            ])

                                        Button.button (Some "Reset Project Series")
                                            (Some "fas fa-ban") "is-danger is-light"
                                            [ prop.onClick (fun e ->
                                                             dispatch <| UnAssignProjectSeries (product.id, project.id, projectSeries.id)
                                                             e.preventDefault ())
                                            ]
                                    ])
                                 | Some project, _, None, Some product ->
                                     Html.div [
                                         prop.children [
                                            Html.p "This product is not part of a series."
                                            Html.div [
                                                prop.className "flex flex-row justify-between"
                                                prop.children [
                                                    Button.button (Some "Create New Project Series") (Some "fas fa-plus-circle") "is-success is-light"
                                                        [ prop.onClick (fun e ->
                                                                         dispatch <| CreateProjectSeries product
                                                                         e.preventDefault ())
                                                        ]
                                                ]
                                            ]
                                            Html.p "or"
                                            SingleAutoComplete.autoCompleteInput
                                                { FieldLabel = ""
                                                  SubjectEntity = Entities.ProjectProjectSeries
                                                  ObjectEntity = Entities.ProjectSeries
                                                  Pk = [("project_id", project.id)]
                                                  AttrName = "project_series_id"
                                                  TheType = AUuid
                                                  CurrentSelection = []
                                                  AddLabel = "Assign Project Series"
                                                  Label = "Enter Project Series"
                                                  Constructor = REProjectSeries
                                                  OnSelect = fun _props item _isAssigned ->
                                                      dispatch <| AssignProjectSeries (product.id, project.id, item.id)

                                                }
                                         ]
                                     ]
                                 | _,_,_,_ -> Html.none
                                )
                    ]
                ]
                Html.div [
                    prop.className "column"
                    prop.children [
                        match state.EntitiesInEdit.Product with
                        | Some x ->
                            let buttons =
                                match x.ware with
                                | Some ware -> match ware.project with
                                               | Some project -> match project.label_info with
                                                                 | Some label_info ->
                                                                        Html.div [
                                                                            prop.className "flex flex-row justify-between"
                                                                            prop.children [
                                                                                Button.button (Some "Create New Product") (Some "fas fa-plus-circle") "is-success is-light"
                                                                                    [ prop.onClick (fun e ->
                                                                                                     printf("CREATE NEW PRODUCT IN CLIENT")
                                                                                                     dispatch <| CreateNewProduct (x.ware_id, label_info.label_id)
                                                                                                     e.preventDefault ())
                                                                                    ]

                                                                                Button.button (Some "Duplicate Product with new EAN") (Some "far fa-copy") "is-success is-light"
                                                                                    [ prop.onClick (fun e ->
                                                                                                     dispatch <| DuplicateProduct x
                                                                                                     e.preventDefault ())
                                                                                    ]

                                                                                Button.button (Some "Duplicate as Spotify Product") (Some "far fa-copy") "is-success is-light"
                                                                                    [ prop.onClick (fun e ->
                                                                                                     dispatch <| DuplicateSpotifyProduct x
                                                                                                     e.preventDefault ())
                                                                                    ]
                                                                            ]
                                                                        ]
                                                                 | None -> Html.none
                                               | None -> Html.none
                                | None -> Html.none
                            productComponent "Product" buttons "product-component" (React.fragment [
                                //editableInput "ID" x.id true dispatch "product" "id" x.id
                                Html.div [
                                    prop.children [
                                        EditableInput.editableInput { id = "product_ean"
                                                                      fieldLabel = "EAN"
                                                                      extraElements =
                                                                            Some (
                                                                                if Set.contains x.id state.OverwriteEANSet then
                                                                                    Html.a
                                                                                        [ prop.onClick (fun e ->
                                                                                                         dispatch <| ToggleOverwriteEAN x.id
                                                                                                         e.preventDefault ())
                                                                                          prop.children [
                                                                                              FrontendUtils.iconSvgUnlock 16
                                                                                          ]
                                                                                        ]
                                                                                else
                                                                                    Html.a
                                                                                        [ prop.onClick (fun e ->
                                                                                                         dispatch <| ToggleOverwriteEAN x.id
                                                                                                         e.preventDefault ())
                                                                                          prop.children [
                                                                                              FrontendUtils.iconSvgLock 16
                                                                                          ]
                                                                                        ])
                                                                      value = x.ean
                                                                      disabled = not <| Set.contains x.id state.OverwriteEANSet
                                                                      onChange = fun curText el ->
                                                                          dispatch <| UpdateEntityAttributeValue (Entities.Product, [("id", AVUuid x.id)], "ean", AString, Some (AVString curText), Some el, true)
                                                                    }
                                    ]
                                ]
                                Html.div [
                                    prop.children [
                                        EditableInput.editableInput { id = "product_bookstream_article_number"
                                                                      fieldLabel = "Bookstream Article Number"
                                                                      extraElements =
                                                                            Some (
                                                                                if Set.contains x.id state.OverwriteArticleNumberSet then
                                                                                    Html.a
                                                                                        [ prop.onClick (fun e ->
                                                                                                         dispatch <| ToggleOverwriteArticleNumber x.id
                                                                                                         e.preventDefault ())
                                                                                          prop.children [
                                                                                              FrontendUtils.iconSvgUnlock 16
                                                                                          ]
                                                                                        ]
                                                                                else
                                                                                    Html.a
                                                                                        [ prop.onClick (fun e ->
                                                                                                         dispatch <| ToggleOverwriteArticleNumber x.id
                                                                                                         e.preventDefault ())
                                                                                          prop.children [
                                                                                              FrontendUtils.iconSvgLock 16
                                                                                          ]
                                                                                        ])
                                                                      value = x.bookstream_article_number
                                                                      disabled = not <| Set.contains x.id state.OverwriteArticleNumberSet
                                                                      onChange = fun curText el ->
                                                                          dispatch <| UpdateEntityAttributeValue (Entities.Product, [("id", AVUuid x.id)], "bookstream_article_number", AString, Some (AVString curText), Some el, true)
                                                                    }
                                    ]
                                ]
                                EditableInput.editableInput { id = "product_title"
                                                              fieldLabel = "Title"
                                                              extraElements = None
                                                              value = x.title
                                                              disabled = false
                                                              onChange = fun curText el ->
                                                                  dispatch <| UpdateEntityAttributeValue (Entities.Product, [("id", AVUuid x.id)], "title", AString, Some (AVString curText), Some el, true)
                                                            }
                                EditableInput.editableInput { id = "product_subtitle"
                                                              fieldLabel = "Subtitle"
                                                              extraElements = None
                                                              value = x.subtitle
                                                              disabled = false
                                                              onChange = fun curText el ->
                                                                  dispatch <| UpdateEntityAttributeValue (Entities.Product, [("id", AVUuid x.id)], "subtitle", AString, Some (AVString curText), Some el, true)
                                                            }

                                SingleAutoComplete.autoCompleteInput
                                    { FieldLabel = "Product Status"
                                      Pk = [("id", x.id)]
                                      SubjectEntity = Entities.Product
                                      ObjectEntity = Entities.ProductStatus
                                      AttrName = "product_status"
                                      TheType = (ACustom "product_status_enum")
                                      CurrentSelection = [(x.product_status, x.product_status)]
                                      AddLabel = "Assign Product Status"
                                      Label = "Enter Product Status"
                                      Constructor = REProductStatus
                                      OnSelect = defaultUpdateEntity dispatch None
                                    }

                                //defaultInput "Original Release (TODO: Date)" "released_at" (match x.released_at with
                                //                                                            | Some se -> se.ToString ()
                                //                                                            | None -> "") true
                                DateInput.dateInput "Original Release" Entities.Product x.id "released_at" x.released_at
                                                    (fun actualType newVal el ->
                                                        dispatch <| UpdateEntityAttributeValue
                                                                        (Entities.Product, [("id", AVUuid x.id)], "released_at",
                                                                         actualType, Some (AVDate newVal), Some el, true))
                                                    (fun el ->
                                                        dispatch <| UpdateEntityAttributeValue
                                                                        (Entities.Product, [("id", AVUuid x.id)], "released_at", AOption ADate,
                                                                         None, Some el, true))
                                                    true
                                //defaultInput "Sales Start (TODO: Date)" "sales_start" (x.sales_start.ToString ()) true
                                DateInput.dateInput "Sales Start" Entities.Product x.id "sales_start" (Some x.sales_start)
                                                    (fun actualType newVal el ->
                                                        dispatch <| UpdateEntityAttributeValue
                                                                        (Entities.Product, [("id", AVUuid x.id)], "sales_start",
                                                                         actualType, Some (AVDate newVal), Some el, true))
                                                    (fun el ->
                                                        dispatch <| UpdateEntityAttributeValue
                                                                        (Entities.Product, [("id", AVUuid x.id)], "sales_start", AOption ADate,
                                                                         None, Some el, true))
                                                    false
                                //defaultInput "Sales End (TODO: Date)" "sales_end" (match x.sales_end with
                                //                                                   | Some se -> se.ToString ()
                                //                                                   | None -> "")true
                                DateInput.dateInput "Sales End" Entities.Product x.id "sales_end" x.sales_end
                                                    (fun actualType newVal el ->
                                                        dispatch <| UpdateEntityAttributeValue
                                                                        (Entities.Product, [("id", AVUuid x.id)], "sales_end",
                                                                         actualType, Some (AVDate newVal), Some el, true))
                                                    (fun el ->
                                                        dispatch <| UpdateEntityAttributeValue
                                                                        (Entities.Product, [("id", AVUuid x.id)], "sales_end", AOption ADate,
                                                                         None, Some el, true))
                                                    true
                                React.keyedFragment ( sprintf "product_%s_price" x.id,
                                    [ IntInput.intInput { fieldLabel = "Price (in Cents)"
                                                          mValue = x.default_price
                                                          disabled = false
                                                          onChange = fun pk intVal el ->
                                                            dispatch <| UpdateEntityAttributeValue
                                                                            (Entities.Product, pk,
                                                                             "default_price", ABigInt, Some (AVInt intVal),
                                                                             Some el, true)
                                                          pk = [("id",x.id)]
                                                          attrName = "default_price" }
                                    ])

                                SingleAutoComplete.autoCompleteInput
                                    { FieldLabel = "Music License"
                                      Pk = [("id", x.id)]
                                      SubjectEntity = Entities.Product
                                      ObjectEntity = Entities.MusicRightsInfo
                                      AttrName = "musical_work_rights"
                                      TheType = (ACustom "music_rights_info_enum")
                                      CurrentSelection = [(x.musical_work_rights,x.musical_work_rights)]
                                      AddLabel = "Assign Music License"
                                      Label = "Enter Music License"
                                      Constructor = REMusicRightsInfo
                                      OnSelect = defaultUpdateEntity dispatch None
                                    }

                                if x.musical_work_rights <> "NO_MUSIC_INCLUDED" then
                                    React.keyedFragment ( sprintf "product_%s_music_percentage" x.id,
                                        [
                                            IntInput.intSliderInput {| min = 0
                                                                       max = 100
                                                                       fieldLabel = "Music Percentage (%)"
                                                                       mValue = (Some x.musical_percentage)
                                                                       disabled = false
                                                                       onChange = fun intVal el ->
                                                                        dispatch <| UpdateEntityAttributeValue
                                                                          (Entities.Product, [("id", AVUuid x.id)],
                                                                           "musical_percentage", AInt, Some (AVInt intVal),
                                                                           Some el, true)
                                                                       attrName = "musical_percentage"
                                                                       pk = x.id |}
                                        ])

                                else Html.none

                                MultiAutoComplete.autoCompleteInput<ProductArtistProfileInfo, ProductArtistProfileInfo.NewArtistProfile>
                                    { FieldLabel = "Marketing Profiles"
                                      Id = x.id
                                      SubjectEntity = Entities.Product
                                      ObjectEntity = Entities.ProductArtistProfileInfo
                                      IdName = "product_id"
                                      AttrName = "artist_profile_id"
                                      TheType = AUuid
                                      CurrentSelection = [for pari in x.product_artist_profile_infos ->
                                                             match pari.artist_profile with
                                                             | Some ap ->
                                                                 (pari.artist_profile_id, ap.name, pari)
                                                             | None ->
                                                                 (pari.artist_profile_id, "", pari)]
                                                          |> List.sortBy (fun (_,_,pari) -> pari.sort_order)
                                      AddLabel = "Assign existing Artist Profile"
                                      Label = "Enter Artist Profile"
                                      Constructor = fun artistProfileId -> REArtistProfile (x.id, artistProfileId)
                                      ElIdHighlight = None
                                      RelatedRenderer = fun x ->
                                          React.fragment [
                                              Text.wrapText("Name",
                                                            match x.artist_profile with
                                                            | Some ap -> Html.p ap.name
                                                            | None -> Html.p "")
                                              Text.wrapText("Role",
                                                            match x.artist_profile_role with
                                                            | Some apr -> Html.p (sprintf "%s (%d)" apr.bookstream_description apr.zebralution_role_number)
                                                            | None -> Html.p "")
                                          ]
                                      EditRelatedBody = fun x update doClose ->
                                          let ap_name =
                                              match x.artist_profile with
                                              | Some ap -> ap.name
                                              | None -> ""
                                          Html.div [
                                              prop.className "flex flex-col w-96"
                                              prop.children [
                                                  EditableInput.editableInput { id = "marketing_profile_name"
                                                                                fieldLabel = "Marketing Profile Name"
                                                                                extraElements = None
                                                                                value = ap_name
                                                                                disabled = false
                                                                                onChange = fun curText el ->
                                                                                    dispatch <| UpdateEntityAttributeValue (Entities.ArtistProfile,
                                                                                                                            [("id", AVUuid x.artist_profile_id)],
                                                                                                                            "name",
                                                                                                                            AString, Some (AVString curText), Some el, false)
                                                                                    update { x with artist_profile = Some { id = x.artist_profile_id
                                                                                                                            name = curText } }
                                                                              }
                                                  SingleAutoComplete.autoCompleteInput
                                                      { FieldLabel = "Role"
                                                        Pk = [("product_id", x.product_id)
                                                              ("artist_profile_id", x.artist_profile_id)]
                                                        SubjectEntity = Entities.ProductArtistProfileInfo
                                                        ObjectEntity = Entities.ArtistProfileRole
                                                        AttrName = "role_id"
                                                        TheType = AUuid
                                                        CurrentSelection = [(x.role_id, match x.artist_profile_role with
                                                                                        | None -> ""
                                                                                        | Some apr ->
                                                                                            match apr.rendered_value with
                                                                                            | Some rv -> rv
                                                                                            | None ->
                                                                                                sprintf "%s (%d)" apr.bookstream_description apr.zebralution_role_number)]
                                                        AddLabel = "Assign Role"
                                                        Label = "Enter Role"
                                                        Constructor = REArtistProfileRole
                                                        OnSelect = fun (props:SingleAutoCompleteProps) (item:SimpleAutoCompleteItem) (_is_assigned:bool) ->
                                                            doClose ()
                                                            dispatch <| UpdateEntityAttributeValue
                                                                          (props.SubjectEntity,
                                                                           List.map (fun (name,id) -> (name, AVUuid id)) props.Pk,
                                                                           props.AttrName,
                                                                           props.TheType,
                                                                           Some (AVString item.id),
                                                                           None,
                                                                           true)
                                                            // defaultUpdateEntity appCtx elIdHighlight (props: SingleAutoCompleteProps) (item: SimpleAutoCompleteItem) _isAssigned
                                                            //update { x with artist_profile_role = Some { id = item.id
                                                            //                                             rendered_value = Some item.value
                                                            //                                             zebralution_role_number = 0
                                                            //                                             zebralution_description = ""
                                                            //                                             bookstream_description = "" } }
                                                      }
                                              ]
                                          ]
                                      Context = Context.createContext<ProductArtistProfileInfo, ProductArtistProfileInfo.NewArtistProfile>
                                      ApiEndpoint = Server.api.ArtistProfileSearchRelatedEntities token
                                      OnOrderChanged = (fun xs ->
                                          let changes =
                                              seq {
                                                  for idx, _old, new' in xs do
                                                      yield (new'.artist_profile_id, idx+1)
                                              } |> List.ofSeq
                                          appCtx.AppDispatch <| UpdateSortOrder (Entities.ProductArtistProfileInfo,
                                                                                 "product_id", "artist_profile_id", x.id, changes))
                                      OnAdd = fun relId -> printfn "ADDING HANDLER %s" relId
                                      OnUpdate = fun updated -> dispatch <| LoadUpdatedEntity (Finished (Ok updated))
                                      AddNewLabel = "Create new Marketing Profile"
                                      AddNew = { product_id = x.id
                                                 name = ""
                                                 errors = None
                                               }
                                      AddApiEndpoint = Server.api.AddArtistProfileRelatedEntity token
                                      AddValidator = fun x ->
                                          let newX = ProductArtistProfileInfo.clientValidate x
                                          match not (isUndefined newX.errors) || not (ProductArtistProfileInfo.emptyErrors newX.errors) with
                                          | true -> false, newX
                                          | false -> true, newX
                                      AddRelatedBody = fun x update _doClose ->
                                          // TODO: create Add form specific components from these
                                          let formInput (label:string) (attr:string) (onChange:string -> unit) errs (errMsg:ProductArtistProfileInfo.NewArtistProfileError -> string) =
                                              Html.div [
                                                prop.className "field"
                                                (match errs with
                                                | Some err ->
                                                    if (errMsg err).Length > 0 then
                                                        prop.children [
                                                            Html.label [
                                                                prop.className "text-sm leading-8 font-bold text-red-500"
                                                                prop.text (label + "* (" + errMsg err + ")")
                                                            ]
                                                            Html.input [
                                                                prop.className "border border-solid border-red-500 p-2"
                                                                prop.valueOrDefault attr
                                                                prop.onChange onChange
                                                            ]
                                                        ]
                                                    else
                                                        prop.children [
                                                            Html.label [
                                                                prop.className "label"
                                                                prop.text label
                                                            ]
                                                            Html.input [
                                                                prop.className "border border-solid border-gray-500 p-2"
                                                                prop.valueOrDefault attr
                                                                prop.onChange onChange
                                                            ]
                                                        ]
                                                | None ->
                                                    prop.children [
                                                        Html.label [
                                                            prop.className "label"
                                                            prop.text label
                                                        ]
                                                        Html.input [
                                                            prop.className "border border-solid border-gray-500 p-2"
                                                            prop.valueOrDefault attr
                                                            prop.onChange onChange
                                                        ]
                                                    ]
                                                )]
                                          Html.div [
                                              prop.children [
                                                  formInput "Name" x.name (fun nameStr -> update { x with name = nameStr }) x.errors (fun err -> err.name)
                                              ]
                                          ]
                                    }

                                YesNo.yesNo "Is Spotify?" "is_spotify" x.id (Some x.is_spotify)
                                            (fun el -> dispatch <| UpdateEntityAttributeValue
                                                                       (Entities.Product, [("id", AVUuid x.id)], "is_spotify", ABool,
                                                                        Some (AVBool true), Some el, true))
                                            (fun el -> dispatch <| UpdateEntityAttributeValue
                                                                       (Entities.Product, [("id", AVUuid x.id)], "is_spotify", ABool,
                                                                        Some (AVBool false), Some el, true))
                                            false

                                Choice.Choice.choices "Segementation Type" "segmentation_type" x.id ["A";"B";"C"] x.segmentation_type
                                                      (fun choice el ->
                                                            dispatch <| UpdateEntityAttributeValue
                                                                            (Entities.Product, [("id", AVUuid x.id)], "segmentation_type",
                                                                             AString, Some (AVString choice), Some el, true))
                                                      false

                                EditableInput.editableInput { id = "total_duration"
                                                              fieldLabel = "Total Duration"
                                                              extraElements = None
                                                              value = x.total_duration
                                                              disabled = true
                                                              onChange = fun _curText _el -> ()
                                                            }

                                EditableTextArea.editableTextArea {| fieldLabel = "Blurb"
                                                                     value = (Option.defaultValue "" x.blurb)
                                                                     disabled = false
                                                                     onChange = fun strVal el ->
                                                                        dispatch <| UpdateEntityAttributeValue
                                                                                        (Entities.Product, [("id", AVUuid x.id)],
                                                                                         "blurb", AString, Some (AVString strVal),
                                                                                         Some el, true)
                                                                     pk = x.id |}

//                                Button.button (Some "Export ONIX") (Some "fas fa-file-export") "is-success is-light"
//                                    [ prop.onClick (fun e ->
//                                                     dispatch <| ExportONIX x
//                                                     //printfn "[ON CLICK EXPORT ONIX] %A" state.ONIX
//                                                     e.preventDefault ())
//                                    ]

                                Button.button (Some "Export XLSX") (Some "fas fa-file-export") "is-success is-light"
                                    [ prop.onClick (fun e ->
                                                     dispatch <| ExportXLSX x
                                                     e.preventDefault ())
                                    ]
                            ])
                        | None ->
                            productComponent "Product" Html.none "product-component"
                                (match mProject with
                                 | Some project ->
                                     let mWareId = List.tryHead project.wares
                                     let mLabelInfo = project.label_info
                                     match mWareId, mLabelInfo with
                                     | None, _ -> Html.none
                                     | _, None -> Html.none
                                     | Some w, Some label_info ->
                                         (React.fragment [
                                            Html.div [
                                                prop.className "flex flex-row justify-between"
                                                prop.children [
                                                    Button.button (Some "Create New Product") (Some "fas fa-plus-circle") "is-success is-light"
                                                        [ prop.onClick (fun e ->
                                                                         dispatch <| CreateNewProduct (w.id, label_info.label_id)
                                                                         e.preventDefault ())
                                                        ]
                                                ]
                                            ]
                                         ])
                                 | None -> Html.none)

                        match productTrackInfo with
                        | None -> Html.none
                        | Some pti ->
                            productComponent "Product Track Info" Html.none "track-component" (React.fragment [
                                Html.table [
                                    prop.className "table"
                                    prop.children [
                                        Html.thead [
                                            Html.tr [
                                                Html.th [
                                                    Html.span "ISRC"
                                                ]
                                                Html.th [
                                                    Html.span "Duration"
                                                ]
                                                Html.th [
                                                    Html.span "Track #"
                                                ]
                                                Html.th [
                                                    Html.span "Title"
                                                ]
                                            ]
                                        ]
                                        for item in pti.product_track_info do
                                            Html.tr [
                                                Html.th [
                                                    Html.span item.isrc
                                                ]
                                                Html.th [
                                                    Html.span item.duration
                                                ]
                                                Html.th [
                                                    Html.span item.track_number
                                                ]
                                                Html.th [
                                                    Html.span item.title
                                                ]
                                            ]

                                    ]
                                ]
                            ])
                    ]
                ]
            ]
        ]

    let page = React.functionComponent(fun(props:EditPageProps) ->
        let appCtx = React.useContext Context.AppContext
        let state, dispatch = React.useElmish(init props, update appCtx)
        React.useEffectOnce(fun _e ->
            FrontendUtils.Extensions.setTimeout (fun () ->
                if props.FocusOnTitle then
                    document.getElementById("manuscript_title").focus ()
                else ()) 50
            |> ignore)

        let pti =
            match state.ProductId with
            | Some _ ->
                // we have a product page
                match state.ProductTrackInfo with
                | HasNotStartedYet -> None
                | InProgress -> None
                | Resolved (Error _error) -> None
                | Resolved (Ok productTrackInfo) ->
                    Some productTrackInfo
            | None -> None
        Html.div [
            prop.children [
                Html.a [
                    prop.className ["button"]
                    prop.href (props.HRefUrl Url.Index)
                    prop.text "Back"
                ]
                renderEditProduct state dispatch pti
                //Html.h1 (sprintf "%A" pti.IsNone)
            ]
        ])