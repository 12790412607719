module App

open Browser
open Components
open Feliz
open Feliz.Bulma.ElementBuilders
open Feliz.Router
open Feliz.Bulma
open Feliz.Popover
open Elmish
open Shared
open Common
open SharedGQL
open Client.Types
open Client.BaseTypes
open Client.Url
open MetaGQL.Types
open Queries.Search.Search
open Indygemma.Auth.Client.Auth

type MsgPopupListProps = {
    Messages: ReactElement list
}

let MsgPopupList = React.functionComponent(fun (inp:MsgPopupListProps) ->
    Html.ul [
        prop.style [
            style.position.fixedRelativeToWindow
            style.bottom 30
            style.right 30
            style.alignItems.center
            style.justifyContent.center
            style.padding 10
            style.zIndex 9999
        ]
        prop.children [
            for x in inp.Messages do
                Html.li [
                    prop.className "mb-1"
                    prop.children [
                        x
                    ]
                ]
        ]
    ])

type MsgPopupProps = {
    Message: string
    LinkTarget: string option
}

let ErrorMsgPopup = React.functionComponent(fun (inp:MsgPopupProps) ->
    Html.div [
        prop.style [
            style.padding 10
            style.backgroundColor.orangeRed
            style.color.black
            //style.fontWeight.bold
        ]
        prop.children [
            Html.div[
                prop.className "flex flex-row p-2 space-x-2"
                prop.children [
                    Html.div [
                        prop.children [
                            Html.i [
                                prop.className "fas fa-exclamation-circle"
                            ]
                        ]
                    ]
                    Html.p [ prop.text inp.Message ]
                ]
            ]
        ]
    ])

let SuccessMsgPopup = React.functionComponent(fun (inp:MsgPopupProps) ->
    Html.div [
        prop.style [
            style.padding 10
            style.backgroundColor.lightGreen
            style.color.black
            //style.fontWeight.bold
        ]
        prop.children [
            Html.div[
                prop.className "flex flex-row p-2 space-x-2"
                prop.children [
                    Html.div [
                        prop.children [
                            Html.i [
                                prop.className ""
                            ]
                        ]
                    ]
                    match inp.LinkTarget with
                    | None -> Html.p [ prop.text inp.Message ]
                    | Some targetUrl ->
                        Html.a [
                            prop.href targetUrl
                            prop.children [
                                Html.p [
                                    prop.text inp.Message
                                ]
                            ]
                        ]
                ]
            ]
        ]
    ])

let private call dispatch callF responseF = Utilities.Remoting.withException dispatch callF responseF DoNothing
let private err okF result = Utilities.Remoting.err DoNothing okF result

let processProductCount token state =
    let loadProductCountsResult dispatch =
        let (filterOptions:FilterOptions) =
            { labelFilter = state.LabelFilter
              productStatusFilter = state.ProductStatusFilter
              projectStatusFilter = state.ProjectStatusFilter
              labelIdsExclusion = state.ImprintExcludeFilter
            }
        call dispatch (Server.api.GetProductStatusCounts token filterOptions)
            (err (fun res ->
                    LoadProductStatusCountsResult (Finished (Ok res))))
    { state with ProductStatusCountsResult = InProgress }, Cmd.fromAsync loadProductCountsResult

let processIndex token (state:AppState) query =
    let loadSearchAllEntitiesResult dispatch =
        let (limitOptions:LimitOptions) =
            { Limit = state.LimitResult
              Offset = state.Offset
            }
        let (orderOptions:OrderOptions) =
            { ProductOrderBy = state.ProductOrderBy
              ProjectOrderBy = state.ProjectOrderBy
              ManuscriptOrderBy = state.ManuscriptOrderBy
            }
        let (filterOptions:FilterOptions) =
            { labelFilter = state.LabelFilter
              productStatusFilter = state.ProductStatusFilter
              projectStatusFilter = state.ProjectStatusFilter
              labelIdsExclusion = state.ImprintExcludeFilter
            }
        printfn "IN processIndex"
        printfn "TOKEN: %A" token
        call dispatch (Server.api.SearchAllEntities token query (Some limitOptions) orderOptions filterOptions)
            (err (fun res ->
                    LoadSearchAllEntitiesResult (Finished (Ok res))))
    LocalStorage.set "last-query" query
    { state with SearchAllEntitiesResult = InProgress; PopupMessages = [] }, Cmd.fromAsync loadSearchAllEntitiesResult

let processUploadTrackInfo token (state:AppState) filename content =
    let op _dispatch = async {
        try
            let! result = Server.api.UploadTrackInfoCSV token filename content
            match result with
            | Ok res ->
                return AddPopupMessage (SuccessMsgPopup {
                    Message = sprintf "(%s): Uploaded %d tracks for product %A with total duration: %s. Click to open product." filename res.Result.TrackCount res.Result.EAN res.Result.TotalRuntTime
                    LinkTarget = Some <| (Url.EditProduct res.Result.ProductId |> toUrl |> Router.format)
                })
            | Error err ->
                return AddPopupMessage (ErrorMsgPopup { Message = sprintf "(%s): %A" filename err; LinkTarget = None })
        with error ->
            Log.developmentError error
            return DoNothing
    }
    state, Cmd.fromAsync op

let processExportBatchXLSX token (state:AppState) =
    let op dispatch =
        let (orderOptions:OrderOptions) =
            { ProductOrderBy = state.ProductOrderBy
              ProjectOrderBy = state.ProjectOrderBy
              ManuscriptOrderBy = state.ManuscriptOrderBy
            }
        let (filterOptions:FilterOptions) =
            { labelFilter = state.LabelFilter
              productStatusFilter = state.ProductStatusFilter
              projectStatusFilter = state.ProjectStatusFilter
              labelIdsExclusion = state.ImprintExcludeFilter
            }
        call dispatch (Server.api.ExportBatchXLSX token state.QueryText orderOptions filterOptions)
            (err (fun res ->
                printf "[processExportXLSX res] %A" res
                let anchor = Dom.document.createElement "a"
                anchor.setAttribute("href", sprintf "/%s" res)
                anchor.setAttribute("download", res)
                anchor.click()
                // refresh current view
                Search state.QueryText))
    let nextCmds = Cmd.batch [
        Cmd.ofMsg <| AddPopupMessage (SuccessMsgPopup {
                        Message = sprintf "Starting batch delivery of products..."
                        LinkTarget = None
                     })
        Cmd.fromAsync op
    ]
    state, nextCmds

let handleUrl (state:AppState) url finalCmd =
    let nextState =
        match url with
        | Url.Index ->
            { state with CurrentPage = Page.Index }
        | Url.EditProduct productId ->
            { state with CurrentPage = Page.EditProduct productId }
        | Url.EditProject projectId ->
            { state with CurrentPage = Page.EditProject (projectId, false) }
        | Url.EditProjectFocusTitle (projectId, doFocus) ->
            { state with CurrentPage = Page.EditProject (projectId, doFocus) }
        | Url.NotFound ->
            { state with CurrentPage = Page.NotFound }
    { nextState with PopupMessages = [] }, finalCmd

let init() =
    let initialUrl = parseUrl (Router.currentUrl())
    let lastQuery = LocalStorage.get "last-query";
    let defaultState = {
        Jwt = None
        SearchAllEntitiesResult = HasNotStartedYet
        ProductStatusCountsResult = HasNotStartedYet
        QueryText = lastQuery;
        Guid = LocalSession.init ();
        SelectedTab = ProductTab;
        LimitResult = 20
        Offset = 0
        CurrentUrl = initialUrl;
        CurrentPage = Page.Index;
        ProductOrderBy = OrderByDesc "created_at"
        ProjectOrderBy = OrderByDesc "created_at"
        ManuscriptOrderBy = OrderByNone
        LabelFilter = BS_Z
        ProductStatusFilter = PSF_All
        ProjectStatusFilter = PRF_All
        ImprintExcludeFilter = Set.empty
        ImprintStarredFilter = Set.empty
        PopupMessages = [
             //ErrorMsgPopup { Message = "ThiS IS A CUSTOM Error" }
             //SuccessMsgPopup { Message = "This is a success message" }
        ]
        ShowStagingBanner = Config.variable "IS_STAGING" = "true"
    }
//    let _defaultCmd = Cmd.batch [
//        Cmd.ofMsg (Search lastQuery)
//        Cmd.ofMsg FetchProductCounts
//    ]
    let nextCmd = Cmd.fromAsync (fun _dispatch ->
        refreshAccessToken SetJwt (fun _ -> DoNothing))
    handleUrl defaultState initialUrl nextCmd

let withReactError x =
    ReactErrorBoundary.renderCatchFn
        (fun (error, info) ->
            printfn "SubComponent failed to render %s. Error:%A" info.componentStack error
        )
        (Html.div [
            Html.text "ERROR RENDERING REACT COMPONENT"
        ])
        x

let searchAndFetchProductCounts state = Cmd.batch [
    Cmd.ofMsg (Search state.QueryText)
    Cmd.ofMsg FetchProductCounts
]

let private processCreateNewManuscript token state =
    let op dispatch =
        call dispatch (Server.api.CreateNewManuscript token)
            (err (fun res ->
                Navigate (Url.EditProjectFocusTitle (res.id, true))))
    state, Cmd.fromAsync op

let rec update (msg: AppMsg) (state: AppState) =
    let token = { Indygemma.Auth.Types.Token =
                    match state.Jwt with
                    | Some tok -> tok
                    | None -> "" }
    match msg with
    | Batch messages ->
        state, Cmd.batch <| List.map Cmd.ofMsg messages
    | SetJwt token ->
        match state.Jwt with
        | Some currToken ->
            if currToken <> token then
                //printfn "SETTING TOKEN (changed): %s" token
                { state with Jwt = Some token }, Cmd.none
            else
                state, Cmd.none
        | None ->
            //printfn "SETTING TOKEN (currently empty): %s" token
            { state with Jwt = Some token }, Cmd.none
    | ClearJwt ->
        { state with Jwt = None }, Cmd.none
    | Logout ->
        { state with Jwt = None }, Cmd.fromAsync (fun _dispatch -> logout (fun _ -> DoNothing))
    | Seq (msg, nextMsg) ->
        let nextState, _nextCmd = update msg state
        //update nextMsg nextState
        let nextCmds = [
            _nextCmd
            Cmd.ofMsg nextMsg
        ]
        nextState, Cmd.batch nextCmds
    | AddPopupMessage el ->
        { state with PopupMessages = el :: state.PopupMessages }, Cmd.none
    | RemovePopupMessage idx ->
        let xs =
            List.mapi (fun iIdx x -> (iIdx, x)) state.PopupMessages
            |> List.filter (fun (i,_) -> i <> idx)
            |> List.map snd
        { state with PopupMessages = xs }, Cmd.none
    | RemoveAllPopupMessages ->
        { state with PopupMessages = [] }, Cmd.none
    | UploadTrackInfo (filename, content) ->
        processUploadTrackInfo token state filename content
    | UploadTrackInfoError ->
        window.alert("Error uploading...")
        state, Cmd.none
    | SetOffset offset ->
        { state with Offset = offset }, searchAndFetchProductCounts state
    | ToggleExcludeImprintFilter labelId ->
        // just toggle the label id
        let changedExcludeFilter, changedStarredFilter =
            if Set.contains labelId state.ImprintExcludeFilter then
                Set.remove labelId state.ImprintExcludeFilter, state.ImprintStarredFilter
            else
                Set.add labelId state.ImprintExcludeFilter, Set.remove labelId state.ImprintStarredFilter
        { state with ImprintExcludeFilter = changedExcludeFilter
                     ImprintStarredFilter = changedStarredFilter
                     Offset = 0 }, searchAndFetchProductCounts state
    | ToggleStarredImprintFilter labelId ->
        // 1) if not in list: exclude everything unless it is already starred. Add it to the starred list
        // 2) if in list: remove from starred and add to exclude list
        let changedStarred, changedExclude =
            if Set.contains labelId state.ImprintStarredFilter then
                Set.remove labelId state.ImprintStarredFilter, Set.add labelId state.ImprintExcludeFilter
            else
                let xs = getLabelIdsForFilter state.LabelFilter
                let changedExcludeFilter =
                    List.filter (fun x ->
                        (not <| Set.contains x state.ImprintStarredFilter) && labelId <> x) xs
                    |> List.fold (fun newE x -> Set.add x newE) state.ImprintExcludeFilter
                let changedExcludeFilter = Set.remove labelId changedExcludeFilter
                let changedStarredFilter =
                    Set.add labelId state.ImprintStarredFilter
                changedStarredFilter, changedExcludeFilter
        { state with ImprintStarredFilter = changedStarred
                     ImprintExcludeFilter = changedExclude
                     Offset = 0 }, searchAndFetchProductCounts state
    | SetLabelFilter value ->
        // when changing the label filter we should reset the imprint filter
        { state with LabelFilter = value
                     ImprintExcludeFilter = Set.empty
                     ImprintStarredFilter = Set.empty
                     Offset = 0 }, searchAndFetchProductCounts state
    | SetProductStatusFilter value ->
        { state with ProductStatusFilter = value
                     Offset = 0 }, searchAndFetchProductCounts state
    | SetProjectStatusFilter value ->
        { state with ProjectStatusFilter = value
                     Offset = 0 }, searchAndFetchProductCounts state
    | SetProductOrderBy value ->
        { state with ProductOrderBy = value }, Cmd.ofMsg (Search state.QueryText)
    | SetProjectOrderBy value ->
        { state with ProjectOrderBy = value }, Cmd.ofMsg (Search state.QueryText)
    | SetManuscriptOrderBy value ->
        { state with ManuscriptOrderBy = value }, Cmd.ofMsg (Search state.QueryText)
    | DoNothing ->
        state, Cmd.none
    | Navigate nextUrl ->
        state, navigateToUrl nextUrl
    | SwitchToIndexAndSearch query ->
        let nextUrlCmd = Url.Index |> toUrl |> Cmd.navigate
        state, Cmd.batch [nextUrlCmd; Cmd.ofMsg <| Search query]
    | UrlChanged nextUrl ->
        handleUrl { state with CurrentUrl = nextUrl } nextUrl Cmd.none
    | UpdateSearchQuery query ->
        { state with QueryText = query }, Cmd.none
    | Search query ->
        processIndex token state query
    | FetchProductCounts ->
        processProductCount token state
    | ExportBatchXLSX ->
        processExportBatchXLSX token state
    | CreateNewManuscript ->
        processCreateNewManuscript token state
    | LoadSearchAllEntitiesResult Started ->
        { state with SearchAllEntitiesResult = HasNotStartedYet }, Cmd.none
    | LoadSearchAllEntitiesResult (Finished counter) ->
        { state with SearchAllEntitiesResult = Resolved counter }, Cmd.none
    | LoadProductStatusCountsResult Started ->
        { state with ProductStatusCountsResult = HasNotStartedYet }, Cmd.none
    | LoadProductStatusCountsResult (Finished result) ->
        { state with ProductStatusCountsResult = Resolved result }, Cmd.none
    | SelectTab tab ->
        // changing tab should reset the page offset
        { state with SelectedTab = tab
                     Offset = 0 }, searchAndFetchProductCounts state
    | UpdateSortOrder  (entity, idName, attrName, pk, mapping) ->
        let op dispatch =
            call dispatch (Server.api.UpdateSortOrder token entity idName attrName pk mapping)
                (err (fun _res ->
                    //match mEl with
                    //| Some el ->
                        //FrontendUtils.yellowFadeElement el |> ignore
                    //| None -> ()
                    DoNothing))
        let nextCmds = [Cmd.fromAsync op]
        state, Cmd.batch nextCmds

let renderProjectSeries (x:ProjectSeries) =
    Html.tr [
        prop.key x.id
        prop.className "project-series-item"
        prop.style [ style.marginTop 15; style.marginBottom 15 ]
        prop.children [
            Html.th x.title
            Html.th x.episode_designator
        ]
    ]

let renderWare (x:Ware) =
    Html.tr [
        prop.key x.id
        prop.className "ware-item"
        prop.style [ style.marginTop 15; style.marginBottom 15 ]
        prop.children [
            Html.th x.name
        ]
    ]

let renderArtistProfile (x:ArtistProfile) =
    Html.tr [
        prop.key x.id
        prop.className "artist-profile-item"
        prop.style [ style.marginTop 15; style.marginBottom 15 ]
        prop.children [
            Html.th x.name
        ]
    ]

let renderSearchInput = React.functionComponent(fun () ->
    let appCtx = React.useContext(Context.AppContext)
    let curSearchReq, setCurSearchReq = React.useState None
    Html.div [
        prop.className "field"
        prop.children [
            Html.p [
                prop.className ["control"; "has-icons-left"]
                prop.children [
                    Html.input [
                        prop.style [
                            style.minWidth 500
                        ]
                        prop.className "input is-rounded"
                        prop.type' "text"
                        prop.placeholder "Search"
                        prop.valueOrDefault appCtx.AppState.QueryText
                        prop.onChange (fun (text:string) ->
                            printfn "Current Url: %A" appCtx.AppState.CurrentUrl

                            // clear previous request
                            match curSearchReq with
                            | None ->
                                ()
                            | Some req -> FrontendUtils.Extensions.clearTimeout req

                            // set next request
                            let timeoutId =
                                FrontendUtils.Extensions.setTimeout (fun _ ->
                                    if appCtx.AppState.CurrentUrl <> Url.Index then
                                        SwitchToIndexAndSearch text
                                    else
                                        Search text
                                    |> appCtx.AppDispatch) 200
                            setCurSearchReq (Some timeoutId)
                            UpdateSearchQuery text |> appCtx.AppDispatch
                            ())
                    ]
                    FrontendUtils.iconSpan "fas fa-search"
                ]
            ]
        ]
    ])

let logo () = StaticFile.import "./imgs/bookstreamlogo.svg"

type PopoverUploadTrackInfoInput =
    { Dispatch: AppMsg -> unit
    }

let popoverUploadTrackInfo = React.functionComponent(fun(props:PopoverUploadTrackInfoInput) ->
        let popoverOpen, toggleOpen = React.useState false
        let enableCover () =
            let el = document.getElementById("global-cover")
            el.classList.add "cover-screen"
        let disableCover () =
            let el = document.getElementById("global-cover")
            el.classList.remove "cover-screen"
        Popover.popover [
            popover.body [
                Html.div [
                    prop.className "bg-white w-auto h-auto p-10 rounded-lg flex flex-col space-y-4"
                    prop.children [
                        Html.p "Upload Track Info"
                        Html.input [
                            prop.className "input mt-2"
                            prop.type' "file"
                            prop.multiple true
                            prop.onInput (fun ev ->
                                let inputEl = (ev.target :?> Browser.Types.HTMLInputElement)
                                for idx in [0..inputEl.files.length] do
                                    let file = (ev.target :?> Browser.Types.HTMLInputElement).files.Item(idx)
                                    let reader = FileReader.Create ()
                                    reader.onload <- fun evt ->
                                        toggleOpen false
                                        disableCover ()
                                        props.Dispatch <| UploadTrackInfo (file.name, (string (evt.target :?> Browser.Types.FileReader).result))
                                    reader.onerror <- fun _evt ->
                                        toggleOpen false
                                        disableCover ()
                                        props.Dispatch <| UploadTrackInfoError
                                    reader.readAsText(file))
                        ]
                        Html.p "Warning: By uploading a track CSV file any existing tracks for the product will be removed and overwritten!"
                    ]
                ]
            ]
            popover.isOpen popoverOpen
            popover.place.left
            popover.onOuterAction (fun _ ->
                disableCover ()
                toggleOpen false)
            popover.children [
                Html.a [
                    prop.className "tag hover:bg-gray-200"
                    prop.children [
                        FrontendUtils.iconSpan "far fa-file-audio"
                        Html.span "Upload Track CSV"
                    ]
                    prop.onClick (fun _ ->
                        toggleOpen true
                        enableCover ())
                ]
            ]
        ]
    )

let header = React.functionComponent(fun () ->
    let appCtx = React.useContext(Context.AppContext)
    Bulma.level [
        prop.style [
            style.marginTop 20
            style.marginBottom 35
        ]
        prop.children [
            Html.div [
                prop.className "flex flex-col w-full"
                prop.children [
                    if appCtx.AppState.ShowStagingBanner then
                        Html.div [
                            prop.className "p-8 mb-5 bg-red-200 border-4 border-red-400 font-semibold text-2xl"
                            prop.text "ENVIRONMENT: STAGING"
                        ]

                    Html.div [
                        prop.className "flex flex-row justify-between items-center"
                        prop.children [
                            Html.div [
                                prop.className "flex flex-row items-center space-x-4"
                                prop.children [
                                    Html.div [
                                        Html.h1 [
                                            prop.className "title"
                                            prop.text "Bookstream Tentacle"
                                            prop.children [
                                                Html.a [
                                                    prop.href (Url.Index |> toUrl |> Router.format)
                                                    prop.children [
                                                        Html.img [
                                                            prop.src (logo ())
                                                            prop.width 250
                                                        ]
                                                    ]
                                                ]
                                            ]
                                        ]
                                    ]

                                    Html.div [
                                        renderSearchInput ()
                                    ]
                                ]
                            ]

                            Html.div [
                                prop.className "flex flex-row items-center space-x-1"
                                prop.children [
                                    Button.button (Some "Create New Manuscript") (Some "fas fa-plus-circle") "is-success is-light"
                                        [ prop.onClick (fun e ->
                                                         appCtx.AppDispatch CreateNewManuscript
                                                         e.preventDefault ())
                                        ]
                                    popoverUploadTrackInfo { Dispatch = appCtx.AppDispatch }
                                    if appCtx.AppState.Jwt.IsSome then
                                        Html.a [
                                            prop.className "ml-2 font-semibold text-sm"
                                            prop.text "Logout"
                                            prop.onClick (fun _ -> appCtx.AppDispatch Logout)
                                        ]
                                ]
                            ]
                        ]
                    ]
                ]
            ]
        ]
    ])

let hrefUrl url = url |> toUrl |> Router.format

let showRender _productId _state _dispatch =
    //renderShowProduct state.EditProduct dispatch
    Html.none

let notFoundRender _state _dispatch =
    Html.h1 "Page not found"

[<ReactComponent>]
let Portal(content: ReactElement) =
    let root = Dom.document.getElementById("portal-root")
    ReactDOM.createPortal(content, root)

let appRender = React.functionComponent(fun (state:AppState,dispatch:AppMsg -> unit) ->
    let appCtx:AppContext =
        { Jwt = if state.Jwt.IsNone then "" else state.Jwt.Value
          AppState = state
          AppDispatch = dispatch
          AppHrefUrl = hrefUrl
        }
    React.contextProvider(Context.AppContext, appCtx,
        withReactError (
            React.router [
                router.onUrlChanged (parseUrl >> UrlChanged >> dispatch)
                router.children [
                    if state.Jwt.IsNone then
                        React.keyedFragment("LoginPage", [Pages.Login.Login.page { AppDispatch = dispatch }])
                    else
                        Bulma.container [
                            container.isFluid
                            prop.children [
                                header ()
                                match state.CurrentPage with
                                    | Page.Index ->
                                        React.fragment [
                                            Pages.Index.Index.page {
                                                Name = "index"
                                                InitMsgs = [ AppMsg.Search state.QueryText
                                                             AppMsg.FetchProductCounts ]
                                            }
                                        ]
                                    | Page.NotFound -> notFoundRender state dispatch
                                    | Page.EditProduct productId ->
                                        let key = sprintf "EditProduct: %s" productId
                                        React.keyedFragment(key, [
                                           Pages.Edit.Edit.page { State = state
                                                                  Dispatch = dispatch
                                                                  ProductId = Some productId
                                                                  HRefUrl = hrefUrl
                                                                  FocusOnTitle = false
                                                                  InitMsg = Pages.Edit.Edit.Msg.InitProduct productId }
                                        ])
                                    | Page.EditProject (projectId, doFocus) ->
                                        let key = sprintf "EditProject: %s" projectId
                                        React.keyedFragment(key, [
                                           Pages.Edit.Edit.page { State = state
                                                                  Dispatch = dispatch
                                                                  ProductId = None
                                                                  HRefUrl = hrefUrl
                                                                  FocusOnTitle = doFocus
                                                                  InitMsg = Pages.Edit.Edit.Msg.InitProject projectId }
                                        ])

                                //
                                // The "global-cover" element is required for the popover component.
                                //
                                Html.div [
                                    prop.id "global-cover"
                                    prop.className ""
                                ]

                                //Portal(ErrorMsgPopup "This is a sample error message (e.g. on constraint violation)." )
                                Portal(MsgPopupList {
                                    Messages = state.PopupMessages
                                })
                            ]
                        ]
                ]
            ]
        )))

let render (state: AppState) (dispatch: AppMsg -> unit) =
    appRender (state, dispatch)
