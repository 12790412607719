module Queries.ProductTrackInfo

open MetaGQL.Types
open SharedGQL

module ProductTrackInfo =

    type GetResult = { product_track_info: ProductTrackInfo list }

    let queryAll =
        { QueryInput = []
          QueryMapping =
              [{ Entity = Entities.ProductTrackInfo
                 Projection = ProjectionHelper.ofEntity Entities.ProductTrackInfo
                 Filter = []
                 OrderBy = OrderByNone
                 WithAggregate = NoAggregate
                 Limit = NoLimit
                 Offset = None
                 Alias = None
              }]
        }

    let queryGetProductTrackInfoForProductId =
        { QueryInput = [ DefVarBang("product_id", AUuid) ]
          QueryMapping =
              [{ Entity = Entities.ProductTrackInfo
                 Projection = ProjectionHelper.ofEntity Entities.ProductTrackInfo
                 Filter = [ OpEq (SimpleAttr "product_id", LookupVar "product_id") ]
                 OrderBy = OrderByAsc "track_number"
                 WithAggregate = NoAggregate
                 Limit = NoLimit
                 Offset = None
                 Alias = None
              }]
        }

    let queryGetProductTrackInfoForISRC =
        { QueryInput = [ DefVar ("isrc", AString) ]
          QueryMapping =
              [{ Entity = Entities.ProductTrackInfo
                 Projection = ProjectionHelper.ofEntity Entities.ProductTrackInfo
                 Filter = [ OpEq (SimpleAttr "isrc", LookupVar "isrc") ]
                 OrderBy = OrderByNone
                 WithAggregate = NoAggregate
                 Limit = NoLimit
                 Offset = None
                 Alias = None
              }]
        }

    type DeleteResult = { affected_rows: int }

    let mutateDeleteAllProductTracks =
        { MutationInput = [ DefVarBang("product_id", AUuid) ]
          MutationMapping =
              [ Delete
                    { Name = "delete_product_track_info"
                      Filter = [ OpEq (SimpleAttr "product_id", LookupVar "product_id" ) ]
                      Entity = Entities.ProductTrackInfo
                    }
              ]
        }

    type InsertResult = { insert_product_track_info_one: ProductTrackInfo option }

    let mutationCreateNewProductTrackInfo =
        { MutationInput = [ DefVarBang("isrc", AString)
                            DefVarBang("product_id", AUuid)
                            DefVarBang("duration", AInterval)
                            DefVarBang("track_number", AInt)
                            DefVarBang("title", AString)
                            DefVarBang("title_version", AString)
                          ]
          MutationMapping =
              [ InsertOne
                    { Name = "insert_product_track_info_one"
                      Entity = Entities.ProductTrackInfo
                      Projection = ProjectionHelper.ofEntity Entities.ProductTrackInfo
                      Assignment = [ (SimpleAttr "isrc", LookupVar "isrc")
                                     (SimpleAttr "product_id", LookupVar "product_id")
                                     (SimpleAttr "duration", LookupVar "duration")
                                     (SimpleAttr "track_number", LookupVar "track_number")
                                     (SimpleAttr "title", LookupVar "title")
                                     (SimpleAttr "title_version", LookupVar "title_version")
                                   ]
                    } ]
        }
