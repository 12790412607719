module Components.YesNo

module YesNo =

    open Feliz
    open Browser.Types
    open Browser.Dom

    let yesNo (fieldLabel:string) (attrName:string) (pk:string) (value:bool option)
              (onYes:HTMLElement -> unit) (onNo:HTMLElement -> unit)
              disabled =
        let elId = sprintf "yesNo_%s_%s" pk attrName
        Html.div [
            prop.className "field"
            prop.children [
                Html.label [
                    prop.className "label"
                    prop.text fieldLabel
                ]
                Html.div [
                    prop.id elId
                    prop.className "control"
                    prop.children [
                        Html.label [
                            prop.className "radio"
                            prop.children [
                                Html.input [
                                    prop.disabled disabled
                                    prop.type' "radio"
                                    prop.name attrName
                                    prop.defaultChecked (Option.defaultValue false value)
                                    prop.onChange (fun (_e:Event) ->
                                        let el = document.querySelector ("#"+elId) :?> HTMLElement
                                        //dispatch <| UpdateEntityAttributeValue (entity, [(idName, AVUuid pk)], attrName, ABool, Some (AVBool true), Some el, true)
                                        onYes el
                                    )
                                ]
                                Html.span [
                                    prop.style [
                                        style.marginLeft 5
                                    ]
                                    prop.text "Yes"
                                ]
                            ]
                        ]
                        Html.label [
                            prop.className "radio"
                            prop.text "No"
                            prop.children [
                                Html.input [
                                    prop.disabled disabled
                                    prop.type' "radio"
                                    prop.name attrName
                                    prop.defaultChecked (Option.map not value |> Option.defaultValue false)
                                    prop.onChange (fun (_e:Event) ->
                                        let el = document.querySelector ("#"+elId) :?> HTMLElement
                                        // dispatch <| UpdateEntityAttributeValue (entity, [(idName, AVUuid pk)], attrName, ABool, Some (AVBool false), Some el, true)
                                        onNo el
                                    )
                                ]
                                Html.span [
                                    prop.style [
                                        style.marginLeft 5
                                    ]
                                    prop.text "No"
                                ]
                            ]
                        ]
                    ]
                ]
            ]
        ]
