﻿module Shared

open MetaGQL.Types
open Queries.Autocomplete
open Queries.MultiRelatedEntity
open Queries.ProductTrackInfo
open Queries.Search.Search
open SharedGQL
open Indygemma.Auth.Types

/// Defines how routes are generated on server and mapped from client
let routerPaths _typeName method = sprintf "/api/%s" method

// TODO: maybe Manuscript option?
type ProductComposite = {
    Id : string
    Ean : string
    Title : string
    Subtitle : string
    ManuscriptId : string option
    ManuscriptTitle : string option
    ManuscriptSubtitle : string option
    ManuscriptAuthors : string list
    ManuscriptIsExplicitContent : bool option
    ManuscriptIsAbridged : bool option
    ManuscriptLanguageId : string option
    ManuscriptGenrePresetId : string option
    ManuscriptComment : string option
    ManuscriptDefaultBlurb : string option
    ProjectId : string
    ProjectTitle : string
    ProjectProductionNarrators : string list
    ProjectProjectSeriesId : string option
    ProjectProjectSeriesPartNumber : int option
    ProjectCopyrightInfoPLine : string option
    ProjectCopyrightInfoCLine : string option
    ProjectAccountingInfoAccountingGroupId : string option
    ProjectProjectKind : string
    ProjectDescription : string option
    ReleasedAt : string option
    SalesStart : string
    SalesEnd : string option
    DefaultPrice : int option
    MusicalWorkRights : string
    MusicalPercentage : int
    ArtistProfileIds : string list
    Blurb : string option
}

type ProductCompositeResult =
    { product: SharedGQL.Product list }

type RelatedNarratorInfo =
    { project_production_info_reader: ProjectProductionInfoReader
      user_info: UserInfo
      user_reader_info: UserReaderInfo
    }

type ErrorType =
    | ErrorMessages of string list
    | ErrorInvalidAccessToken

type ResponseWithJwt<'a> =
    {
        Jwt : string
        Result : 'a
    }

type Response<'a> = Async<Result<ResponseWithJwt<'a>, ErrorType>>

type UploadedTrackCSVResult = {
    EAN: string
    TrackCount: int
    TotalRuntTime: string
    ProductId: string
}

type ExportXLSXResult = {
    Filename: string
    UpdatedEntities: Queries.Update.Update.UpdateResult list
}

/// A type that specifies the communication protocol between client and server
/// to learn more, read the docs at https://zaid-ajaj.github.io/Fable.Remoting/src/basics.html
type IServerApi = {
    GetProductStatusCounts : AccessToken -> FilterOptions -> Response<ProductStatusCountsResult>
    SearchAllEntities : AccessToken -> string -> LimitOptions option -> OrderOptions -> FilterOptions -> Response<SearchAllEntitiesResult>
    GetOneProductComposite : AccessToken -> string -> Response<Product>
    GetProject : AccessToken -> string -> Response<Project>
    SimpleSearchRelatedEntities : AccessToken -> AutoComplete.SimpleRelatedEntityRequest -> Response<AutoComplete.SimpleAutoCompleteResult>

    UpdateEntityAttribute : AccessToken -> MetaGQL.Types.AEntity -> (string * string) list -> string -> MetaGQL.Types.AType -> MetaGQL.Types.AValue option -> Response<Queries.Update.Update.UpdateResult list>
    AddMultiRelatedEntity : AccessToken -> MetaGQL.Types.AEntity -> string -> string -> string -> string -> Response<Queries.Update.Update.UpdateResult list>
    DeleteMultiRelatedEntity : AccessToken -> MetaGQL.Types.AEntity -> string -> string -> int -> Response<Queries.Update.Update.UpdateResult list>

    // entity idName attrName pk mapping
    UpdateSortOrder : AccessToken -> MetaGQL.Types.AEntity -> string -> string -> string -> (string * int) list -> Response<bool>

    // Project -> Narrators
    NarratorSearchRelatedEntities : AccessToken -> AutoComplete.ExtraRelatedEntityRequest<ProjectProductionInfoReader> -> Response<AutoComplete.ExtraAutoCompleteResult<ProjectProductionInfoReader>>
    AddNarratorRelatedEntity : AccessToken -> Queries.Add.ProjectProductionInfoReader.NewProjectProductionInfoReader -> Response<Result<Queries.Update.Update.UpdateResult list, Queries.Add.ProjectProductionInfoReader.NewProjectProductionInfoReader>>

    // Manuscript -> Authors
    AuthorSearchRelatedEntities : AccessToken -> AutoComplete.ExtraRelatedEntityRequest<AuthorManuscript> -> Response<AutoComplete.ExtraAutoCompleteResult<AuthorManuscript>>
    AddAuthorRelatedEntity : AccessToken -> Queries.Add.AuthorManuscript.NewAuthor -> Response<Result<Queries.Update.Update.UpdateResult list, Queries.Add.AuthorManuscript.NewAuthor>>

    // Product -> ArtistProfile
    ArtistProfileSearchRelatedEntities : AccessToken -> AutoComplete.ExtraRelatedEntityRequest<ProductArtistProfileInfo> -> Response<AutoComplete.ExtraAutoCompleteResult<ProductArtistProfileInfo>>
    AddArtistProfileRelatedEntity : AccessToken -> Queries.Add.ProductArtistProfileInfo.NewArtistProfile -> Response<Result<Queries.Update.Update.UpdateResult list, Queries.Add.ProductArtistProfileInfo.NewArtistProfile>>

    CreateNewProduct : AccessToken -> string -> string -> Response<Product>
    DuplicateProduct : AccessToken -> Product -> Response<Product>
    DuplicateSpotifyProduct : AccessToken -> Product -> Response<Product>

    ExportONIX : AccessToken -> Product -> Response<string>
    ExportXLSX : AccessToken -> Product -> Response<ExportXLSXResult>
    ExportBatchXLSX : AccessToken -> string -> OrderOptions -> FilterOptions -> Response<string>

    CreateProjectSeries: AccessToken -> Product -> Response<Product>
    AssignProjectSeries: AccessToken -> string -> string -> string -> Response<Product>
    UnAssignProjectSeries: AccessToken -> string -> string -> string -> Response<Product>

    CreateNewManuscript: AccessToken -> Response<Project>

    UploadTrackInfoCSV : AccessToken -> string -> string -> Response<UploadedTrackCSVResult> // returns product ID if successful
    GetProductTrackInfo : AccessToken -> string -> Response<ProductTrackInfo.GetResult>

    RefreshProjectCLine : AccessToken -> string -> Response<Queries.Update.Update.UpdateResult list>
    RefreshProjectPLine : AccessToken -> string -> Response<Queries.Update.Update.UpdateResult list>
    RefreshProjectCAndPLine : AccessToken -> string -> Response<Queries.Update.Update.UpdateResult list>
}