module Components.DefaultInput

module DefaultInput =
    
    open Feliz
    
    let defaultInput (fieldLabel:string) name value disabled =
        Html.div [
            prop.className "field"
            prop.children [
                Html.label [
                    prop.className "label"
                    prop.text fieldLabel
                ]
                Html.div [
                    prop.className "control"
                    prop.children [
                        Html.input [
                            prop.disabled disabled
                            prop.className "input"
                            prop.type' "text"
                            prop.placeholder value
                            prop.valueOrDefault value
                            prop.name name
                        ]
                    ]
                ]
            ]
        ]
