module Components.DragAndDrop

open Fable.Core
open Browser.Types
open Browser.Dom

[<Emit("$0 === null")>]
let isNull (_x:'a): bool = jsNative

[<Emit("$0.previousSibling")>]
let previousSibling : Node -> obj = jsNative

[<Emit("$0.nextSibling")>]
let nextSibling : Node -> obj = jsNative

[<Emit("$0.nextSibling === null")>]
let nextSiblingIsNull : Node -> bool = jsNative

module DragAndDrop =
    
    open Feliz
    
    type IItem<'T> =
        { id: int
          item: 'T }
        
    let createItem<'T> (id:int) (item:'T) : IItem<'T> = { id = id; item = item  }
    
    type Props<'T> =
        { Id: string
          CssClasses: string
          Items: IItem<'T> list
          ItemRenderer: IItem<'T> -> int -> ReactElement
          OnChange: IItem<'T> list -> unit
        }
        
    let rec isBefore' (cur:Node) (target:Node) =
        if cur = target then
            //printfn "] cur = target"
            true
        else
            //printfn "] isBefore' 2"
            //printfn "cur = %A" cur
            if isNull cur then
                //printfn "exit with false"
                false
            else
                //printfn "isBefore' cur.previousSibling: %A" cur.previousSibling
                isBefore' cur.previousSibling target
        
    let private isBefore (el1:Node) (el2:Node) =
        if el2.parentNode = el1.parentNode then
            //printfn "el2.parentNode = el1.parentNode"
            //printfn "el1.parentNode: %A" el1.parentNode
            //printfn "el2.parentNode: %A" el2.parentNode
            isBefore' el1.previousSibling el2
        else
            false
    
    let private dragStart setSelected (e:DragEvent) =
        e.dataTransfer.effectAllowed <- "move"
        e.dataTransfer.setData("text/plain", "") |> ignore
        let el = e.target :?> Node
        setSelected (Some el)
        ()
        
    let private dragOver (ulRef:IRefValue<HTMLElement option>) selected (e:DragEvent) =
        e.preventDefault ()
        e.dataTransfer.dropEffect <- "move"
        let el = e.target :?> Node
        let elHtml = el :?> HTMLElement
        let isDraggable = elHtml.parentElement.hasAttribute "draggable"
        //printfn "selected: %A" selected
        //printfn "elHtml: %A" elHtml.parentElement
        //printfn "elHtml.innerHtml: %A" elHtml.parentElement.innerHTML
        //printfn "isDraggable: %A" isDraggable
        match selected, isDraggable with
        | Some (sel:Node), true ->
            match ulRef.current with
            | Some ul ->
                let selHtml = sel :?> HTMLElement
                try
                    if (selHtml.getAttribute "data-idx") <> (elHtml.parentElement.getAttribute "data-idx") then
                        //printfn "selected: %A" sel
                        //printfn "elHtml: %A" elHtml
                        //printfn "elHtml.parentElement: %A" elHtml.parentElement
                        if isBefore sel el.parentNode then
                            //printfn "1)"
                            //printfn "selected: %A" sel
                            //printfn "elHtml: %A" elHtml
                            //printfn "elHtml.parentElement: %A" elHtml.parentElement
                            //el.parentNode.insertBefore (sel, el) |> ignore
                            ul.insertBefore (sel, el.parentNode) |> ignore
                            ()
                        else
                            //printfn "2)"
                            //printfn "selected: %A" sel
                            //printfn "selected idx: %A" ((sel :?> HTMLElement).getAttribute "data-idx")
                            //printfn "elHtml.parentElement idx: %A" (elHtml.parentElement.getAttribute "data-idx")
                            //printfn "elHtml.nextSibling: %A" elHtml.nextSibling
                            //printfn "elHtml.parentElement: %A" elHtml.parentElement
                            //printfn "elHtml.nextSibling.parentElement: %A" elHtml.nextSibling.parentElement
                            ul.insertBefore (sel, el.nextSibling) |> ignore
                            //el.parentNode.insertBefore (sel, el.nextSibling) |> ignore
                            ()
                    else
                        ()
                with err ->
                    ()
            | None -> ()
        | Some _sel, false ->
            ()
        | None, _ ->
            ()
            
    let private toList (nodeList:NodeList) =
        let length = (nodeList.length |> float) - (1.0)
        [0.0 .. length]
        |> List.map (fun i -> nodeList.item i  :?> HTMLElement)
            
    let private dragEnd elId onChange (ulRef:IRefValue<HTMLElement option>) (itemsMap:Map<int, 'T>) setSelected (_e:DragEvent) =
        setSelected None
        let (currentOrder:(int * int) list) = // (idx * item-id) list
            match ulRef.current with
            | Some ul ->
                toList ul.childNodes
                |> List.map (fun el -> (el.getAttribute "data-idx" |> int, el.getAttribute "data-item-id" |> int))
            | None -> []
        let folder s ((idx,itemId):(int * int)) =
            (idx, createItem itemId (Map.find itemId itemsMap)) :: s
        let orderedItemList = List.fold folder [] currentOrder
                              |> List.map snd
                              |> List.rev
        //printfn "Updated: %A" (orderedItemList |> List.map (fun item -> item.id))
        onChange orderedItemList
        ()
        
    type ItemProps<'T> =
        { idx: int
          x: IItem<'T>
          renderer: IItem<'T> -> int -> ReactElement
          ulRef: IRefValue<HTMLElement option>
          itemsMap: Map<int, 'T>
          selected: Node option
          setSelected: Node option -> unit
        }
        
    let private renderItem<'T> = React.functionComponent(fun(props:ItemProps<'T>) ->
        Html.li [
            prop.className ""
            prop.draggable true
            prop.onDragStart (dragStart props.setSelected)
            prop.onDragOver (dragOver props.ulRef props.selected)
            //prop.onDragEnd (dragEnd props.ulRef props.itemsMap props.setSelected)
            prop.custom("data-idx", props.idx)
            //prop.custom("data-item-id", props.x.id)
            prop.children [
                props.renderer props.x props.idx
            ]
        ])
    
    let private renderItem2 idx x elId renderer onChange ulRef itemsMap selected setSelected =
        Html.li [
            prop.className ""
            prop.draggable true
            prop.onDragStart (dragStart setSelected)
            prop.onDragOver (dragOver ulRef selected)
            prop.onDragEnd (dragEnd elId onChange ulRef itemsMap setSelected)
            prop.custom("data-idx", idx)
            prop.custom("data-item-id", x.id)
            prop.children [
                renderer x idx
            ]
        ]
    
    let private createItemsMap<'T> (items:IItem<'T> list) : Map<int, 'T> =
        items |> List.map (fun x -> x.id, x.item) |> Map.ofList
    
    let render<'T> = React.functionComponent(fun(props:Props<'T>) ->
        let itemsMap, _setItems= React.useState (createItemsMap props.Items)
        let selected, setSelected = React.useState None
        let ulRef = React.useElementRef ()
        Html.ul [
            prop.id props.Id
            prop.className props.CssClasses
            prop.ref ulRef
            prop.children [
                //React.fragment (List.mapi (fun idx x -> renderItem<'T> { idx = idx
                //                                                         x = x
                //                                                         renderer = props.ItemRenderer
                //                                                         ulRef = ulRef
                //                                                         itemsMap = itemsMap
                //                                                         selected = selected
                //                                                         setSelected = setSelected }) props.Items)
                
                React.fragment (List.mapi (fun idx x -> renderItem2 idx x props.Id props.ItemRenderer props.OnChange ulRef itemsMap selected setSelected) props.Items)
            ]
        ])

