module Queries.Search

open MetaGQL.Types
open SharedGQL

module Search =

    let ProductSearch =
        AEntity
            ( "product",
              [("id", AUuid)
               ("ean", AString)
               ("title", AString)
               ("subtitle", AString)
               ("product_status", AForeign (Entities.ProductStatus, None, IsEnum))
               //("ware", AForeign (Entities.WareWithProject, None, IsOne))
              ])

    type ProductSearchResult =
        { id: string
          ean: string
          title: string
          subtitle: string
          product_status: string
          //ware: SharedGQL.Ware option
        }

    // Filter: BS/BS+Z/Z (Z)
    let z_label_ids = [
        ("Digital Literature International", "45b20c65-f54f-4f12-b8b9-cc1171c414b9")
        ("Der Drehbuchverlag", "32273295-0ac8-4ff9-a105-58bb292295bf")
        ("Cabal Verlag", "d17b98e5-ed9a-4aa4-b23e-5f200da2ac79")
        ("hoerbuch.cc", "0998c2e0-04ba-4242-a444-ffdda91fa2a2")
        ("Audio Media Digital", "07c04eb9-0cbd-4e4e-b15d-8de9d7ec5624")
        ("m!A", "a948121e-2996-462e-b406-0a6848746c39")
    ]

    // Filter: BS/BS+Z/Z (BS)
    let bs_label_ids = [
        ("Bookstream Asia", "ffe8d3f1-ce1a-4914-8fd6-17d20ecab77b")
        ("Klever Verlag", "54732485-d0a5-4f9d-9e12-6b8f64637e99")
        ("edition a", "f5654cb8-0ea6-4f86-a781-a4662c2f7a56")
        ("Goldegg Verlag", "6e384da6-68ce-4b4a-bae2-a209883610e2")
        ("Bookstream livres audio", "9190383e-ee1b-4b99-8011-c89822e3d511")
        ("Bookstream Audiolibros", "9c128ea8-5391-49fa-bb16-5cd5a393d06a")
        ("Bookstream Hörbücher", "c8e8cd6a-c344-40d8-a3d8-88e16503d679")
        ("Bookstream Audiobooks", "8a8c7b69-8bea-4fb3-a0e1-02c6ffe6ddfc")
        ("BLITZ-Verlag Audio", "8a360fff-9627-4ed8-87f1-8e9588c68603")
        ("Baker Street Audio", "1d6aa515-7bcc-42bb-ab18-a00d480e878c")
    ]

    type LabelFilter = BS
                     | BS_Z
                     | Z

    let getLabelNameIdsForFilter (x:LabelFilter) =
        match x with
        | BS   -> bs_label_ids
        | BS_Z -> List.append bs_label_ids z_label_ids
        | Z    -> z_label_ids

    let getLabelIdsForFilter (x:LabelFilter) =
        match x with
        | BS   -> bs_label_ids
        | BS_Z -> List.append bs_label_ids z_label_ids
        | Z    -> z_label_ids
        |> List.map snd

    let all_project_status_ids = [
        "_01_CREATED"
        "_02_READY_FOR_READING"
        "_03_IN_RECORDING"
        "_04_READY_FOR_SOUND"
        "_05_READY_FOR_PROOFLISTENING"
        "_06_IN_PROOFLISTENING"
        "_07_READY_FOR_CUT"
        "_08_IN_CUT"
        "_09_CORRECTIONS_NEEDED"
        "_10_CORR_READY_FOR_SOUND"
        "_11_CORR_READY_FOR_CUT"
        "_12_READY_FOR_PRODUCTS"
    ]

    type ProjectStatusFilter = PRF_All
                             | PRF_Created
                             | PRF_Ready_For_Products
                             // TODO: add more project status filter when there is more time allocated for this task

    let getProjectStatusIdsForFilter (x:ProjectStatusFilter) =
        match x with
        | PRF_All -> all_project_status_ids
        | PRF_Created -> ["_01_CREATED"]
        | PRF_Ready_For_Products -> ["_12_READY_FOR_PRODUCTS"]

    // Filter: product_status - ALL Kontor
    let all_kontor_ids = [
        "KONTOR_LARGE_IMPORT"
        "KONTOR_SMALL_IMPORT"
        "KONTOR_MANUAL_IMPORT"
    ]

    // Filter: product_status - ALL Zebralution
    let all_zebralution_ids = [
        "ZEBRA_REPLACEMENTS_FROM_KONTOR"
        "ZEBRA_DELIVERY_ONLY_NOT_READY"
        "ZEBRA_DELIVERY_ONLY_READY"
        "ZEBRA_DELIVERY_ONLY_READY_FOR_SEGMENTATION"
        "ZEBRA_DELIVERY_ONLY_DELIVERED"
        "ZEBRA_IMPORT"
        "ZEBRA_3M_RECUT"
    ]

    type ProductStatusFilter = PSF_All
                             | PSF_Zebralution_All
                             | PSF_Zebralution_Not_Ready
                             | PSF_Zebralution_Ready_Segmentation
                             | PSF_Zebralution_Ready_Delivery
                             | PSF_Zebralution_Delivered
                             | PSF_Zebralution_Import
                             | PSF_Zebralution_Replacement_From_Kontor
                             | PSF_Zebralution_3m_Recut
                             | PSF_Kontor_All
                             | PSF_Kontor_Manual_Import

    let getProductStatusIdsForFilter (x:ProductStatusFilter) =
        match x with
        | PSF_All -> List.append all_kontor_ids all_zebralution_ids
        | PSF_Zebralution_All -> all_zebralution_ids
        | PSF_Zebralution_Not_Ready -> ["ZEBRA_DELIVERY_ONLY_NOT_READY"]
        | PSF_Zebralution_Ready_Segmentation -> ["ZEBRA_DELIVERY_ONLY_READY_FOR_SEGMENTATION"]
        | PSF_Zebralution_Ready_Delivery -> ["ZEBRA_DELIVERY_ONLY_READY"]
        | PSF_Zebralution_Delivered -> ["ZEBRA_DELIVERY_ONLY_DELIVERED"]
        | PSF_Zebralution_Import -> ["ZEBRA_IMPORT"]
        | PSF_Zebralution_Replacement_From_Kontor -> ["ZEBRA_REPLACEMENTS_FROM_KONTOR"]
        | PSF_Zebralution_3m_Recut -> ["ZEBRA_3M_RECUT"]
        | PSF_Kontor_All -> all_kontor_ids
        | PSF_Kontor_Manual_Import -> ["KONTOR_MANUAL_IMPORT"]

    type AllLabelsResult =
        { label: Label list
          label_aggregate: count_aggregate }

    let queryAllLabelIds =
        { QueryInput = []
          QueryMapping = [
              { Entity = Entities.Label
                Projection = ProjectionHelper.ofEntity Entities.Label
                Filter = []
                OrderBy = OrderByNone
                WithAggregate = WithAggregateCount "label_aggregate"
                Limit = NoLimit
                Offset = None
                Alias = None
              }
          ] }

    let getLabelIdsWithFilter (xs:Label list) (labelFilter:LabelFilter) =
        List.filter (fun (x:Label) ->
            match labelFilter with
            | BS   -> not <| x.article_number_prefix.StartsWith "Z-"
            | BS_Z -> true
            | Z    -> x.article_number_prefix.StartsWith "Z-") xs
        |> List.map (fun (x:Label) -> x.id)

    let applyFilter attrPath theFilter =
        OpIn (ComplexAttr attrPath, Constant (sprintf "%A" theFilter |> fun x -> x.Replace(";", ",")))

    let applyEnumFilter attrPath theFilter =
        OpIn (ComplexAttr attrPath, Constant (sprintf "%A" theFilter |> fun x -> x.Replace(";", ",").Replace("\"", "")))

    type SearchAllEntitiesResult =
        { product: SharedGQL.Product list
          product_aggregate: count_aggregate
          manuscript: SharedGQL.Manuscript list
          manuscript_aggregate: count_aggregate
          project: SharedGQL.Project list
          project_aggregate: count_aggregate
          empty_project: SharedGQL.Project list
          empty_project_aggregate: count_aggregate
        }

    type LimitOptions =
        { Limit: int
          Offset: int
        }

    type OrderOptions =
        { ProductOrderBy: OrderBy
          ProjectOrderBy: OrderBy
          ManuscriptOrderBy: OrderBy
        }

    type FilterOptions =
        { labelFilter : LabelFilter
          productStatusFilter: ProductStatusFilter
          projectStatusFilter: ProjectStatusFilter
          labelIdsExclusion : string Set
        }

    let querySearchEntities (limitOpts:LimitOptions option) (orderOpts:OrderOptions) (filterOpts:FilterOptions) =
        let s1 = getLabelIdsForFilter filterOpts.labelFilter |> Set.ofList
        let finalLabelIds = Set.difference s1 filterOpts.labelIdsExclusion |> Set.toList
        //let finalLabelIds = getLabelIdsForFilter labelFilter
        printfn "%A" (getProductStatusIdsForFilter filterOpts.productStatusFilter)

        let queryMap entity alias aggName attrsSearch orderBy extraFilter =
          { Entity = entity
            Projection = ProjectionHelper.ofEntity entity
            Filter = if List.length extraFilter > 0 then
                        [ OpAnd <| List.append [ OpOr <| List.map (fun attr -> OpILike(SimpleAttr attr, LookupVar "q")) attrsSearch ] extraFilter ]
                     else
                        [ OpOr <| List.map (fun attr -> OpILike(SimpleAttr attr, LookupVar "q")) attrsSearch ]
            OrderBy = orderBy
            WithAggregate = WithAggregateCount aggName
            Limit =
                match limitOpts with
                | None -> NoLimit
                | Some lOpts -> Limit lOpts.Limit
            Offset =
                match limitOpts with
                | None -> None
                | Some lOpts -> Some lOpts.Offset
            Alias = Some alias }
        { QueryInput = [ DefVar("q", AString) ]
          QueryMapping =
              [ queryMap Entities.Product "product" "product_aggregate" ["title"; "subtitle"; "ean"] orderOpts.ProductOrderBy
                         [
                           applyFilter ["ware";"project";"label_info";"label_id"] finalLabelIds
                           applyEnumFilter ["product_status"] (getProductStatusIdsForFilter filterOpts.productStatusFilter)
                           applyFilter ["ware";"project";"production_info";"project_status";"value"]
                                       (getProjectStatusIdsForFilter filterOpts.projectStatusFilter)
                         ]
                queryMap Entities.Project "project" "project_aggregate" ["title"; "computed_authors"; "computed_narrators"] orderOpts.ProjectOrderBy
                         [
                           applyFilter ["label_info";"label_id"] finalLabelIds
                           applyEnumFilter ["wares";"products";"product_status"]
                                           (getProductStatusIdsForFilter filterOpts.productStatusFilter)
                           applyFilter ["production_info";"project_status";"value"]
                                       (getProjectStatusIdsForFilter filterOpts.projectStatusFilter)
                         ]
                queryMap Entities.Manuscript "manuscript" "manuscript_aggregate" ["title"; "subtitle"] orderOpts.ManuscriptOrderBy
                         [
                           applyFilter ["projects";"project";"label_info";"label_id"] finalLabelIds
                           applyEnumFilter ["projects";"project";"wares";"products";"product_status"]
                                           (getProductStatusIdsForFilter filterOpts.productStatusFilter)
                           applyFilter ["projects";"project";"production_info";"project_status";"value"]
                                       (getProjectStatusIdsForFilter filterOpts.projectStatusFilter)
                         ]
                { Entity = Entities.Project
                  Projection = ProjectionHelper.ofEntity Entities.Project
                  Filter = [ OpNot (ComplexAttr ["wares";"products"]) ]
                  OrderBy = OrderByDesc "created_at"
                  WithAggregate = WithAggregateCount "empty_project_aggregate"
                  Limit =
                      match limitOpts with
                      | None -> NoLimit
                      | Some lOpts -> Limit lOpts.Limit
                  Offset =
                      match limitOpts with
                      | None -> None
                      | Some lOpts -> Some lOpts.Offset
                  Alias = Some "empty_project" }
                //queryMap Entities.ProjectSeries "project_series_aggregate" ["title"]
                //queryMap Entities.Ware "ware_aggregate" ["name"]
                //queryMap Entities.ArtistProfile "artist_profile_aggregate" ["name"]
              ] }

    type ProductStatusCountsResult =
        { all: count_aggregate
          zebralution_all: count_aggregate
          zebralution_not_ready: count_aggregate
          zebralution_ready_segmentation: count_aggregate
          zebralution_ready_delivery: count_aggregate
          zebralution_delivered: count_aggregate
          zebralution_import: count_aggregate
          zebralution_replacement_from_kontor: count_aggregate
          zebralution_3m_recut: count_aggregate
          kontor_all: count_aggregate
          kontor_manual_import: count_aggregate
        }

    let queryFilterProductCounts (labelFilter:LabelFilter)
                                 (projectStatusFilter:ProjectStatusFilter)
                                 (imprintExcludeSet:string Set) =
        let s1 = getLabelIdsForFilter labelFilter |> Set.ofList
        let finalLabelIds = Set.difference s1 imprintExcludeSet |> Set.toList
        let queryMap name productStatusFilter =
            { Entity = Entities.Product
              Projection = ProjectionHelper.ofEntity Entities.Product
              Filter = [ OpAnd [
                            applyEnumFilter ["product_status"] (getProductStatusIdsForFilter productStatusFilter)
                            applyFilter ["ware";"project";"label_info";"label_id"] finalLabelIds
                            applyFilter ["ware";"project";"production_info";"project_status";"value"] (getProjectStatusIdsForFilter projectStatusFilter)
                         ]
                       ]
              OrderBy = OrderByNone
              WithAggregate = WithAggregateCount name
              Limit = Limit 1
              Offset = None
              Alias = Some <| sprintf "product_%s" name
            }
        { QueryInput = []
          QueryMapping =
              [ queryMap "all" PSF_All
                queryMap "zebralution_all" PSF_Zebralution_All
                queryMap "zebralution_not_ready" PSF_Zebralution_Not_Ready
                queryMap "zebralution_ready_segmentation" PSF_Zebralution_Ready_Segmentation
                queryMap "zebralution_ready_delivery" PSF_Zebralution_Ready_Delivery
                queryMap "zebralution_delivered" PSF_Zebralution_Delivered
                queryMap "zebralution_import" PSF_Zebralution_Import
                queryMap "zebralution_replacement_from_kontor" PSF_Zebralution_Replacement_From_Kontor
                queryMap "zebralution_3m_recut" PSF_Zebralution_3m_Recut
                queryMap "kontor_all" PSF_Kontor_All
                queryMap "kontor_manual_import" PSF_Kontor_Manual_Import
              ]
        }