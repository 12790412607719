module Pages.Index

open Components
open Client.BaseTypes
open Client.Types
open Elmish
open Feliz
open Feliz.UseElmish
open Feliz.Bulma
open Feliz.Router
open SharedGQL
open MetaGQL.Types
open Queries.Search.Search
open Client.Url

module ManuscriptHelper =
    let authorNames (x:Manuscript) =
        seq {
            for author:AuthorManuscript in x.authors do
                match author.author with
                | Some a -> yield a.name
                | None -> ()
        }
        |> List.ofSeq
        |> String.concat ", "

    let genrePreset (x:Manuscript) =
        Option.map (fun (x:GenrePreset) -> x.name) x.genre_preset
        |> Option.defaultValue ""

module ProjectHelper =

    let authorNames (x:Project) =
        match x.manuscript_info with
        | Some mi -> match mi.manuscript with
                     | Some manuscript -> ManuscriptHelper.authorNames manuscript
                     | None -> ""
        | None -> ""

    let narratorNames (x:Project) =
        match x.production_info with
        | Some pi -> [for ppir in pi.project_production_info_readers -> ppir.reader_name]
        | None -> []
        |> String.concat ", "

    let imprint (x:Project) =
        match x.copyright_info with
        | Some ci -> ci.p_line
        | None -> ""

module WareHelper =

    let authorNames (x:Ware) =
        match x.project with
        | Some project -> ProjectHelper.authorNames project
        | None -> ""

    let narratorNames (x:Ware) =
        match x.project with
        | Some project -> ProjectHelper.narratorNames project
        | None -> ""

    let imprint (x:Ware) =
        match x.project with
        | Some project -> ProjectHelper.imprint project
        | None -> ""
module ProductHelper =

    let authorNames (mWare:Ware option) =
        match mWare with
        | Some ware -> WareHelper.authorNames ware
        | None -> ""

    let narratorNames (mWare:Ware option) =
        match mWare with
        | Some ware -> WareHelper.narratorNames ware
        | None -> ""

    let imprint (mWare:Ware option) =
        match mWare with
        | Some ware -> WareHelper.imprint ware
        | None -> ""

module Index =

    type Msg =
        | DoNothing
        | Relay of AppMsg list

    type PageProps =
       {
         Name: string
         InitMsgs : AppMsg list
       }

    type State =
        {
          Name: string
        }


    let private init (props:PageProps) () =
        { Name = props.Name
        }, Cmd.ofMsg <| Relay props.InitMsgs

    let private call dispatch callF responseF = Utilities.Remoting.withException dispatch callF responseF DoNothing
    let private err okF result = Utilities.Remoting.err DoNothing okF result

    let private update (appCtx : AppContext) msg state =
        let token = { Indygemma.Auth.Types.Token = appCtx.Jwt }
        match msg with
        | DoNothing -> state, Cmd.none
        | Relay appMsgs ->
            appCtx.AppDispatch <| AppMsg.Batch appMsgs
            state, Cmd.none

    let private labelFilterComponent = React.functionComponent(fun () ->
        let appCtx = React.useContext(Context.AppContext)
        let isCurrent = fun x ->
            if appCtx.AppState.LabelFilter = x then "is-active" else ""
        Bulma.tabs [
            tabs.isCentered
            tabs.isBoxed
            prop.className "label-filter mb-5"
            prop.children [
                Html.ul [
                       for x, label in [(BS, "BS");(BS_Z, "BS + Z");(Z, "Z")] do
                       Html.li [
                           prop.className (isCurrent x)
                           prop.children [
                               Html.a [
                                   prop.onClick (fun e ->
                                       appCtx.AppDispatch <| SetLabelFilter x
                                       e.preventDefault ())
                                   prop.children [
                                       Html.span label
                                   ]
                               ]
                           ]
                       ]
                ]
            ]
        ]
    )

    let private productFilterComponent = React.functionComponent(fun state ->
        let mCounts =
            match state.ProductStatusCountsResult with
            | Resolved resultCounts ->
                match resultCounts with
                | Ok counts -> Some counts
                | Error _ -> None
            | _ -> None
        let withCount = fun label x ->
            match mCounts with
            | None -> label
            | Some (counts:ProductStatusCountsResult) ->
                let theCount =
                    match x with
                    | PSF_All -> counts.all.aggregate.count
                    | PSF_Zebralution_All -> counts.zebralution_all.aggregate.count
                    | PSF_Zebralution_Not_Ready -> counts.zebralution_not_ready.aggregate.count
                    | PSF_Zebralution_Ready_Segmentation -> counts.zebralution_ready_segmentation.aggregate.count
                    | PSF_Zebralution_Ready_Delivery -> counts.zebralution_ready_delivery.aggregate.count
                    | PSF_Zebralution_Delivered -> counts.zebralution_delivered.aggregate.count
                    | PSF_Zebralution_Import -> counts.zebralution_import.aggregate.count
                    | PSF_Zebralution_Replacement_From_Kontor -> counts.zebralution_replacement_from_kontor.aggregate.count
                    | PSF_Zebralution_3m_Recut -> counts.zebralution_3m_recut.aggregate.count
                    | PSF_Kontor_All -> counts.kontor_all.aggregate.count
                    | PSF_Kontor_Manual_Import -> counts.kontor_manual_import.aggregate.count
                sprintf "%s (%d)" label theCount
        let appCtx = React.useContext(Context.AppContext)
        let isCurrent = fun x ->
            if appCtx.AppState.ProductStatusFilter = x then
                sprintf "is-success is-light"
            else
                sprintf "is-info is-light"
        Html.div [
            prop.className "mt-5"
            prop.children [
                Html.b "Product Filter"
                Html.ul [
                    prop.className "product-filter mt-2"
                    prop.children [
                            for label, eventName in [ ("All Zebralution + Kontor", PSF_All)
                                                      ("Zebralution - All", PSF_Zebralution_All)
                                                      ("Zebralution - Not Ready", PSF_Zebralution_Not_Ready)
                                                      ("Zebralution - Ready for Segmentation", PSF_Zebralution_Ready_Segmentation)
                                                      ("Zebralution - Ready for Delivery", PSF_Zebralution_Ready_Delivery)
                                                      ("Zebralution - Delivered", PSF_Zebralution_Delivered)
                                                      ("Zebralution - 3m Recut", PSF_Zebralution_3m_Recut)
                                                      ("Zebralution - Import", PSF_Zebralution_Import)
                                                      ("Zebralution - Replacements from Kontor", PSF_Zebralution_Replacement_From_Kontor)
                                                      ("Kontor - All", PSF_Kontor_All)
                                                      ("Kontor - Manual Import", PSF_Kontor_Manual_Import) ]
                                do
                                    Html.li [
                                        prop.className "mb-1"
                                        prop.children [
                                            Button.button (Some (withCount label eventName)) None (isCurrent eventName)
                                                [ prop.onClick (fun e ->
                                                                 appCtx.AppDispatch <| SetProductStatusFilter eventName
                                                                 e.preventDefault ())
                                                ]
                                        ]
                                    ]
                        ]
                    ]
            ]
        ])

    let private projectFilterComponent = React.functionComponent(fun () ->
        let appCtx = React.useContext(Context.AppContext)
        let isCurrent = fun x ->
            if appCtx.AppState.ProjectStatusFilter = x then
                sprintf "is-success is-light"
            else
                sprintf "is-info is-light"
        Html.div [
            prop.className ""
            prop.children [
                Html.b "Project Filter"
                Html.ul [
                    prop.className "product-filter mt-2"
                    prop.children [
                            for label, event in [ ("Projects: ALL", PRF_All)
                                                  ("Projects: CREATED", PRF_Created)
                                                  ("Projects: READY FOR PRODUCTS", PRF_Ready_For_Products) ]
                                do
                                Html.li [
                                    prop.className "mb-1"
                                    prop.children [
                                        Button.button (Some label) None (isCurrent event)
                                            [ prop.onClick (fun e ->
                                                             appCtx.AppDispatch <| SetProjectStatusFilter event
                                                             e.preventDefault ())
                                            ]
                                    ]
                                ]
                        ]
                    ]
            ]
        ]
        )

    let private imprintFilterComponent = React.functionComponent(fun () ->
        let appCtx = React.useContext(Context.AppContext)
        let isExcluded = fun x ->
            if Set.contains x appCtx.AppState.ImprintExcludeFilter then
                sprintf "is-danger is-light"
            else
                sprintf "is-info is-light"
        let isActive x = Set.contains x appCtx.AppState.ImprintStarredFilter
        let isStarred = fun x ->
            if isActive x then
                sprintf "is-success is-light"
            else
                sprintf "is-info is-light"
        Html.div [
            prop.className "imprint-filter mt-5"
            prop.children [
                    Html.b "Imprint Filter"
                    Html.ul [
                        prop.className "mt-2"
                        prop.children [
                            for labelName, labelId in getLabelNameIdsForFilter appCtx.AppState.LabelFilter do
                               Html.li [
                                   prop.className "mb-1"
                                   prop.children [
                                       Html.div [
                                           prop.children [
                                                Button.button None (Some "fas fa-ban") (isExcluded labelId)
                                                    [ prop.onClick (fun e ->
                                                                     appCtx.AppDispatch <| ToggleExcludeImprintFilter labelId
                                                                     e.preventDefault ())
                                                    ]
                                                Button.button None
                                                              (if (isActive labelId) then (Some "fas fa-star") else (Some "far fa-star"))
                                                              (isStarred labelId)
                                                    [ prop.onClick (fun e ->
                                                                     appCtx.AppDispatch <| ToggleStarredImprintFilter labelId
                                                                     e.preventDefault ())
                                                    ]
                                                Html.span [
                                                    prop.className "ml-2"
                                                    prop.text labelName
                                                ]
                                           ]
                                       ]
                                   ]
                               ]
                        ]
                    ]
                ]
            ]
        )

    let private paginationComponent (state: AppState) (maxCount:int) (dispatch: AppMsg -> unit) =
        let pages = if maxCount <= state.LimitResult then 1 else maxCount / state.LimitResult + 1
        let currentPage = if state.Offset = 0 then 1 else state.Offset / state.LimitResult + 1
        let prevPage =  currentPage - 1
        let nextPage =  currentPage + 1
        let prevOffset = state.Offset - 1 * state.LimitResult
        let nextOffset = state.Offset + 1 * state.LimitResult
        let lastOffset = pages * state.LimitResult - state.LimitResult
        if pages = 1 then
            Html.none
        else
            Html.div [
                prop.className "my-5"
                prop.children [
        //            Html.p (sprintf "max pages: %d" pages)
        //            Html.p (sprintf "prevPage: %d" prevPage)
        //            Html.p (sprintf "currentPage: %d" currentPage)
        //            Html.p (sprintf "nextPage: %d" nextPage)
        //            Html.p (sprintf "prevOffset: %d" prevOffset)
        //            Html.p (sprintf "offset: %d" state.Offset)
        //            Html.p (sprintf "nextOffset: %d" nextOffset)
        //            Html.p (sprintf "lastOffset: %d" lastOffset)
                    Html.nav [
                        prop.className "pagination is-centered"
                        prop.role "navigation"
                        prop.ariaLabel "pagination"
                        prop.children [
                            if state.Offset <> 0 then
                                Html.a [
                                    prop.className "pagination-previous"
                                    prop.onClick (fun _ -> dispatch <| SetOffset prevOffset)
                                    prop.text "Previous Page"
                                ]
                            if currentPage < pages then
                                Html.a [
                                    prop.className "pagination-next"
                                    prop.onClick (fun _ -> dispatch <| SetOffset nextOffset)
                                    prop.text "Next Page"
                                ]
                            Html.ul [
                                prop.className "pagination-list"
                                prop.children [
                                    //
                                    // previous page
                                    //
                                    if state.Offset <> 0 then
                                        if prevPage <> 1 then
                                            Html.li [
                                                Html.a [
                                                    prop.className "pagination-link"
                                                    prop.ariaLabel (sprintf "Go to page %d" 0)
                                                    prop.onClick (fun _ -> dispatch <| SetOffset 0)
                                                    prop.text 1
                                                ]
                                            ]
                                            Html.li [ Html.span [ prop.className "pagination-ellipsis"; prop.text "..."] ]
                                        Html.li [
                                            Html.a [
                                                prop.className "pagination-link"
                                                prop.ariaLabel (sprintf "Go to page %d" prevPage)
                                                prop.onClick (fun _ -> dispatch <| SetOffset prevOffset)
                                                prop.text prevPage
                                            ]
                                        ]
                                    //
                                    // current page selection
                                    //
                                    Html.li [
                                        Html.a [
                                            prop.className "pagination-link is-current"
                                            prop.ariaLabel (sprintf "Go to page %d" currentPage)
                                            prop.text currentPage
                                        ]
                                    ]
                                    //
                                    // next page
                                    //
                                    if currentPage < pages then
                                        Html.li [
                                            Html.a [
                                                prop.className "pagination-link"
                                                prop.ariaLabel (sprintf "Go to page %d" nextPage)
                                                prop.onClick (fun _ -> dispatch <| SetOffset nextOffset)
                                                prop.text nextPage
                                            ]
                                        ]
                                        if nextPage < pages then
                                            Html.li [ Html.span [ prop.className "pagination-ellipsis"; prop.text "..."] ]
                                            Html.li [
                                                Html.a [
                                                    prop.className "pagination-link"
                                                    prop.ariaLabel (sprintf "Go to page %d" pages)
                                                    prop.onClick (fun _ -> dispatch <| SetOffset lastOffset)
                                                    prop.text pages
                                                ]
                                            ]
                                ]
                            ]
                        ]
                    ]
                ]
            ]

    let private tablethead (xs:(string * string option) list) callback =
        Html.thead [
            Html.tr [
                for x,orderByOption in xs ->
                    match orderByOption with
                    | Some obo ->
                        Html.th [
                            prop.children [
                                Html.button [
                                     prop.className "text-sm"
                                     prop.onClick (fun _ -> callback (OrderByDesc obo))
                                     prop.text "(DESC)"
                                ]
    //                            Button.button None (Some "fas fa-sort-up") ""
    //                                [ prop.onClick (fun _ -> callback (OrderByDesc obo))
    //                                ]
                                Html.span x
    //                            Button.button None (Some "fas fa-sort-down") ""
    //                                [ prop.onClick (fun _ -> callback (OrderByAsc obo))
    //                                ]
                                Html.button [
                                     prop.className "text-sm"
                                     prop.onClick (fun _ -> callback (OrderByAsc obo))
                                     prop.text "(ASC)"
                                ]
                            ]
                        ]
                    | None ->
                        Html.th x
            ]
        ]

    let private table (headF:Fable.React.ReactElement) xs xf =
        Html.table [
            prop.className "table"
            prop.children [
                headF
                Html.tbody [
                    React.fragment <| List.mapi (fun idx x -> xf x idx) xs //[for x in xs -> xf x]
                ]
            ]
        ]

    let private renderProduct (_dispatch: AppMsg -> unit) (state:AppState) (x:Product) (idx:int) =
        // ["Name";"Author";"Narrator";"Imprint";"EAN";"Product Status"]
        let name =  if x.subtitle.Length = 0 then
                        x.title
                    else
                        sprintf "%s - %s" x.title x.subtitle
        let authorNames = ProductHelper.authorNames x.ware
        let narratorNames = ProductHelper.narratorNames x.ware
        let imprint = ProductHelper.imprint x.ware

        Html.tr [
            prop.key x.id
            prop.className "product-item"
            prop.style [ style.marginTop 15; style.marginBottom 15 ]
            prop.children [
                Html.th (sprintf "%d" (state.Offset + idx + 1))
                Html.th name
                Html.th authorNames
                Html.th narratorNames
                Html.th imprint
                Html.th x.ean
                Html.th [
                    Html.span [
                        prop.className "rounded bg-blue-50 text-blue-400 px-3 py-1 text-sm"
                        prop.text (sprintf "%s" ( x.product_status.ToString () ))
                    ]
                ]
                Html.th ( x.created_at.ToString() )
                Html.th [
                    Bulma.buttons [
                        Html.a [
                            prop.className ["button"; "is-outlined"]
                            //prop.onClick (fun _ -> EditProduct x.id |> dispatch)
                            prop.href (Url.EditProduct x.id |> toUrl |> Router.format)
                            prop.children [
                                FrontendUtils.iconSpan "fas fa-eye"
                            ]
                        ]
    //                    Html.a [
    //                        prop.className ["button"; "is-info"]
    //                        prop.href (Url.ShowProduct x.id |> toUrl |> Router.format)
    //                        prop.text "Show"
    //                    ]
                    ]
                ]
            ]
        ]

    let private renderManuscript (state:AppState) (x:Manuscript) (idx:int) =
        // ["Manuscript Title"; "Authors"; "Narrator"; "Imprint"; "Genre Preset"]
        let name =  if x.subtitle.Length = 0 then
                        x.title
                    else
                        sprintf "%s - %s" x.title x.subtitle
        // TODO: this requires manuscript having a link to project (Manuscript does not right now. Maybe add ManuscriptWithProject)
        //let authorNames = ManuscriptHelper.authorNames x
        //let narratorNames = ManuscriptHelper.narratorNames x
        //let imprint = ManuscriptHelper.imprint x

        let genrePreset = ManuscriptHelper.genrePreset x

        Html.tr [
            prop.key x.id
            prop.className "manuscript-item"
            prop.style [ style.marginTop 15; style.marginBottom 15 ]
            prop.children [
                Html.th (sprintf "%d" (state.Offset + idx + 1))
                Html.th name
                //Html.th "(no author names)"
                //Html.th "(no narrator names)"
                //Html.th "(no imprint)"
                Html.th genrePreset
            ]
        ]

    let private renderProject (state:AppState) (x:Project) (idx:int) =
        let authorNames = ProjectHelper.authorNames x
        let narratorNames = ProjectHelper.narratorNames x
        let imprint = ProjectHelper.imprint x

        Html.tr [
            prop.key x.id
            prop.className "project-item"
            prop.style [ style.marginTop 15; style.marginBottom 15 ]
            prop.children [
                Html.th (sprintf "%d" (state.Offset + idx + 1))
                Html.th x.title
                Html.th authorNames
                Html.th narratorNames
                Html.th imprint
                Html.th ( x.created_at.ToString() )
                Bulma.buttons [
                    Html.a [
                        prop.className ["button"; "is-outlined"]
                        prop.href (Url.EditProject x.id |> toUrl |> Router.format)
                        prop.children [
                            FrontendUtils.iconSpan "fas fa-eye"
                        ]
                    ]
                ]
            ]
        ]

    let private renderCounter (state: AppState) (dispatch: AppMsg -> unit) =
        match state.SearchAllEntitiesResult with
        | HasNotStartedYet -> Html.none
        | InProgress -> Html.h1 "Loading..."
        | Resolved (Ok searchResult) ->
            Html.div [
                prop.className "columns mt-8"
                prop.children [
                    Html.div [
                        prop.className "column is-one-fifths"
                        prop.children [
                            labelFilterComponent ()
                            projectFilterComponent ()
                            productFilterComponent state
                            imprintFilterComponent ()
                        ]
                    ]
                    Html.div [
                        prop.className "column is-four-fifths"
                        prop.children [
                            // TODO: the following should be a component
                            match state.SelectedTab with
                            | EmptyProjectsTab ->
                                Bulma.container [
                                    //Html.h1 (sprintf "Projects (%d)" counter.ProjectsCount)
                                    paginationComponent state searchResult.empty_project_aggregate.aggregate.count dispatch
                                    table (tablethead [("#", None)
                                                       ("Project Name", Some "title")
                                                       ("Authors", None)
                                                       ("Narrator", None)
                                                       ("Imprint", None)
                                                       ("Created At", Some "created_at")]
                                                      (fun orderByValue ->
                                                        if orderByValue = state.ProjectOrderBy then
                                                            dispatch (SetProjectOrderBy OrderByNone)
                                                        else
                                                            dispatch (SetProjectOrderBy orderByValue)))
                                           searchResult.empty_project
                                           (renderProject state)
                                    paginationComponent state searchResult.empty_project_aggregate.aggregate.count dispatch
                                ]
                            | ProductTab ->
                                Bulma.container [
                                    if state.ProductStatusFilter = PSF_Zebralution_Ready_Delivery && searchResult.product_aggregate.aggregate.count > 0 then
                                        Html.div [
                                            prop.className "flex flex-row-reverse my-2"
                                            prop.children [
                                                Button.button
                                                    (Some <| sprintf "Deliver in Batch (%d) Products" searchResult.product_aggregate.aggregate.count)
                                                    (Some "fas fa-truck") "is-light"
                                                    [ prop.onClick (fun e ->
                                                                     dispatch ExportBatchXLSX
                                                                     e.preventDefault ())
                                                    ]
                                            ]
                                        ]
                                    paginationComponent state searchResult.product_aggregate.aggregate.count dispatch
                                    table (tablethead [("#", None)
                                                       ("Name", Some "title")
                                                       ("Author", None)
                                                       ("Narrator", None)
                                                       ("Imprint", None)
                                                       ("EAN", Some "ean")
                                                       ("Product Status", Some "product_status")
                                                       ("Created At", Some "created_at")]
                                                      (fun orderByValue ->
                                                        if orderByValue = state.ProductOrderBy then
                                                            dispatch (SetProductOrderBy OrderByNone)
                                                        else
                                                            dispatch (SetProductOrderBy orderByValue)))
                                          searchResult.product
                                          (renderProduct dispatch state)
                                    paginationComponent state searchResult.product_aggregate.aggregate.count dispatch
                                ]
                            | ManuscriptTab ->
                                Bulma.container [
                                    //Html.h1 (sprintf "Manuscripts (%d)" counter.ManuscriptsCount)
                                    paginationComponent state searchResult.manuscript_aggregate.aggregate.count dispatch
                                    table (tablethead [("#", None)
                                                       ("Manuscript Title", Some "title")
                                                       //("Authors", None)
                                                       //("Narrator", None)
                                                       //("Imprint", None)
                                                       ("Genre Preset", None)]
                                                      (fun orderByValue ->
                                                        if orderByValue = state.ManuscriptOrderBy then
                                                            dispatch (SetManuscriptOrderBy OrderByNone)
                                                        else
                                                            dispatch (SetManuscriptOrderBy orderByValue)))
                                           searchResult.manuscript
                                           (renderManuscript state)
                                    paginationComponent state searchResult.manuscript_aggregate.aggregate.count dispatch
                                ]
                            | ProjectTab ->
                                Bulma.container [
                                    //Html.h1 (sprintf "Projects (%d)" counter.ProjectsCount)
                                    paginationComponent state searchResult.project_aggregate.aggregate.count dispatch
                                    table (tablethead [("#", None)
                                                       ("Project Name", Some "title")
                                                       ("Authors", None)
                                                       ("Narrator", None)
                                                       ("Imprint", None)
                                                       ("Created At", Some "created_at")]
                                                      (fun orderByValue ->
                                                        if orderByValue = state.ProjectOrderBy then
                                                            dispatch (SetProjectOrderBy OrderByNone)
                                                        else
                                                            dispatch (SetProjectOrderBy orderByValue)))
                                           searchResult.project
                                           (renderProject state)
                                    paginationComponent state searchResult.project_aggregate.aggregate.count dispatch
                                ]
                        ]
                    ]
                ]
            ]
        | Resolved (Error errorMsg) ->
            Html.h1 [
                prop.style [ style.color.crimson ]
                prop.text errorMsg
            ]

    let private getSearchCount (searchResult:Deferred<Result<SearchAllEntitiesResult, string>>) tab =
        let count =
            match searchResult with
            | HasNotStartedYet -> 0
            | InProgress -> 0
            | Resolved (Ok x) ->
                match tab with
                | EmptyProjectsTab -> x.empty_project_aggregate.aggregate.count
                | ProductTab -> x.product_aggregate.aggregate.count
                | ProjectTab -> x.project_aggregate.aggregate.count
                | ManuscriptTab -> x.manuscript_aggregate.aggregate.count
            | Resolved (Error _) -> 0
        if count = 0 then ""  else sprintf "(%d)" count

    let private render (state:AppState) (dispatch:AppMsg -> unit) =
        Html.div [
            Bulma.tabs [
                tabs.isCentered
                tabs.isBoxed
                prop.children [
                    Html.ul [
                        for tabLabel, tab, count in
                            [("Empty Projects", EmptyProjectsTab, getSearchCount state.SearchAllEntitiesResult EmptyProjectsTab)
                             ("Products", ProductTab, getSearchCount state.SearchAllEntitiesResult ProductTab);
                             ("Projects", ProjectTab, getSearchCount state.SearchAllEntitiesResult ProjectTab);
                             ("Manuscripts", ManuscriptTab, getSearchCount state.SearchAllEntitiesResult ManuscriptTab);
                             //("Project Series", ProjectSeriesTab, getSearchCount state.SearchAllEntitiesResult ProjectSeriesTab);
                             //("Ware", WareTab, getSearchCount state.SearchAllEntitiesResult WareTab);
                             //("Artist Marketing Profile", ArtistMarketingProfileTab, getSearchCount state.SearchAllEntitiesResult ArtistMarketingProfileTab);
                            ] do
                               Html.li [
                                   prop.className (if tab = state.SelectedTab then "is-active" else "")
                                   prop.children [
                                       Html.a [
                                           prop.onClick (fun _ -> SelectTab tab |> dispatch)
                                           prop.children [
                                               Html.span (sprintf "%s %s" tabLabel count)
                                           ]
                                       ]
                                   ]
                               ]
                    ]
                ]
            ]
            renderCounter state dispatch
        ]

    let page = React.functionComponent(fun (props:PageProps) ->
        let appCtx = React.useContext Context.AppContext
        let state, dispatch = React.useElmish(init props, update appCtx)
        render appCtx.AppState appCtx.AppDispatch)
