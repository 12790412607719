module Components.Text

open Feliz

module Text =

    let wrapText = React.functionComponent(fun(label:string, x:ReactElement) ->
        Html.div [
            prop.className "flex flex-col space-y-1"
            prop.children [
                Html.p [
                    prop.className "text-xs font-bold mx-auto text-gray-400"
                    prop.text label
                ]
                x
            ]
        ])
