module Components.EditableTextArea

module EditableTextArea =

    open Feliz
    open Feliz.Bulma
    open Browser.Types
    open Browser.Dom
    open Feliz.UseListener

    let editableTextArea =
        React.functionComponent(fun (input: {| fieldLabel: string
                                               value: string
                                               disabled: bool
                                               onChange: string -> HTMLElement -> unit
                                               pk: string |}) ->
            let inEdit, setInEdit = React.useState(false)
            let elId = sprintf "editableTextArea-%s" input.pk
            let doSave () =
                let el = document.querySelector ("#" + elId) :?> HTMLTextAreaElement
                let newText = el.value
                setInEdit(false)
                let targetEl = document.querySelector ("#" + elId) :?> HTMLElement
                input.onChange newText targetEl
            let elRef = React.useElementRef ()
            //React.useListener.onClickAway(elRef, fun _ -> setInEdit(false))
            let height = if inEdit then " h-96 " else " h-56 "
            Html.div [
                prop.ref elRef
                prop.className "field"
                prop.children [
                    Html.label [
                        prop.className "label"
                        prop.text input.fieldLabel
                    ]
                    (*
                    Html.div [
                        prop.id elId
                        prop.className (" max-w-3xl " + (if input.disabled then "non-editable" else "editable"))
                        prop.contentEditable (not input.disabled)
                        prop.custom ("suppressContentEditableWarning", true)
                        prop.onClick (fun _ -> setInEdit(true))
                        prop.onPaste (fun e ->
                            e.stopPropagation ()
                            e.preventDefault ()
                            let content = e.clipboardData.getData "Text"
                            let el = e.currentTarget :?> HTMLElement
                            el.innerHTML <- content
                            ())
                        prop.onKeyPress (key.enter, fun (e:KeyboardEvent) ->
                            if e.ctrlKey then
                                doSave ()
                                let el = document.querySelector ("#" + elId) :?> HTMLElement
                                el.blur()
                                e.preventDefault()
                            else ())
                        prop.children [
                            Html.text input.value
                        ]
                    ]
                    *)
                    Html.textarea [
                        prop.id elId
                        prop.className (" max-w-3xl " + (if input.disabled then "non-editable" else "editable") + height)
                        prop.onClick (fun _ -> setInEdit(true))
                        prop.valueOrDefault input.value

                        prop.onFocus (fun _ -> setInEdit true)
                        prop.onBlur (fun _e ->
                            if inEdit then
                                setInEdit false
                                doSave ()
                            else ())

                        prop.onKeyPress (key.enter, fun (e:KeyboardEvent) ->
                            if e.ctrlKey then
                                doSave ()
                                let el = document.querySelector ("#" + elId) :?> HTMLElement
                                el.blur()
                                e.preventDefault()
                            else ())
                    ]
                    if inEdit then
                        Bulma.level [
                            prop.style [
                                style.marginTop 15
                            ]
                            prop.children [
                                Bulma.levelLeft []
                                Bulma.levelRight [
//                                    Bulma.levelItem [
//                                        Button.button (Some "Cancel") None "" [
//                                            prop.onClick (fun _ ->
//                                                let el = document.querySelector("#" + elId) :?> HTMLTextAreaElement
//                                                el.value <- input.value
//                                                setInEdit(false))
//                                        ]
//                                    ]
                                    Bulma.levelItem [
                                        Button.button (Some "Save") (Some "fas fa-save") "is-success" [
                                            prop.onClick (fun _ -> doSave())
                                        ]
                                    ]
                                ]
                            ]
                        ]
                    else Html.none
                ]
            ])
