module Queries.Add

open MetaGQL.Types
open SharedGQL

module User =

    type Result =
        { insert_user_one: User }

    let mutationAdd email =
      { MutationInput = []
        MutationMapping =
            [ InsertOne
                { Name = "insert_user_one"
                  Entity = Entities.BaseUser
                  Projection = ProjectionHelper.ofEntity Entities.BaseUser
                  Assignment = [ (SimpleAttr "email", Constant (sprintf "\"%s\"" email))
                                 (SimpleAttr "password_digest", Constant "\"\"")
                                 (SimpleAttr "is_active", Constant "true") ]
                  }
            ]
      }

module UserInfo =

    type Result =
        { insert_user_info_one: UserInfo }

    let mutationAdd userId name =
      { MutationInput = []
        MutationMapping =
            [ InsertOne
                { Name = "insert_user_info_one"
                  Entity = Entities.BaseUserInfo
                  Projection = ProjectionHelper.ofEntity Entities.BaseUserInfo
                  Assignment = [
                      (SimpleAttr "user_id", Constant (sprintf "\"%s\"" userId))
                      (SimpleAttr "name", Constant (sprintf "\"%s\"" name))
                  ]
                  }
            ]
      }

module UserUserRole =

    type Result =
        { insert_user_user_role_one: UserUserRole }

    let mutationAdd userId role =
      { MutationInput = []
        MutationMapping =
            [ InsertOne
                { Name = "insert_user_user_role_one"
                  Entity = Entities.UserUserRole
                  Projection = ProjectionHelper.ofEntity Entities.UserUserRole
                  Assignment = [
                      (SimpleAttr "user_id", Constant (sprintf "\"%s\"" userId))
                      (SimpleAttr "role", Constant (sprintf "%s" role))
                  ]
                  }
            ]
      }

module UserReaderInfo =

    type Result =
        { insert_user_info_one: UserReaderInfo }

    let mutationAdd userId pseudonym gender =
      let pseudonym = match pseudonym with
                      | Some p -> (sprintf "\"%s\"" p)
                      | None -> "null"
      { MutationInput = []
        MutationMapping =
            [ InsertOne
                { Name = "insert_user_reader_info_one"
                  Entity = Entities.BaseUserReaderInfo
                  Projection = ProjectionHelper.ofEntity Entities.BaseUserReaderInfo
                  Assignment = [ (SimpleAttr "user_id", Constant (sprintf "\"%s\"" userId))
                                 (SimpleAttr "pseudonym", Constant pseudonym)
                                 (SimpleAttr "gender", Constant (sprintf "%s" gender))
                                 (SimpleAttr "voice_pitch", Constant (sprintf "%s" "UNKNOWN")) // TODO: make this variable, take from input
                                 (SimpleAttr "voice_age", Constant (sprintf "%s" "UNKNOWN")) // TODO: make this variable, take from input
                                 (SimpleAttr "voice_type", Constant (sprintf "%s" "UNKNOWN")) // TODO: make this variable, take from input
                                 (SimpleAttr "is_active", Constant (sprintf "%s" "true")) // TODO: make this variable, take from input
                                 (SimpleAttr "notes", Constant (sprintf "\"%s\"" "")) // TODO: make this variable, take from input
                                 (SimpleAttr "dialect", Constant (sprintf "\"%s\"" "")) // TODO: make this variable, take from input. This is currently freetext, no ENUM is current DB
                               ]
                  }
            ]
      }

module Author =

    type Result =
        { insert_author_one: Author }

    let mutationAdd name =
      { MutationInput = []
        MutationMapping =
            [ InsertOne
                { Name = "insert_author_one"
                  Entity = Entities.Author
                  Projection = ProjectionHelper.ofEntity Entities.Author
                  Assignment = [ (SimpleAttr "name", Constant (sprintf "\"%s\"" name))
                               ]
                  }
            ]
      }

module ArtistProfile =

    type Result =
        { insert_artist_profile_one: ArtistProfile }

    let mutationAdd name =
      { MutationInput = []
        MutationMapping =
            [ InsertOne
                { Name = "insert_artist_profile_one"
                  Entity = Entities.ArtistProfile
                  Projection = ProjectionHelper.ofEntity Entities.ArtistProfile
                  Assignment = [ (SimpleAttr "name", Constant (sprintf "\"%s\"" name))
                               ]
                  }
            ]
      }

module ProjectProductionInfoReader =

    type NewProjectProductionInfoReaderErrors =
        { email: string
          name: string
          gender: string
        }

    type NewProjectProductionInfoReader =
        { project_id: string // the project this reader will be added to
          email: string
          name: string
          pseudonym: string option
          gender: string // TODO: later should be single auto complete
          errors: NewProjectProductionInfoReaderErrors option
        }

    let clientValidate (x:NewProjectProductionInfoReader) =
        let emailMsg = "is required"
        let nameMsg = "is required"
        let err =
            match x.email.Length = 0, x.name.Length = 0 with
            | true, true -> Some { email = emailMsg; name = nameMsg; gender = "" }
            | true, false -> Some { email = emailMsg; name = ""; gender = "" }
            | false, true -> Some { email = ""; name = nameMsg; gender = "" }
            | false, false -> None
        { x with errors = err }

    // TODO: server validate: email is unique. or let hasura identify this and return the error. But then we need to keep the popover open. How?

    let emptyErrors (err:NewProjectProductionInfoReaderErrors option) =
        match err with
        | Some e ->
            e.email.Length = 0 && e.name.Length = 0 && e.gender.Length = 0
        | None -> true

module AuthorManuscript =

    type NewAuthorError =
        { name: string
        }

    type NewAuthor =
        { manuscript_id: string
          name: string
          errors: NewAuthorError option
        }

    let clientValidate (x:NewAuthor) =
        let nameMsg = "is required"
        let err =
            match x.name.Length = 0 with
            | true -> Some { name = nameMsg }
            | false -> None
        { x with errors = err }

    let emptyErrors (err:NewAuthorError option) =
        match err with
        | Some e ->
            e.name.Length = 0
        | None -> true

module ProductArtistProfileInfo =

    type NewArtistProfileError =
        { name: string
        }

    type NewArtistProfile =
        { product_id: string
          name: string
          errors: NewArtistProfileError option
        }

    let clientValidate (x:NewArtistProfile) : NewArtistProfile =
        let nameMsg = "is required"
        let (err:NewArtistProfileError option) =
            match x.name.Length = 0 with
            | true -> Some { name = nameMsg }
            | false -> None
        { x with errors = err }

    let emptyErrors (err:NewArtistProfileError option) =
        match err with
        | Some e ->
            e.name.Length = 0
        | None -> true
