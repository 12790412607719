module Queries.Autocomplete

open MetaGQL.Types
open SharedGQL

module AutoComplete =
    
    let querySearchHelper (entity: AEntity) (projections: string list) (attrName: string) (aggName: string) =
        { QueryInput = [ DefVar("q", AString) ]
          QueryMapping =
              [ { Entity = entity
                  Projection = Projection <| List.map PLookup projections
                  Filter = [ OpILike(SimpleAttr attrName, LookupVar "q") ]
                  OrderBy = OrderByAsc attrName
                  WithAggregate = WithAggregateCount aggName
                  Limit = NoLimit
                  Offset = None
                  Alias = None } ] }
        
    type IdTitleResult =
        { id: string
          title: string }
        
    type IdNameResult =
        { id: string
          name: string }
        
    type ValueResult =
        { value: string }
        
    type IdBookstreamDescriptionResult =
        { id: string
          bookstream_description: string
          zebralution_role_number: int }
        
    type RelatedProjectSeriesResult =
        { project_series: IdTitleResult list }

    let querySearchProjectSeries =
        querySearchHelper Entities.ProjectSeries [ "id"; "title" ] "title" "project_series_aggregate"
        
    type RelatedLanguageResult =
        { language: ValueResult list }

    let querySearchLanguage =
        querySearchHelper Entities.Language [ "value" ] "value" "language_aggregate"
        
    type RelatedArtistProfileRoleResult =
        { artist_profile_role: IdBookstreamDescriptionResult list }
        
    let querySearchArtistProfileRole =
        querySearchHelper Entities.ArtistProfileRole [ "id"; "bookstream_description"; "zebralution_role_number" ] "bookstream_description" "artist_profile_role_aggregate"

    type RelatedGenrePresetResult =
        { genre_preset: IdNameResult list }
        
    let querySearchGenrePreset =
        querySearchHelper Entities.GenrePreset [ "id"; "name" ] "name" "genre_preset_aggregate"

    type RelatedAccountingGroupResult =
        { accounting_group: IdNameResult list }
               
    let querySearchAccountingGroup =
        querySearchHelper Entities.AccountingGroup [ "id"; "name" ] "name" "accounting_group_aggregate"
        
    type RelatedProjectKindResult =
        { project_kind: ValueResult list }

    let querySearchProjectKind =
        querySearchHelper Entities.ProjectKind [ "value" ] "value" "project_kind_aggregate"

    type RelatedMusicRightsInfo =
        { music_rights_info: ValueResult list }
        
    let querySearchMusicRightsInfo =
        querySearchHelper Entities.MusicRightsInfo [ "value" ] "value" "music_rights_info_aggregate"
        
    type RelatedProductStatus =
        { product_status: ValueResult list }
        
    let querySearchProductStatus =
        querySearchHelper Entities.ProductStatus [ "value" ] "value" "product_status_aggregate"

    type RelatedAuthorResult =
        { author: IdNameResult list }
        
    let querySearchAuthor =
        querySearchHelper Entities.Author [ "id"; "name" ] "name" "author_aggregate"

    type RelatedArtistProfileResult =
        { artist_profile: IdNameResult list }
        
    let querySearchArtistProfile =
        querySearchHelper Entities.ArtistProfile [ "id"; "name" ] "name" "artist_profile_aggregate"
        
    type RelatedProjectStatus =
        { project_status: ValueResult list }
        
    let querySearchProjectStatus =
        querySearchHelper Entities.ProjectStatus [ "value" ] "value" "project_status_aggregate"
        
    type RelatedLabelResult =
        { label: IdNameResult list }
        
    let querySearchLabel =
        querySearchHelper Entities.Label [ "id"; "name" ] "name" "label_aggregate"

    // TARGET:
    // query SearchRelatedReader($q: String = "%%", $role: user_role_enum = READER) {
    //   user_info(where: {name: {_ilike: $q}, user: {roles: {role: {_eq: $role}}}}, order_by: {name: asc}) {
    //     user_id
    //     name
    //   }
    //   user_info_aggregate(where: {name: {_ilike: $q}, user: {roles: {role: {_eq: $role}}}}) {
    //     aggregate {
    //       count
    //     }
    //   }
    //   user_reader_info(where: {pseudonym: {_ilike: $q}, user: {roles: {role: {_eq: $role}}}}, order_by: {pseudonym: asc}) {
    //     user_id
    //     pseudonym
    //   }
    //   user_reader_info_aggregate(where: {pseudonym: {_ilike: $q}, user: {roles: {role: {_eq: $role}}}}) {
    //     aggregate {
    //       count
    //     }
    //   }
    // }
    
    // TODO: these types are unnecessary now. We can use the full type definitions
    type reader =
        { pseudonym: string option }
    
    type user_reader =
        { reader: reader option }
    
    type user_id_name =
        { user_id: string
          name: string
          user:  user_reader option}
        
    type name =
        { name: string }
        
    type user_info =
        { info: name option }
        
    type user_id_pseudonym =
        { user_id: string
          pseudonym: string
          user: user_info option }
    
    type RelatedReaderResult =
        { user_info: user_id_name list
          user_reader_info: user_id_pseudonym list }
    
    let querySearchReader =
        { QueryInput =
              [ DefVar("q", AString)
                DefVarDefault("role", ACustom "user_role_enum", "READER") ]
          QueryMapping =
              [ { Entity = Entities.UserInfoToReader
                  Projection = ProjectionHelper.ofEntity Entities.UserInfoToReader
                  Filter =
                      [ OpAnd [ OpILike(SimpleAttr "name", LookupVar "q")
                                OpEq(ComplexAttr [ "user"; "roles"; "role" ], LookupVar "role") ] ]
                  OrderBy = OrderByAsc "name"
                  WithAggregate = WithAggregateCount "user_info_aggregate"
                  Limit = NoLimit
                  Offset = None
                  Alias = None }
                { Entity = Entities.UserReaderInfoToUser
                  Projection = ProjectionHelper.ofEntity Entities.UserReaderInfoToUser
                  Filter =
                      [ OpAnd [ OpILike(SimpleAttr "pseudonym", LookupVar "q")
                                OpEq(ComplexAttr [ "user"; "roles"; "role" ], Constant "READER") ] ]
                  OrderBy = OrderByAsc "pseudonym"
                  WithAggregate = WithAggregateCount "user_reader_info_aggregate"
                  Limit = Limit 10
                  Offset = None
                  Alias = None } ] }
        
    type ManuscriptAuthorResult =
        { manuscript: Manuscript list
          author_manuscript: AuthorManuscript list }
        
    let querySearchManuscriptAuthor =
        // This entity is required to point to both the author as well as the manuscript
        let AllAuthorManuscript =
            EntityHelper.add Entities.AuthorManuscript
                [("author", AForeign(Entities.Author, None, IsOne))
                 ("manuscript", AForeign(Entities.Manuscript, None, IsOne))]
        { QueryInput =
              [ DefVar("q", AString) ]
          QueryMapping =
              [ { Entity = Entities.Manuscript
                  Projection = ProjectionHelper.ofEntity Entities.Manuscript
                  Filter =
                      [ OpOr [ OpILike(SimpleAttr "title", LookupVar "q")
                               OpILike(SimpleAttr "subtitle", LookupVar "q") ] ]
                  OrderBy = OrderByAsc "title"
                  WithAggregate = WithAggregateCount "manuscript_aggregate"
                  Limit = Limit 10
                  Offset = None
                  Alias = None }
                { Entity = AllAuthorManuscript
                  Projection = ProjectionHelper.ofEntity AllAuthorManuscript
                  Filter =
                      [ OpOr [ OpILike(ComplexAttr ["author"; "name"], LookupVar "q") ] ]
                  OrderBy = OrderByNone
                  WithAggregate = WithAggregateCount "author_manuscript_aggregate"
                  Limit = Limit 10
                  Offset = None
                  Alias = None
                }]}
        
    type SimpleRelatedEntityRequest =
        | REProjectSeries of string
        | RELanguage of string
        | REGenrePreset of string
        | REAccountingGroup of string
        | REProjectKind of string
        | REMusicRightsInfo of string
        | REProductStatus of string
        | REProjectStatus of string
        | REManuscriptAuthor of string
        | RELabel of string
        | REArtistProfileRole of string
        
    type ExtraRelatedEntityRequest<'T> =
        | REReader of string * string
        | REAuthor of string * string
        | REArtistProfile of string * string
        
    type SimpleAutoCompleteItem =
        { id: string
          value: string }
        
    type SimpleAutoCompleteResult =
        { simple_items: SimpleAutoCompleteItem list }
        
    type ExtraAutoCompleteItem<'T> =
        { id: string
          value: string
          related: 'T option }
        
    type ExtraAutoCompleteResult<'T> =
        { extra_items: ExtraAutoCompleteItem<'T> list }
        
    type AutoCompleteResult2 =
        | ACProjectSeries of RelatedProjectSeriesResult
        | ACLanguage of RelatedLanguageResult
        | ACGenrePreset of RelatedGenrePresetResult
        | ACAccountingGroup of RelatedAccountingGroupResult
        | ACProjectKind of RelatedProjectKindResult
        | ACMusicRightsInfo of RelatedMusicRightsInfo
        | ACAuthor of RelatedAuthorResult
        | ACArtistProfile of RelatedArtistProfileResult
        | ACProjectStatus of RelatedProjectStatus
        | ACReader of RelatedReaderResult
        
    let mutationInsertProjectSeriesTo =
        // TODO: ensure this assignment does not exist yet
        { MutationInput =
            [ DefVarBang("project_id", AUuid)
              DefVarBang("project_series_id", AUuid)
              DefVarBang("part_number", AInt)
            ]
          MutationMapping =
              [ InsertOne
                    { Name = "insert_project_project_series_one"
                      Entity = Entities.ProjectProjectSeries
                      Projection = ProjectionHelper.ofEntity Entities.ProjectProjectSeries
                      Assignment = [ (SimpleAttr "project_id", LookupVar "project_id")
                                     (SimpleAttr "project_series_id", LookupVar "project_series_id")
                                     (SimpleAttr "part_number", LookupVar "part_number")
                                   ]
                    } ]
        }
        
    let mutationUpdateManuscriptLanguage =
        { MutationInput =
            [ DefVarBang("manuscript_id", AUuid)
              DefVarBang("language", AString)
            ]
          MutationMapping =
              [ UpdateByPKSet
                    { Name = "update_manuscript_by_pk"
                      PK = [(SimpleAttr "id", LookupVar "manuscript_id")]
                      Entity = Entities.Manuscript
                      Projection = ProjectionHelper.ofEntity Entities.Manuscript
                      Assignment = [ (SimpleAttr "language_id", LookupVar "language")
                                   ]
                    } ]
        }
        
    let mutationInsertProjectAccountingGroup =
        // TODO: ensure this relationship does not yet exist before executing
        { MutationInput =
            [ DefVarBang("project_id", AUuid)
              DefVarBang("accounting_group_id", AUuid)
            ]
          MutationMapping =
              [ InsertOne
                    { Name = "insert_project_accounting_info_one"
                      Entity = Entities.ProjectAccountingInfo
                      Projection = ProjectionHelper.ofEntity Entities.ProjectAccountingInfo
                      Assignment = [ (SimpleAttr "project_id", LookupVar "project_id")
                                     (SimpleAttr "accounting_group_id", LookupVar "accounting_group_id") ]
                    } ]
        }

    let mutationUpdateProjectProjectKind =
        { MutationInput =
            [ DefVarBang("project_id", AUuid)
              DefVarBang("project_kind", AString)
            ]
          MutationMapping =
              [ UpdateByPKSet
                    { Name = "update_project_by_pk"
                      PK = [ (SimpleAttr "id", LookupVar "project_id") ]
                      Entity = Entities.Project
                      Projection = ProjectionHelper.ofEntity Entities.Project
                      Assignment = [ (SimpleAttr "id", LookupVar "project_id")
                                     (SimpleAttr "project_kind", LookupVar "project_kind") ]
                    } ]
        }
        
    let mutationInsertAuthorManuscript =
            // TODO: ensure this relationship does not yet exist before executing
            { MutationInput =
                [ DefVarBang("author_id", AUuid)
                  DefVarBang("manuscript_id", AUuid)
                  DefVarBang("sort_order", AUuid)
                ]
              MutationMapping =
                  [ InsertOne
                        { Name = "insert_author_manuscript_one"
                          Entity = Entities.AuthorManuscript
                          Projection = ProjectionHelper.ofEntity Entities.AuthorManuscript
                          Assignment = [ (SimpleAttr "author_id", LookupVar "author_id")
                                         (SimpleAttr "manuscript_id", LookupVar "manuscript_id")
                                         (SimpleAttr "sort_order", LookupVar "sort_order")
                                       ]
                        } ]
            }
            
    let mutationInsertProductArtistProfileInfo =
        // TODO: ensure this relationship does not yet exist before executing
        { MutationInput =
            [ DefVarBang("product_id", AUuid)
              DefVarBang("artist_profile_id", AUuid)
              DefVarBang("sort_order", AUuid)
            ]
          MutationMapping =
              [ InsertOne
                    { Name = "insert_product_artist_profile_info"
                      Entity = Entities.ProductArtistProfileInfo
                      Projection = ProjectionHelper.ofEntity Entities.ProductArtistProfileInfo
                      Assignment = [ (SimpleAttr "product_id", LookupVar "product_id")
                                     (SimpleAttr "artist_profile_id", LookupVar "artist_profile_id")
                                     (SimpleAttr "sort_order", LookupVar "sort_order")
                                   ]
                    } ]
        }
        
    let mutationInsertProjectProductionInfoReader =
            // TODO: ensure this relationship does not yet exist before executing
            { MutationInput =
                [ DefVarBang("project_id", AUuid)
                  DefVarBang("user_id", AUuid)
                  DefVarBang("total_duration", AInterval)
                  DefVarBang("reader_name", AInterval)
                  DefVarBang("sort_order", AInt)
                ]
              MutationMapping =
                  [ InsertOne
                        { Name = "insert_project_production_info_reader"
                          Entity = Entities.ProjectProductionInfoReader
                          Projection = ProjectionHelper.ofEntity Entities.ProjectProductionInfoReader
                          Assignment = [ (SimpleAttr "project_id", LookupVar "project_id")
                                         (SimpleAttr "user_id", LookupVar "user_id")
                                         (SimpleAttr "total_duration", LookupVar "total_duration")
                                         (SimpleAttr "reader_name", LookupVar "reader_name")
                                         (SimpleAttr "sort_order", LookupVar "reader_name")
                                       ]
                        } ]
            }
            
    let mutationUpdateProjectProductionInfoStatus =
        { MutationInput =
            [ DefVarBang("project_id", AUuid)
              DefVarBang("status", AString)
            ]
          MutationMapping =
              [ UpdateByPKSet
                    { Name = "update_project_production_info_by_pk"
                      PK = [ (SimpleAttr "project_id", LookupVar "project_id") ]
                      Entity = Entities.ProjectProductionInfo
                      Projection = ProjectionHelper.ofEntity Entities.ProjectProductionInfo
                      Assignment = [ (SimpleAttr "status", LookupVar "status") ]
                    } ]
        }